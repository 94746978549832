import amplitude from "amplitude-js";
import { AMPLITUDE_API_KEY } from "../../config";

amplitude.getInstance().init(AMPLITUDE_API_KEY);

export const getSessionId = () => {
  let sessionId;
  try {
    sessionId = amplitude.getInstance().getSessionId();
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.error(`Amplitude Error: ${error}`);
    }
  }
  return sessionId;
};

export const logEvent = (name, properties) => {
  try {
    amplitude.getInstance().logEventWithTimestamp(name, properties, Date.now());
    // amplitude.logEvent(name, properties);
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.log(`Event: ${JSON.stringify({ name, properties })}`);
    }
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.error(`Amplitude Error: ${error}`);
    }
  }
};

export const logRevenue = (productId, quantity, amount) => {
  try {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.log(
        `Revenue: ${JSON.stringify({
          productId,
          quantity,
          amount,
        })}`
      );
    }
    amplitude.getInstance().logRevenue(productId, quantity, amount);
    // amplitude.logEvent(name, properties);
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.error(`Amplitude Error: ${error}`);
    }
  }
};

export const setUserId = (userId) => {
  try {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.log(
        `UserId: ${JSON.stringify({
          userId,
        })}`
      );
    }
    amplitude.getInstance().setUserId(userId);
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.error(`Amplitude Error: ${error}`);
    }
  }
};

export const convertMonetaryValue = (currency, value) => {
  switch (currency) {
    case "EUR":
      return value / 100;
    default:
      return null;
  }
};

export const resetUser = () => {
  try {
    amplitude.getInstance().regenerateDeviceId();
    amplitude.getInstance().setUserId(null);
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.log("Reset Amplitude User");
    }
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.error(`Amplitude Error: ${error}`);
    }
  }
};
