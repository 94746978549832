import { Allergen } from "@orda/shared-constants/allergens";
import { VenueTag, FoodPreference } from "@orda/shared-constants/preferences";
import { CALL_WAITER_REASON_TYPE } from "../constants";

export default {
  common: {
    number: "{{data, number}}",
    money: "{{data, money}}",
    percent: "{{data, percent}}",
    "time-llll": "{{time, time-llll}}",
    timeTimed: "{{time, orderTime}}",
    duration: "{{duration, duration}}",
  },
  nativeUpsellBanner: {
    header: "Willkommen bei {{venueName}}.",
    mode: "Aktuell bestellst du für: {{mode}}.",
    toGo: "To-Go",
    dineIn: "Hier-Essen",
    delivery: "Lieferung",
    text: "Lade Dir die <1>ORDA-App</1> runter und schalte weitere Features frei.",
  },
  checkoutBar: {
    sumTotalLabel: "Summe",
    countLabel: "Anzahl",
    addToCart: "Zum Warenkorb hinzufügen",
    oneClickOrder: "Jetzt Bestellen",
    viewCart: "zum Warenkorb",
  },
  cart: {
    title: "Warenkorb",
    subTotalLabel: "Zwischensumme",
    feesLabel: "Gebühren",
    totalLabel: "Summe",
    benefitLabel: "Rabatt",
    tipLabel: "Trinkgeld ({{tip, percent}})",
    orderButtonLabel: "Kostenpflichtig bestellen",
    addPaymentMethodButtonLabel: "Zahlungsart auswählen",
    tip: "Möchtest Du dem Team Trinkgeld geben?",
    termsNotice: "Mit der Bestellung akzeptierst Du die <1>AGBs</1>.",
    retryButtonLabel: "Erneut Versuchen",
    paymentMethodLabel: "Zahlungsart",
    agbs: "<1>AGBs</1>",
    privacy: "<1>Datenschutz</1>",
    noteLabel: "Anmerkungen",
    toGo: "To-Go",
    dineIn: "Hier-Essen",
    delivery: "Lieferung",
    modes: "Zum Mitnehmen, Hier-Essen oder Lieferung?",
    itemPreOrder: "Vorbestellung für: ",
    toGoNow: "Schnellstmöglich",
    timedNow: "Schnellstmöglich",
    timedValue: "{{time, orderTime}}",
    emailLabel: "E-Mail",
    invoiceHelpLabel:
      "Für Deine Rechnung/Kontaktaufnahme durch das Restaurant: ",
    validEmailErrorLabel: "Bitte hinterlege eine gültige E-Mail Adresse.",
    validPhoneNumberErrorLabel: "Bitte hinterlege eine gültige Telefonnummer.",
    orderTime: "Bestellzeit",
    preOrderTime: "Vorbestellung",
    toGoPreOrderTime: "Abholzeit:",
    deliveryPreOrderTime: "Lieferzeit :",
    dineInPreOrderTime: "Reservierungszeit:",
    asapNotAvailable:
      "Einige Artikel in Ihrem Warenkorb sind nur für Vorbestellungen verfügbar!",
    minimumBasketNotReached:
      "Mindestbetrag für die Bestellung {{minimumBasket}}",
    creditCardLabel: "Kreditkarte",
    paypalLabel: "PayPal",
    applePayLabel: "Apple Pay",
    googlePayLabel: "Google Pay",
    cashOnDeliveryLabel: "Bar bei Lieferung",
    deliveryFee: "Liefergebühr",
  },
  paypalButton: {
    errorValidation:
      "Bitte stellen Sie sicher, dass alle eingegebenen Daten gültig sind.",
  },
  addressForm: {
    nameLabel: "Vor- und Nachname",
    phoneNumberLabel: "Telefonnummer",
    cityLabel: "Stadt",
    emailLabel: "E-Mail",
    additionalInfoLabel: "Zusatzinformationen",
    postalCodeLabel: "Postleitzahl",
    streetNumberLabel: "Hausnummer",
    streetLabel: "Straße",
    enterInfo: "Bitte gebe Deine Daten ein:",
    requiredStringErrorLabel: "Erforderlich.",
    requiredNumberErrorLabel: "Erforderlich (nur Ziffern erlaubt).",
    validEmailErrorLabel: "Bitte hinterlege eine gültige E-Mail Adresse.",
    validPhoneNumberErrorLabel: "Bitte hinterlege eine gültige Telefonnummer.",
    addressOutOfRange:
      "Adresse liegt außerhalb des Lieferbereichs, Lieferung nicht möglich!",
    confirmAddress: "erneut Prüfen",
    invoiceHelpLabel:
      "Für Deine Rechnung/Kontaktaufnahme durch das Restaurant: ",
  },
  lastOrderBar: {
    lastOrderLabel: "Deine letzte Bestellung:",
    // eslint-disable-next-line @typescript-eslint/camelcase
    lastOrderLabel_plural: "Deine letzten Bestellungen:",
  },
  menuItemDetail: {
    additionalInfoLabel: "Zusatzinformationen:",
    sumTotalLabel: "Summe:",
    addToBasketButton: "Zum Warenkorb hinzufügen",
    noteLabel: "Anmerkungen",
    notesDisabled:
      "{{venueName}} hat das Hinzufügen von Anmerkungen deaktiviert.",
    notesDisclaimer:
      "Bitte beachte, dass {{venueName}} die Berücksichtigung von Extrawünschen nicht garantieren kann. Kostenpflichtige Extras können nicht beachtet werden.",
    noteTextInputPlaceholder: "z.B. ohne Senf",
    iosNoteError: "Ungültiges Zeichen eingegeben: ",
  },
  additives: {
    [Allergen.Gluten]: "Gluten",
    [Allergen.Crustaceans]: "Krebstiere",
    [Allergen.Eggs]: "Eier",
    [Allergen.Fish]: "Fisch",
    [Allergen.Peanuts]: "Erdnüsse",
    [Allergen.Soy]: "Soja",
    [Allergen.Lactose]: "Laktose",
    [Allergen.Nuts]: "Nüsse",
    [Allergen.Celery]: "Sellerie",
    [Allergen.Mustard]: "Senf",
    [Allergen.Sesame]: "Sesam",
    [Allergen.Sulfite]: "Sulfit",
    [Allergen.Lupins]: "Lupinen",
    [Allergen.Molluscs]: "Weichtiere",
    [Allergen.Preservative]: "Konservierungsstoff",
    [Allergen.Colorant]: "Farbstoff",
    [Allergen.Antioxidant]: "Antioxidationsmittel",
    [Allergen.Saccharin]: "Saccharin",
    [Allergen.Cyclamate]: "Cyclamat",
    [Allergen.AspartamePhenylalanineSource]:
      "Aspartam, enth. Phenylalaninquelle",
    [Allergen.Acesulfame]: "Acesulfam",
    [Allergen.Phosphate]: "Phosphat",
    [Allergen.Sulfurized]: "Geschwefelt",
    [Allergen.Quinine]: "Chininhaltig",

    [FoodPreference.HighProtein]: "High Protein",
    [FoodPreference.LowCarb]: "Low Carb",
    [FoodPreference.Spicy]: "Scharf",
    [FoodPreference.NotSpicy]: "Nicht scharf",
    [FoodPreference.Vegan]: "Vegan",
    [FoodPreference.Vegetarian]: "Vegetarisch",

    [VenueTag.Turkish]: "Turkish",
    [VenueTag.Grill]: "Grill",
    [VenueTag.Chinese]: "Chinese",
    [VenueTag.Doner]: "Doner",
    [VenueTag.Bar]: "Bar",
    [VenueTag.Drinks]: "Drinks",
    [VenueTag.SoulFood]: "Soul Food",
    [VenueTag.Syrian]: "Syrian",
    [VenueTag.Shawarma]: "Shawarma",
    [VenueTag.Falafel]: "Falafel",
    [VenueTag.PokeBowls]: "Poké Bowls",
    [VenueTag.Fish]: "Fish",
    [VenueTag.Vietnamese]: "Vietnamese",
    [VenueTag.Indian]: "Indian",
    [VenueTag.Curry]: "Curry",
    [VenueTag.Burger]: "Burger",
    [VenueTag.American]: "American Food",
    [VenueTag.Mexican]: "Mexican",
    [VenueTag.Burrito]: "Burrito",
    [VenueTag.Taco]: "Taco",
    [VenueTag.Bowl]: "Bowl",
    [VenueTag.Asian]: "Asian",
    [VenueTag.FusionCuisine]: "Fusion Cuisine",
    [VenueTag.Sushi]: "Sushi",
    [VenueTag.Breakfast]: "Breakfast",
    [VenueTag.Brunch]: "Brunch",
    [VenueTag.HealtyFood]: "Healty Food",
    [VenueTag.Pinsa]: "Pinsa",
    [VenueTag.Lunch]: "Lunch",
    [VenueTag.Salads]: "Salads",
    [VenueTag.Bowls]: "Bowls",
    [VenueTag.Bagels]: "Bagels",
    [VenueTag.Muffins]: "Muffins",
    [VenueTag.CoffeeShop]: "Café",
    [VenueTag.FitnessFood]: "Fitness Food",
    [VenueTag.Fries]: "Fries",
    [VenueTag.Cocktails]: "Cocktails",
    [VenueTag.Vegan]: "Vegan",
    [VenueTag.HealtyFood]: "Healthy Food",
    [VenueTag.Pizza]: "Pizza",
    [VenueTag.Tacos]: "Tacos",
    [VenueTag.Burritos]: "Burritos",
    [VenueTag.Thai]: "Thai Food",
    Sy6w1tRzb: "Vegetarisch",

    containsLabel: "beinhaltet {{additive}}",
    warnLabel: "Achtung: {{additive}}",
  },
  "ui-parts": {
    notEatIn: "(nur zum Mitnehmen)",
    venueBusy:
      "Heute ist viel Betrieb bei {{venueName}}. Es wird etwas länger dauern.",
    venueVeryBusy:
      "Heute ist sehr viel Betrieb bei {{venueName}}. Stell dich auf deutlich längere Wartezeit ein.",
    notToGo: "(nur zum Hier-Essen)",
    defaultDisabledNotice: "(Bald wieder verfügbar)",
    freeLabel: "Kostenlos",
    backButton: "zurück",
    startingFromPrefix: "ab ",
    venueClosed:
      "ist derzeit geschlossen. Bestellungen sind wieder ab {{nextAvailable, calendar}} möglich",
    venueClosedWithName:
      "{{venueName}} ist zur Zeit geschlossen. Bestellungen sind wieder ab {{nextAvailable, calendar}} möglich",
    itemNotEatIn: "kann nur zum Mitnehmen bestellt werden.",
    itemNotEatInWithName:
      "{{itemName}} kann nur zum Mitnehmen bestellt werden.",
    itemNotToGo: "kann nur zum Hier-Essen bestellt werden.",
    itemNotToGoWithName:
      "{{itemName}} kann nur zum Hier-Essen bestellt werden.",
    itemNotAvailableUntil:
      "kann ab {{nextAvailable, calendar}} bestellt werden.",
    itemNotAvailableUntilWithName:
      "{{itemName}} kann ab {{nextAvailable, calendar}} bestellt werden.",
    itemNotAvailable: "ist bald wieder verfügbar.",
    itemNotAvailableWithName: "{{itemName}} ist bald wieder verfügbar.",
    sectionNotAvailable: "Bald wieder verfügbar",
    venueClosedNotice:
      "{{venueName}} ist bis {{nextOpenTime, calendar}} geschlossen.",
    venueClosedExtendedNotice: "{{venueName}} ist zurzeit geschlossen.",
    venueOfflineNotice:
      "{{venueName}} ist zur Zeit nicht verfügbar. Bitte wende Dich an das Servicepersonal vor Ort.",
    lastOrderLabel: "Deine letzte Bestellung:",
    subTotalLabel: "Zwischensumme",
    paypalLabel: "PayPal",
    applePayLabel: "Apple Pay",
    googlePayLabel: "Google Pay",
    appLinkLabel: "ORDA-App",
    downloadAppFragment1: "Verwende die ",
    downloadAppFragment2: " und genieße viele Vorteile!",
    maxChildren: "(maximal {{maxChildren}})",
    closed: "Geschlossen",
  },
  addPaymentMethod: {
    explanationText: "Zahlungsart auswählen:",
    creditCardLabel: "Kreditkarte",
    paypalLabel: "PayPal",
    termsNotice: "Mit der Bestellung akzeptierst Du die <1>AGBs</1>.",
  },
  paymentOptionItem: {
    creditCardLabel: "Kreditkarte",
    paypalLabel: "PayPal",
    applePayLabel: "Apple Pay",
    googlePayLabel: "Google Pay",
    cashOnDeliveryLabel: "Bar bei Lieferung",
    cardOnDeliveryLabel: "Karte bei Lieferung",
  },
  creditCardForm: {
    cardNumber: "Kartennummer",
    validUntil: "Gültig bis (MM/YY)",
    cvc: "CVC",
    orderButtonLabel: "Kostenpflichtig Bestellen",
    continueButtonLabel: "Weiter",
    poweredByStripe: "Bereitgestellt von Stripe",
    termsNotice: "Mit der Bestellung akzeptierst Du die <1>AGBs</1>.",
  },
  securityNotice: {
    text: "Deine Zahlung wird sicher von unserem Zahlungspartner abgewickelt.",
  },
  dataProtection: {
    agbs: "AGBs",
    privacy: "Datenschutz",
  },
  errors: {
    generic:
      "Hoppla 🙈\n\nEs ist ein Fehler aufgetreten. Bitte scanne den QR-Code erneut. Wenn das Problem weiterhin besteht, wenden Dich an den Support.",
    network:
      "Bei der Serveranfrage ist ein Fehler aufgetreten. Bitte überprüfe Deine Internetverbindung und versuche es nochmal.",
  },
  error: {
    // this is the error page
    retryButtonLabel: "Erneut Versuchen",
    backButtonLabel: "Zurück",
  },
  orderSuccess: {
    orderNumberLabel: "Deine Bestellnummer:",
    messageOrderConfirmed: "Deine Bestellung wurde erfolgreich übermittelt.",
    explanationTable:
      "Sobald Deine Bestellung fertig ist, bringen wir Sie zu Dir an den Tisch.",
    warning: "Wichtig:",
    tableNewOrderWarning:
      "Wenn Du eine weitere Bestellung aufgeben möchtest, scanne den QR-Code bitte erneut. Nur dann wird Deine Tischnummer auch der Bestellung hinterlegt.",
    explanationPushNotificationEatIn: "Sobald Dei Tisch gebracht.",
    explanationPushNotificationToGoNow:
      "Bitte hole Deine Bestellung bei {{venueName}} um {{time, time-hhmm}} ab.",
    explanationPushNotificationToGoTimed:
      "Bitte hole Deine Bestellung bei {{venueName}} um {{time, orderTime}} ab.",
    explanationPushNotificationDeliveryTimed:
      "Deine Bestellung bei {{venueName}} wird um circa {{time, orderTime}} geliefert.",
    messageEnjoy: "Enjoy 😋",
    fullNameLabel: "Vollständiger Name: {{fullName}}",
    deliveryAdditionalInformationLabel:
      "Zusätzliche informationen: {{deliveryAdditionalInformation}}",
    deliveryAddressLabel: "Lieferadresse: {{deliveryAddress}}",
    phoneNumberLabel: "Telefonnummer: {{phoneNumber}}",
    avgDeliveryTimeLabel:
      "Deine Bestellung bei {{venueName}} wird in circa {{avgTime}} Minuten geliefert.",
    backButtonLabel: "Zurück",
    toGoNow: "Schnellstmöglich",
  },
  errorNotFound: {
    header: "Upps 🙈",
    errorText: "Während Deiner Anfrage ist ein Fehler aufgetreten.",
    retryText:
      "Bitte scanne den QR-Code erneut. Wenn das Problem weiterhin besteht, wenden Dich an den Support.",
  },
  burgerMenu: {
    downloadApp: "Download App",
    imprint: "Impressum",
    restaurantInfo: "Restaurant Info",
  },
  venueInfoModal: {
    currency: "Währung:",
    venueAddress: "Adresse des Restaurants:",
    orderLocations: "Bestellmöglichkeiten:",
    toGo: "To-Go",
    dineIn: "Hier-Essen",
    delivery: "Lieferung",
    prepTimeLabel: "Durchschnittliche Vorbereitungszeit:",
    prepTime: "{{- prepTime}} min",
    phoneNumber: "Telefonnummer:",
    openingHours: "Öffnungszeiten:",
  },
  downloadAppModal: {
    title: "VERWENDE DIE ORDA APP",
    content: "UND PROFITIERE VON WEITEREN VORTEILEN",
    buttonLabel: "ZUR APP",
  },
  googleDeliveryForm: {
    checkAddress: "Lieferadresse eingeben",
    addressPlaceholder: "Straße und Hausnummer, Ort und Postleitzahl",
    addressOutOfRange:
      "Adresse außerhalb des Bereichs, Lieferung nicht möglich",
    errorStreetNumber: "Bitte geben Sie die Straße und die Hausnummer ein.",
    confirmAddress: "Diese Adresse als Lieferadresse verwenden",
    correctAddress: "Deine Adresse liegt in unserem Liefergebiet.",
    myLocation: "Schaltfläche Aktueller Standort.",
  },
  startScreen: {
    title: "Wilkommen bei {{venueName}}!",
    subTitle: "Wilkommen! Triff Deine Auswahl.",
    callWaiter: "Service rufen",
    orderNow: "Speisekarte",
    pay: "Bezahlen",
    posMessageError:
      "Es ist ein Fehler aufgetreten. Bitte versuche es erneut oder wende dich direkt an das Servicepersonal.",
    posMessageSuccess: "Das Servicepersonal wurde erfolgreich informiert.",
  },
  callWaiterModal: {
    callWaiterTitle: "Service rufen",
    callWaiterReason:
      "Bitte gib den Grund für den Ruf des Servicepersonals an.",
    customerInfoPlaceholder: "Kommentare für Personal.",
    callWaiterButtonLabel: "Senden",
    [CALL_WAITER_REASON_TYPE.Order]: "Bestellen",
    [CALL_WAITER_REASON_TYPE.Pay]: "Bezahlen",
    [CALL_WAITER_REASON_TYPE.Other]: "Andere",
  },
  inHousePayment: {
    title: "Bestellübersicht",
    sumTotalLabel: "Summe:",
    payButtonLabel: "{{sumTotal}} Bezahlen",
    noPositions:
      "Keine offenen Positionen gefunden.\n\nBitte wende dich an das Servicepersonal.",
    posMessageError:
      "Es ist ein Fehler aufgetreten.\nBitte versuche es erneut oder wende dich direkt an das Servicepersonal.",
  },
  inHouseCheckout: {
    title: "Checkout",
    checkoutError:
      "Es ist ein Fehler aufgetreten.\nBitte wende dich an das Servicepersonal.",
    checkoutSuccess: "Deine Zahlung war erfolgreich!",
    redirectMessage: "Umleitung...",
  },
};
