import {
  VENUE_LOCATION_EAT_IN,
  VENUE_LOCATION_TO_GO,
  VENUE_LOCATION_DELIVERY,
} from "@orda/shared-constants/order-locations";

import {
  currentlyWithinOpeningHours,
  currentlyWithinSpecificHours,
} from "@orda/shared-functions-js/lib/opening-hours";
import moment from "moment-timezone";
import { MAX_PREORDERHOURS } from "../constants";

export const OUTSIDE_VENUE_OPENING_HOURS = "OUSTIDE_VENUE_OPENING_HOURS";
export const OUTSIDE_ITEM_OPENING_HOURS = "OUTSIDE_ITEM_OPENING_HOURS";
const UNAVAILABLE = "UNAVAILABLE";
const NOT_AVAILABLE_EAT_IN = "NOT_AVAILABLE_EAT_IN";
const NOT_AVAILABLE_TO_GO = "NOT_AVAILABLE_TO_GO";

const VENUE_CLOSED = "VENUE_CLOSED";
const VENUE_CLOSED_EXTENDED = "VENUE_CLOSED_EXTENDED";
const VENUE_OFFLINE_NOTICE = "VENUE_OFFLINE_NOTICE";

/**
 * Returns a status object based on the item availability. The object contains
 * available, reason and nextAvailable keys. The reason key is a constant which can be used to generate
 * explanation strings where needed. The nextAvailable is used to show the next available time when needed
 *
 * @param {object} openingHours
 * @param {object} item
 * @param {bool} skipOpeningHours
 * @param {string} orderLocation
 * @param {string} orderTime
 */
export const getItemAvailability = (
  openingHours,
  { hours, unavailable, notToGo, notEatIn },
  skipOpeningHours,
  orderLocation,
  orderTime
) => {
  if (unavailable) {
    return {
      available: false,
      reason: UNAVAILABLE,
    };
  }
  const usedMoment = moment.tz(openingHours.timeZone);
  if (orderLocation === VENUE_LOCATION_TO_GO && orderTime) {
    usedMoment.hour(`${orderTime[0]}${orderTime[1]}`);
    usedMoment.minute(`${orderTime[2]}${orderTime[3]}`);
  }

  const withinOpeningHours = currentlyWithinOpeningHours(openingHours);

  if (!withinOpeningHours.withinOpeningHours && !skipOpeningHours) {
    return {
      available: false,
      reason: OUTSIDE_VENUE_OPENING_HOURS,
      nextAvailable: withinOpeningHours.nextOpen,
    };
  }

  switch (orderLocation) {
    case VENUE_LOCATION_EAT_IN:
      if (notEatIn) {
        return {
          available: false,
          reason: NOT_AVAILABLE_EAT_IN,
        };
      }
      break;
    case VENUE_LOCATION_TO_GO:
      if (notToGo) {
        return {
          available: false,
          reason: NOT_AVAILABLE_TO_GO,
        };
      }
      break;
    case VENUE_LOCATION_DELIVERY:
      if (notToGo) {
        return {
          available: false,
          reason: NOT_AVAILABLE_TO_GO,
        };
      }
      break;
    default:
      throw new Error(`Unknown orderLocation: ${orderLocation}`);
  }

  if (hours && withinOpeningHours.withinOpeningHours) {
    const withinItemHours = currentlyWithinSpecificHours(
      openingHours,
      hours,
      orderTime
    );
    let didSurpassMaxPreorderTime = true;
    const orderTimeString =
      orderTime || moment.tz(openingHours.timeZone).format("HHmm");
    if (withinItemHours.nextOpen > 0) {
      const initialTime = moment().tz(openingHours.timeZone);
      initialTime.set({
        hour: parseInt(`${orderTimeString[0]}${orderTimeString[1]}`, 10),
        minute: parseInt(`${orderTimeString[2]}${orderTimeString[3]}`, 10),
        second: 0,
        millisecond: 0,
      });
      didSurpassMaxPreorderTime =
        moment
          .unix(withinItemHours.nextOpen)
          .tz(openingHours.timeZone)
          .diff(initialTime, "hours") > MAX_PREORDERHOURS;
    }

    if (!withinItemHours.withinOpeningHours && didSurpassMaxPreorderTime) {
      return {
        available: false,
        reason: OUTSIDE_ITEM_OPENING_HOURS,
        nextAvailable: withinItemHours.nextOpen,
      };
    }
    return {
      available: true,
      reason: OUTSIDE_ITEM_OPENING_HOURS,
      nextAvailable: withinItemHours.nextOpen,
    };
  }

  return {
    available: true,
    reason: "",
  };
};

export const getVenueOpenStatus = (venue) => {
  const { openingHours, isOffline, maintenance } = venue;
  const withinHours = currentlyWithinOpeningHours(openingHours);
  if (!withinHours.withinOpeningHours) {
    if (withinHours.nextOpen !== 0) {
      return {
        closed: true,
        reason: VENUE_CLOSED,
        nextOpenTime: withinHours.nextOpen,
      };
    }
    return {
      closed: true,
      reason: VENUE_CLOSED_EXTENDED,
    };
  }

  if (isOffline || maintenance) {
    return {
      closed: true,
      reason: VENUE_OFFLINE_NOTICE,
    };
  }

  return {
    closed: false,
    reason: "",
  };
};

/**
 * Returns string explanation text for an unavailable item. Used in conjunction with getItemAvailabilitu function
 *
 * @param {func} t
 * @param {object} availability
 * @param {string} venueName
 * @param {string} itemName
 */
export const getItemUnvailableExplanationText = (
  t,
  availability,
  venueName,
  itemName
) => {
  switch (availability.reason) {
    case OUTSIDE_VENUE_OPENING_HOURS: {
      return venueName
        ? t("ui-parts:venueClosedWithName", {
            venueName,
            nextAvailable: availability.nextAvailable,
          })
        : t("ui-parts:venueClosed", {
            nextAvailable: availability.nextAvailable,
          });
    }
    case NOT_AVAILABLE_EAT_IN: {
      return itemName
        ? t("ui-parts:itemNotEatInWithName", { itemName })
        : t("ui-parts:itemNotEatIn");
    }
    case NOT_AVAILABLE_TO_GO: {
      return itemName
        ? t("ui-parts:itemNotToGoWithName", { itemName })
        : t("ui-parts:itemNotToGo");
    }
    case OUTSIDE_ITEM_OPENING_HOURS:
      return itemName
        ? t("ui-parts:itemNotAvailableUntilWithName", {
            itemName,
            nextAvailable: availability.nextAvailable,
          })
        : t("ui-parts:itemNotAvailabeUntil", {
            nextAvailable: availability.nextAvailable,
          });
    case UNAVAILABLE:
      return itemName
        ? t("ui-parts:itemNotAvailableWithName", { itemName })
        : t("ui-parts:itemNotAvailable");
    default:
      throw new Error(`Unknown unavailable reason: ${availability.reason}`);
  }
};

export const getVenueClosedExplanation = (t, venueOpenStatus, venueName) => {
  switch (venueOpenStatus.reason) {
    case VENUE_CLOSED:
      return t("ui-parts:venueClosedNotice", {
        venueName,
        nextOpenTime: venueOpenStatus.nextOpenTime,
      });
    case VENUE_CLOSED_EXTENDED:
      return t("ui-parts:venueClosedExtendedNotice", { venueName });
    case VENUE_OFFLINE_NOTICE:
      return t("ui-parts:venueOfflineNotice", {
        venueName,
      });
    default:
      throw new Error(`Unknown venue closed reason: ${venueOpenStatus.reason}`);
  }
};

/**
 * Returns a the opening time to use of a list of item (example: if two items have different availablity time, the function will return the biggest time)
 * @param {array} items
 */
export const getCompatibleOpeningTime = (itemsOpeningHours) => {
  let maxOpeningHour = [];
  if (itemsOpeningHours.length === 1) return itemsOpeningHours[0];
  for (let index = 0; index < itemsOpeningHours.length; index++) {
    if (!itemsOpeningHours[index][moment().isoWeekday()]) {
      continue;
    }
    const openingHour = itemsOpeningHours[index][moment().isoWeekday()];
    for (let index2 = 0; index2 < openingHour.length; index2++) {
      const element = openingHour[index2];
      if (
        !maxOpeningHour.startHour ||
        element.startHour >= maxOpeningHour.startHour
      ) {
        maxOpeningHour = itemsOpeningHours[index];
      }
    }
  }
  return maxOpeningHour;
};
