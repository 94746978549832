import React from "react";
import PropTypes from "prop-types";
import {
  VENUE_LOCATION_EAT_IN,
  VENUE_LOCATION_TO_GO,
} from "@orda/shared-constants/order-locations";
import { PRICE_DISPLAY_DEFAULT } from "@orda/shared-constants-js/price-display";
import { getCharacter } from "../lib/additives";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles, Typography } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import Price from "../containers/Price";
import { formatPricePrefix } from "../lib/price";
import TypographyExponent from "./TypographyExponent";
import { THEME } from "../theme";

const styles = {
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  label: {
    marginLeft: 0,
  },
  labelContainer: {
    display: "flex",
    flexDirection: "column",
  },
  labelDisplay: {
    fontSize: "1em",
    fontWeight: "700",
  },
  descriptionDisplay: {
    fontSize: "0.8rem",
    fontWeight: "400",
    color: THEME.SECONDARY_TEXT_COLOR,
  },
  disabledDisplay: {
    fontSize: "0.8rem",
    fontWeight: "400",
    color: THEME.ACCENT_RED,
  },
};

const OrOption = ({
  t,
  checked,
  onCheckedChange,
  label,
  itemId,
  additives,
  tags,
  priceDisplay,
  unavailable,
  notEatIn,
  notToGo,
  orderLocation,
  benefits,
  value,
  classes,
  description,
}) => {
  let notice;

  switch (orderLocation) {
    case VENUE_LOCATION_EAT_IN:
      if (notEatIn) {
        notice = t("notEatIn");
      }
      break;
    case VENUE_LOCATION_TO_GO:
      if (notToGo) {
        notice = t("notToGo");
      }
      break;
    default:
  }

  const labelComponent = (
    <div className={classes.labelContainer}>
      {tags.length > 0 ? (
        <TypographyExponent
          base={label}
          exponent={tags
            .map((tag) => getCharacter(additives.indexOf(tag)))
            .join(",")}
          labelDisplay={styles.labelDisplay}
        />
      ) : (
        <Typography component="span" className={classes.labelDisplay}>
          {label}
        </Typography>
      )}
      {!notice && !!description && (
        <Typography component="span" className={classes.descriptionDisplay}>
          {description}
        </Typography>
      )}
      {(unavailable || !!notice) && (
        <Typography component="span" className={classes.disabledDisplay}>
          {notice || t("defaultDisabledNotice")}
        </Typography>
      )}
    </div>
  );

  return (
    <div className={classes.root}>
      <FormControlLabel
        value={value}
        label={labelComponent}
        checked={checked}
        onChange={onCheckedChange}
        disabled={unavailable || !!notice}
        control={<Radio style={{ color: THEME.BUTTON_BACKGROUND_COLOR }} />}
      />
      <Price
        priceDisplay={priceDisplay}
        hideFree
        prefix={formatPricePrefix(priceDisplay)}
        benefits={benefits}
        itemId={itemId}
      />
    </div>
  );
};

OrOption.propTypes = {
  notToGo: PropTypes.bool,
  notEatIn: PropTypes.bool,
  unavailable: PropTypes.bool,
  tags: PropTypes.array,
  priceDisplay: PropTypes.number,
  t: PropTypes.func.isRequired,
  onCheckedChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  additives: PropTypes.array.isRequired,
  orderLocation: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  benefits: PropTypes.array.isRequired,
  checked: PropTypes.bool.isRequired,
  description: PropTypes.string,
};

OrOption.defaultProps = {
  notToGo: false,
  notEatIn: false,
  unavailable: false,
  tags: [],
  priceDisplay: PRICE_DISPLAY_DEFAULT,
  description: null,
};

export default withTranslation("ui-parts")(withStyles(styles)(OrOption));
