export function replaceTrailingSpaces(input) {
  if (!input) {
    return "";
  }

  let trailingSpaces = 0;
  for (let i = input.length - 1; i >= 0; i--) {
    if (input[i] === " ") {
      trailingSpaces++;
    } else {
      break;
    }
  }
  return `${input.substring(0, input.length - trailingSpaces)}${"\u00a0".repeat(
    trailingSpaces
  )}`;
}
