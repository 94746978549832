import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { withTranslation, Trans } from "react-i18next";
import CartItem from "../../containers/Cart/CartItem";
import Button from "@material-ui/core/Button";
import Loading from "../Loading";
import PaypalButton from "../../containers/PaypalButton";
import routes from "../../routes";
import CartPrepareOrderError from "./CartPrepareOrderError";
import CartCalculations from "./CartCalculations";
import Deferred from "../../util/deferred";
import SecurityNotice from "../SecurityNotice";
import Footer from "./Footer";
import TipSelector from "../../containers/TipSelector";
import {
  VENUE_LOCATION_EAT_IN,
  VENUE_LOCATION_TO_GO,
  VENUE_LOCATION_DELIVERY,
} from "@orda/shared-constants/order-locations";
import AddressForm from "../../containers/Cart/AddressForm";
import {
  validateEmail,
  validateNumber,
  validatePhoneNumber,
} from "../../util/validation";
import { THEME } from "../../theme";
import { Link } from "react-router-dom";
import { ArrowLeft } from "mdi-material-ui";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import Header from "../Header";
import { PaymentOption } from "@orda/shared-constants";
import { injectStripe } from "react-stripe-elements";
import InvoiceForm from "../../containers/Cart/InvoiceForm";
import PaymentMethodInformation from "../../containers/Cart/PaymentMethodInformation";
import CartOrderLocationSwitcherBar from "../../containers/CartOrderLocationSwitcherBar";
import GoogleDeliveryForm from "../../containers/GoogleDeliveryForm";

const styles = {
  cartContainer: {
    width: "100%",
    backgroundColor: "#fafafa",
    display: "flex",
    flexDirection: "column",
  },
  locationOptionContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    justifyContent: "space-evenly",
  },
  preOrderTimeButtonContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    margin: "2%",
    justifyContent: "space-evenly",
  },
  preOrderTimeContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  venueName: {
    color: "white",
  },
  cartCardContainer: {
    margin: "0.75rem",
  },
  cartCard: {
    padding: "1rem",
  },
  tipCard: {
    padding: "1rem 1rem 0 1rem",
  },
  actionContainer: {
    margin: "2rem 0.75rem 0.75rem 0.75rem",
  },
  termsNotice: {
    textAlign: "center",
    color: "#999",
    fontSize: "0.8em",
    fontFamily: "Open Sans",
    marginBottom: 10,
  },
  link: {
    color: "#1565c0",
  },
  button: {
    height: 45,
    color: THEME.WHITE_TEXT_COLOR,
    backgroundColor: THEME.BUTTON_BACKGROUND_COLOR,
    "&:hover": {
      backgroundColor: THEME.BUTTON_BACKGROUND_COLOR,
    },
  },
  orderLocationButton: {
    padding: "5px",
    width: "110px",
    minHeight: "50px",
    minWidth: "60px",
    maxWidth: "150px",
    flexGrow: 1,
  },
  optionButton: {
    padding: "5px",
    width: "150px",
    minHeight: "50px",
    minWidth: "60px",
    maxWidth: "150px",
    "@media (max-width: 770px)": {
      flexGrow: 1,
      width: "145px",
    },
  },
  selectOptionButton: {
    width: "150px",
    minWidth: "60px",
    maxWidth: "150px",
    "@media (max-width: 770px)": {
      flexGrow: 1,
      maxWidth: "100%",
    },
    margin: "10px 0",
  },
  selectedButton: {
    color: "white",
    backgroundColor: THEME.BUTTON_BACKGROUND_COLOR,
    "&:hover": {
      backgroundColor: THEME.BUTTON_BACKGROUND_COLOR,
    },
  },
  notSelectedButton: {
    backgroundColor: THEME.DISABLED_BUTTON_ICON,
    color: "white",
    "&:hover": {
      backgroundColor: THEME.BUTTON_BACKGROUND_COLOR,
      color: "white",
    },
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  inverse: {
    color: "#fff",
  },
  footerContainer: {
    width: "100%",
    paddingBottom: 20,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "flex-end",
  },
  text: {
    marginBottom: "20px",
  },
  securityNoticeContainer: {
    margin: "1.75em",
  },
  iconButton: {
    color: THEME.PRIMARY_BUTTON_ICON,
    backgroundColor: THEME.SECONDARY_BUTTON_ICON,
    "&:hover": {
      opacity: 0.8,
    },
    borderRadius: 0,
    fontSize: "clamp(0.7rem, 3vmin, 1rem)",
  },
  headerContainer: {
    display: "flex",
    backgroundColor: THEME.SECONDARY_BUTTON_ICON,
    boxShadow: "0px 4px 20px 1px rgba(0,0,0,0.2)",
    position: "sticky",
    height: 50,
    top: 0,
    zIndex: 3,
  },
  header: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
};

// payment with inner logic for submiting order meaning no submit order trigger inside of cart
const customSubmitOrderPaymentMethods = [
  PaymentOption.PayPalCheckout,
  PaymentOption.PayPalPrivateCheckout,
];

const Cart = ({
  t,
  classes,
  venueId,
  locale,
  currency,
  groupedItems,
  currentPaymentMethod,
  hasSeenTermsNotice,
  authUser,
  authSyncFinished,
  prepareOrderProcessing,
  prepareOrderError,
  preparedOrder,
  prepareOrder,
  setLocationOption,
  executeOrder,
  executeOrderProcessing,
  clearOrder,
  clearPreparedOrder,
  navigate,
  subTotal,
  location,
  userName,
  allowedTOGO,
  allowedDINEIN,
  allowedDelivery,
  tableId,
  // default for DINEIN
  allowedPreOrderForEatIn,
  allowedPreOrderForDelivery,
  orderTimes,
  orderTime,
  userEmail,
  phoneNumber,
  tip,
  addCreditCardProcessing,
  addPaymentMethodProcessing,
  setOrderTime,
  sendInvoiceTOGO,
  sendInvoiceDINEIN,
  deliveryStreetName,
  deliveryStreetNumber,
  deliveryCity,
  deliveryPostalCode,
  asapNotAvailable,
  minimumBasketNotReached,
  minimumBasket,
  tipAllowed,
  setGooglePayToken,
  setApplePayToken,
  label,
  stripe,
  login,
  loginProcessing,
  countryCode,
}) => {
  // setup a new Deferred object
  const { current: executeOrderNotifyDeferred } = useRef(new Deferred());
  const emailViewRef = useRef();
  const deliveryFormViewRef = useRef();
  const footerViewRef = useRef();
  let paymentFlowState = false;
  const addressEmpty =
    !deliveryStreetName && !deliveryStreetNumber && !deliveryPostalCode;
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);

  const [showOrderTimesContent, setShowOrderTimesContent] = useState(true);
  const [showPreOrderTimes, setShowPreOrderTimes] = useState(false);
  const [isEmailRequired, setIsEmailRequired] = useState(false);
  const [orderTimeSelected, setOrderTimeSelected] = useState(orderTime || "");
  const [showAllErrors, setShowAllErrors] = useState(false);
  const [errorOutOfrange, setErrorOutOfRange] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [triggerSubmitAddressForm, setTriggerSubmitAddressForm] =
    useState(false);
  const [openModal, setOpenModal] = useState(false);
  /**
   * Difference between the triggerValidateAddressForm and triggerValidateDeliveryAddress is that:
   * triggerValidateAddressForm validate the string
   * triggerValidateDeliveryAddress validate the actual distance and range of that address from the restaurant
   */
  const [triggerValidateAddressForm, setTriggerValidateAddressForm] =
    useState(false);

  const formDataNotValid =
    !userName ||
    !validateEmail(userEmail) ||
    !deliveryStreetName ||
    !validateNumber(deliveryStreetNumber) ||
    !deliveryCity ||
    !validateNumber(deliveryPostalCode) ||
    location !== VENUE_LOCATION_DELIVERY;

  const invoiceFormDataNotValid =
    !userName || !validatePhoneNumber(phoneNumber) || !validateEmail(userEmail);

  useEffect(() => {
    if (authSyncFinished && !authUser) {
      login();
    }
  }, [authSyncFinished, !!authUser]);

  useEffect(() => {
    global.window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (!location) {
      if (allowedTOGO) {
        setIsEmailRequired(sendInvoiceTOGO);
        setLocationOption(VENUE_LOCATION_TO_GO);
      } else if (allowedDINEIN) {
        setIsEmailRequired(sendInvoiceDINEIN);
        setLocationOption(VENUE_LOCATION_EAT_IN);
      } else if (allowedDelivery) {
        setLocationOption(VENUE_LOCATION_DELIVERY);
      } else {
        setLocationOption(VENUE_LOCATION_TO_GO);
      }
    }
    if (tableId && allowedDINEIN) {
      setLocationOption(VENUE_LOCATION_EAT_IN);
    }
    if (orderTime) {
      setOrderTimeSelected(orderTime);
      setOrderTime(orderTime);
    }
  }, []);

  useEffect(() => {
    if (location === VENUE_LOCATION_DELIVERY) {
      if (allowedPreOrderForDelivery) {
        setShowOrderTimesContent(true);
      } else {
        setShowOrderTimesContent(false);
      }
    } else if (location === VENUE_LOCATION_EAT_IN) {
      setIsEmailRequired(sendInvoiceDINEIN);
      if (allowedPreOrderForEatIn) {
        setShowOrderTimesContent(true);
      } else {
        setShowOrderTimesContent(false);
      }
    } else if (location === VENUE_LOCATION_TO_GO) {
      setIsEmailRequired(sendInvoiceTOGO);
      setShowOrderTimesContent(true);
    }
  }, [location]);

  const preOrderTimeString = useMemo(() => {
    if (location === VENUE_LOCATION_TO_GO) {
      return t("toGoPreOrderTime");
    }
    if (location === VENUE_LOCATION_DELIVERY) {
      return t("deliveryPreOrderTime");
    }
    return t("dineInPreOrderTime");
  }, [location]);

  useEffect(() => {
    if (
      authSyncFinished &&
      currentPaymentMethod &&
      !prepareOrderProcessing &&
      !prepareOrderError &&
      !preparedOrder &&
      groupedItems.length !== 0 &&
      ((location === VENUE_LOCATION_DELIVERY && !formDataNotValid) ||
        location !== VENUE_LOCATION_DELIVERY)
    ) {
      prepareOrder();
    }
  }, [
    authSyncFinished,
    currentPaymentMethod,
    prepareOrderProcessing,
    triggerSubmitAddressForm,
    prepareOrderError,
    !!preparedOrder,
    tip,
    groupedItems.length,
    location,
    formDataNotValid,
    invoiceFormDataNotValid,
    orderTime,
  ]);

  useEffect(() => {
    if (groupedItems.length === 0) {
      navigate(routes.menu.template(venueId));
    }
  }, [groupedItems.length]);

  useEffect(() => {
    executeOrderNotifyDeferred.cancelled = false;
    return () => {
      executeOrderNotifyDeferred.cancelled = true;
    };
  }, []);

  const awaitExecuteOrderFinish = async () => {
    try {
      await executeOrderNotifyDeferred.promise;
      // order success redirect to order success
      navigate(routes.orders.success.path);
      clearOrder();
    } catch (error) {
      // TODO: rethink how this is implemented
      // if (error.message === PROCESSING_CANCELLED_PROMISE) {
      //   // handle cancelled promise
      //   return;
      // }
      // error injected redirect to error page
      navigate(routes.errors.operation.path);
      clearPreparedOrder();
    }
  };

  let action;

  useEffect(() => {
    setTriggerSubmitAddressForm(false);
  }, [
    deliveryStreetName,
    deliveryStreetNumber,
    deliveryCity,
    deliveryPostalCode,
  ]);

  useEffect(() => {
    if (paymentRequest && preparedOrder) {
      paymentRequest.update({
        total: {
          label,
          amount: preparedOrder.sumTotal,
        },
      });
    }
  }, [tip, subTotal, preparedOrder]);

  useEffect(() => {
    if (
      stripe &&
      currentPaymentMethod &&
      !paymentRequest &&
      !paymentFlowState &&
      (currentPaymentMethod.type === PaymentOption.ApplePay ||
        currentPaymentMethod.type === PaymentOption.GooglePay)
    ) {
      paymentFlowState = false;
      const pr = stripe.paymentRequest({
        country: countryCode.toUpperCase(),
        currency: (currency && currency.toLowerCase()) || "eur",
        total: {
          label,
          amount: subTotal,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, currentPaymentMethod, paymentFlowState]);

  useEffect(() => {
    if (
      footerViewRef.current &&
      +searchParams.get("addPaymentOptionDone") === 1
    ) {
      footerViewRef.current.scrollIntoView({
        behavior: "smooth",
      });
      searchParams.delete("addPaymentOptionDone");
    }
  }, [searchParams]);

  const nativePaymentFlowStart = () => {
    paymentRequest.on("token", async (event) => {
      try {
        if (!paymentFlowState) {
          if (currentPaymentMethod.type === PaymentOption.GooglePay) {
            setGooglePayToken(event.token.id);
          } else if (currentPaymentMethod.type === PaymentOption.ApplePay) {
            setApplePayToken(event.token.id);
          }
          event.complete("success");
          setPaymentRequest(null);
          awaitExecuteOrderFinish();
          executeOrder(executeOrderNotifyDeferred);
        }
        paymentFlowState = true;
      } catch (error) {
        setPaymentRequest(null);
        paymentFlowState = false;
        event.complete("fail");
      }
    });
  };

  if (currentPaymentMethod) {
    if (
      customSubmitOrderPaymentMethods.includes(currentPaymentMethod.type) &&
      preparedOrder
    ) {
      action = (
        <PaypalButton
          venueId={venueId}
          preparedOrder={preparedOrder}
          authUser={authUser}
          notifyPromise={executeOrderNotifyDeferred}
          awaitExecuteOrderFinish={awaitExecuteOrderFinish}
          isReadyToOrder={() => {
            if (location === VENUE_LOCATION_DELIVERY) {
              if (
                formDataNotValid ||
                minimumBasketNotReached ||
                errorOutOfrange
              ) {
                setTriggerSubmitAddressForm(false);
                setTriggerValidateAddressForm(true);
                return false;
              }
            } else if (invoiceFormDataNotValid) {
              setShowAllErrors(true);
              return false;
            } else if (
              location === VENUE_LOCATION_TO_GO &&
              minimumBasketNotReached
            ) {
              return false;
            }
            return true;
          }}
        />
      );
    } else {
      action = (
        <Button
          fullWidth
          className={classes.button}
          variant="contained"
          size="large"
          color="primary"
          onClick={() => {
            if (location === VENUE_LOCATION_DELIVERY) {
              if (
                formDataNotValid ||
                !preparedOrder ||
                minimumBasketNotReached
              ) {
                deliveryFormViewRef.current.scrollIntoView({
                  behavior: "smooth",
                });
                setTriggerSubmitAddressForm(false);
                setTriggerValidateAddressForm(true);
              } else {
                if (
                  currentPaymentMethod.type === PaymentOption.ApplePay ||
                  currentPaymentMethod.type === PaymentOption.GooglePay
                ) {
                  paymentRequest.show();
                }
                setTriggerValidateAddressForm(false);
                setTriggerSubmitAddressForm(true);
              }
            } else if (
              invoiceFormDataNotValid ||
              !preparedOrder ||
              minimumBasketNotReached
            ) {
              emailViewRef.current.scrollIntoView({ behavior: "smooth" });
              setShowAllErrors(true);
            } else if (
              currentPaymentMethod.type === PaymentOption.ApplePay ||
              currentPaymentMethod.type === PaymentOption.GooglePay
            ) {
              paymentRequest.show();
              nativePaymentFlowStart();
            } else {
              awaitExecuteOrderFinish();
              executeOrder(executeOrderNotifyDeferred);
            }
          }}
          disabled={
            executeOrderProcessing ||
            prepareOrderProcessing ||
            prepareOrderError ||
            errorOutOfrange ||
            addPaymentMethodProcessing ||
            addCreditCardProcessing ||
            ((currentPaymentMethod.type === PaymentOption.ApplePay ||
              currentPaymentMethod.type === PaymentOption.GooglePay) &&
              !paymentRequest)
          }
        >
          {executeOrderProcessing ? (
            <CircularProgress size={20} className={classes.inverse} />
          ) : (
            t("orderButtonLabel")
          )}
        </Button>
      );
    }
  } else {
    action = (
      <Button
        fullWidth
        className={classes.button}
        variant="contained"
        size="large"
        component={Link}
        to={routes.payments.add.template(venueId)}
      >
        {addCreditCardProcessing || addPaymentMethodProcessing ? (
          <CircularProgress size={20} className={classes.inverse} />
        ) : (
          t("addPaymentMethodButtonLabel")
        )}
      </Button>
    );
  }

  let calculationsContent;
  if (prepareOrderProcessing) {
    calculationsContent = (
      <div className={classes.progressContainer}>
        <CircularProgress size={30} thickness={5} />
      </div>
    );
  } else if (!prepareOrderProcessing && prepareOrderError) {
    calculationsContent = (
      <CartPrepareOrderError
        prepareOrderError={prepareOrderError}
        prepareOrder={prepareOrder}
      />
    );
  } else if (!prepareOrderProcessing && !prepareOrderError && preparedOrder) {
    calculationsContent = (
      <CartCalculations
        currency={currency}
        locale={locale}
        sumBeforeEverything={preparedOrder.sumBeforeEverything}
        sumBenefit={preparedOrder.sumBenefit}
        sumBenefitSum={preparedOrder.sumBenefitSum}
        fees={preparedOrder.fees}
        feeSum={preparedOrder.feeSum}
        sumTotal={preparedOrder.sumTotal}
        tip={tip}
        tipSum={preparedOrder.tipSum}
        showMinimumBasketNotReachedError={
          preparedOrder && minimumBasketNotReached
        }
        minimumBasket={minimumBasket}
        totalBenefit={preparedOrder.totalBenefit}
      />
    );
  }

  const orderTimesContent = (
    <FormControl className={classes.selectOptionButton} variant="outlined">
      <InputLabel id="order-time-select-label">{t("orderTime")}</InputLabel>
      <Select
        labelid="order-time-select-label"
        id="order-time-select"
        value={orderTimeSelected}
        label={t("orderTime")}
        onChange={(event) => {
          setOrderTimeSelected(event.target.value);
          setOrderTime(event.target.value);
        }}
        input={
          <OutlinedInput
            id="order-time-select-label"
            labelWidth={t("orderTime").length * 10}
          />
        }
      >
        {orderTimes.map((orderTime_) => (
          <MenuItem value={orderTime_} key={orderTime_}>
            {t("timedValue", { time: orderTime_ })}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  // initial state update
  return !authSyncFinished || (!authUser && loginProcessing) ? (
    <Loading />
  ) : (
    <div className={classes.cartContainer}>
      <div className={classes.headerContainer}>
        <IconButton
          className={classes.iconButton}
          component={Link}
          to={routes.menu.template(venueId)}
        >
          <ArrowLeft fontSize="small" />
        </IconButton>
        <div className={classes.header}>
          <Header title={t("title")} />
        </div>
      </div>
      <div className={classes.cartCardContainer}>
        <Paper className={classes.cartCard}>
          {groupedItems.length !== 0 &&
            groupedItems.map((section) => (
              <Fragment key={section.key}>
                {section.data.map((itemGroup) => (
                  <CartItem key={itemGroup.id} {...itemGroup} />
                ))}
              </Fragment>
            ))}
        </Paper>
      </div>
      <div className={classes.cartCardContainer}>
        <Paper className={classes.cartCard}>
          <CartOrderLocationSwitcherBar
            setOrderTimeSelected={setOrderTimeSelected}
            setShowPreOrderTimes={setShowPreOrderTimes}
            asapNotAvailable={asapNotAvailable}
            setOpenModal={setOpenModal}
            addressEmpty={addressEmpty}
          />
        </Paper>
      </div>
      {location === VENUE_LOCATION_DELIVERY && allowedDelivery && (
        <div className={classes.cartCardContainer} ref={deliveryFormViewRef}>
          <Paper className={classes.cartCard}>
            <AddressForm
              triggerSubmitAddressForm={triggerSubmitAddressForm}
              triggerValidateAddressForm={triggerValidateAddressForm}
              setTriggerValidateAddressForm={setTriggerValidateAddressForm}
              setShowOrderTimesContent={setShowOrderTimesContent}
              setErrorOutOfRange={setErrorOutOfRange}
              errorOutOfrange={errorOutOfrange}
              submitOrder={() => {
                if (preparedOrder && !minimumBasketNotReached) {
                  if (
                    currentPaymentMethod.type === PaymentOption.ApplePay ||
                    currentPaymentMethod.type === PaymentOption.GooglePay
                  ) {
                    nativePaymentFlowStart();
                  } else if (
                    !customSubmitOrderPaymentMethods.includes(
                      currentPaymentMethod.type
                    )
                  ) {
                    awaitExecuteOrderFinish();
                    executeOrder(executeOrderNotifyDeferred);
                  }
                }
              }}
              t={t}
            />
          </Paper>
        </div>
      )}
      {showOrderTimesContent && orderTimes.length > 1 && (
        <div className={classes.cartCardContainer}>
          <Paper className={classes.cartCard}>
            <Typography variant="body2" className={classes.text}>
              {preOrderTimeString}
            </Typography>
            <div className={classes.preOrderTimeContainer}>
              <div className={classes.preOrderTimeButtonContainer}>
                {!asapNotAvailable && (
                  <Button
                    className={`${classes.optionButton}
                  ${
                    !orderTimeSelected
                      ? classes.selectedButton
                      : classes.notSelectedButton
                  }`}
                    onClick={() => {
                      setOrderTimeSelected("");
                      setOrderTime("");
                      setShowPreOrderTimes(false);
                    }}
                  >
                    {t("timedNow")}
                  </Button>
                )}
                <Button
                  className={`${classes.optionButton}
                  ${
                    showPreOrderTimes ||
                    orderTimeSelected ||
                    (asapNotAvailable && orderTimeSelected)
                      ? classes.selectedButton
                      : classes.notSelectedButton
                  }`}
                  onClick={() => {
                    setOrderTimeSelected(orderTimes[0]);
                    setOrderTime(orderTimes[0]);
                    setShowPreOrderTimes(true);
                  }}
                >
                  {t("preOrderTime")}
                </Button>
              </div>
              {(showPreOrderTimes || orderTimeSelected) && orderTimesContent}
            </div>
            {asapNotAvailable && (
              <Typography
                variant="body2"
                className={classes.text}
                style={{
                  fontStyle: "italic",
                  color: THEME.ACCENT_RED,
                  textAlign: "center",
                }}
              >
                {t("asapNotAvailable")}
              </Typography>
            )}
          </Paper>
        </div>
      )}
      {location !== VENUE_LOCATION_DELIVERY && (
        <div className={classes.cartCardContainer} ref={emailViewRef}>
          <Paper className={classes.cartCard}>
            <InvoiceForm
              showAllErrors={showAllErrors}
              isEmailRequired={isEmailRequired}
            />
          </Paper>
        </div>
      )}
      {tipAllowed && (
        <div className={classes.cartCardContainer}>
          <Paper className={classes.tipCard}>
            <TipSelector />
          </Paper>
        </div>
      )}
      {(prepareOrderProcessing || prepareOrderError || !preparedOrder) && (
        <div className={classes.cartCardContainer}>
          <Paper className={classes.cartCard}>
            <CartCalculations
              currency={currency}
              locale={locale}
              tip={tip}
              tipSum={subTotal * tip}
              sumTotal={subTotal * (1 + tip)}
              fees={null}
              feeSum={0}
              sumBeforeEverything={subTotal}
              showMinimumBasketNotReachedError={
                preparedOrder && minimumBasketNotReached
              }
              minimumBasket={minimumBasket}
            />
          </Paper>
        </div>
      )}
      {!!currentPaymentMethod && !!calculationsContent && (
        <div className={classes.cartCardContainer}>
          <Paper className={classes.cartCard}>{calculationsContent}</Paper>
        </div>
      )}
      {!prepareOrderProcessing &&
        !prepareOrderError &&
        currentPaymentMethod && (
          <div className={classes.cartCardContainer}>
            <Paper className={classes.cartCard}>
              <PaymentMethodInformation />
            </Paper>
          </div>
        )}
      {!prepareOrderError && (
        <div className={classes.actionContainer}>
          {!hasSeenTermsNotice && !!currentPaymentMethod && (
            <Typography variant="body2" className={classes.termsNotice}>
              <Trans i18nKey="cart:termsNotice">
                By placing an order you are accepting the
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.orda-app.com/agb-nutzer"
                  className={classes.link}
                >
                  Terms & Conditions
                </a>
                .
              </Trans>
            </Typography>
          )}
          {action}
        </div>
      )}
      {currentPaymentMethod && (
        <div className={classes.securityNoticeContainer}>
          <SecurityNotice />
        </div>
      )}
      <div className={classes.footerContainer} ref={footerViewRef}>
        <Footer />
      </div>
      {openModal && (
        <GoogleDeliveryForm open={openModal} setOpen={setOpenModal} />
      )}
    </div>
  );
};

Cart.propTypes = {
  t: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  deliveryStreetName: PropTypes.string.isRequired,
  deliveryStreetNumber: PropTypes.string.isRequired,
  deliveryCity: PropTypes.string.isRequired,
  deliveryPostalCode: PropTypes.string.isRequired,
  orderTime: PropTypes.string,
  venueId: PropTypes.string.isRequired,
  location: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
  tableId: PropTypes.string,
  userEmail: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  orderTimes: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  authUser: PropTypes.object,
  authSyncFinished: PropTypes.bool.isRequired,
  groupedItems: PropTypes.array.isRequired,
  currentPaymentMethod: PropTypes.object,
  hasSeenTermsNotice: PropTypes.bool.isRequired,
  prepareOrderProcessing: PropTypes.bool.isRequired,
  allowedTOGO: PropTypes.bool.isRequired,
  allowedDINEIN: PropTypes.bool.isRequired,
  allowedDelivery: PropTypes.bool.isRequired,
  allowedPreOrderForEatIn: PropTypes.bool.isRequired,
  allowedPreOrderForDelivery: PropTypes.bool.isRequired,
  sendInvoiceTOGO: PropTypes.bool,
  sendInvoiceDINEIN: PropTypes.bool,
  prepareOrderError: PropTypes.object,
  preparedOrder: PropTypes.object,
  prepareOrder: PropTypes.func.isRequired,
  executeOrder: PropTypes.func.isRequired,
  setLocationOption: PropTypes.func.isRequired,
  setOrderTime: PropTypes.func.isRequired,
  executeOrderProcessing: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  clearOrder: PropTypes.func.isRequired,
  clearPreparedOrder: PropTypes.func.isRequired,
  subTotal: PropTypes.number.isRequired,
  tip: PropTypes.number.isRequired,
  addCreditCardProcessing: PropTypes.bool.isRequired,
  addPaymentMethodProcessing: PropTypes.bool.isRequired,
  asapNotAvailable: PropTypes.bool.isRequired,
  tipAllowed: PropTypes.bool.isRequired,
  minimumBasketNotReached: PropTypes.bool.isRequired,
  minimumBasket: PropTypes.any.isRequired,
  setGooglePayToken: PropTypes.func.isRequired,
  setApplePayToken: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  stripe: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  loginProcessing: PropTypes.bool.isRequired,
  countryCode: PropTypes.string.isRequired,
};

Cart.defaultProps = {
  currentPaymentMethod: null,
  prepareOrderError: null,
  preparedOrder: null,
  authUser: null,
  sendInvoiceTOGO: false,
  sendInvoiceDINEIN: false,
  orderTime: null,
  tableId: null,
};

export default withTranslation(["cart", "common", "addressForm"])(
  withStyles(styles)(injectStripe(Cart))
);
