import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import StartScreen from "../components/StartScreen";
import {
  posMessage as posMessageAction,
  getVenue,
} from "../redux/actions/venues";

const mapStateToProps = () => {
  return (state, ownProps) => {
    const {
      match: {
        params: { venueId },
      },
    } = ownProps;

    const {
      order,
      venues: {
        requests: {
          posMessage,
          getVenue: { [venueId]: getVenueRequest = {} },
        },
        data: { [venueId]: venue },
      },
    } = state;

    return {
      order,
      venueId,
      posMessageState: posMessage,
      venueLoaded: !!venue && !getVenueRequest.processing,
      venue,
    };
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVenue: (venueId) => dispatch(getVenue(venueId)),
    posMessage: (venueId, order) => dispatch(posMessageAction(venueId, order)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StartScreen)
);
