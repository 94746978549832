/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect, useRef } from "react";
import { THEME } from "../theme";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Close } from "mdi-material-ui";
import { Button, IconButton, TextField, Typography } from "@material-ui/core";
import { getDistance } from "geolib";
import "../styles/google-places-style.css";
import { GOOGLE_MAPS_API_URL } from "../config";
import { VENUE_LOCATION_TO_GO } from "@orda/shared-constants/order-locations";
import {
  checkCoordinatesAvailability,
  handleScriptLoad,
  loadScript,
} from "../lib/scripts";

const addressComponets = {
  street_number: "short_name",
  route: "long_name",
  locality: "long_name",
  country: "long_name",
  postal_code: "short_name",
};

// true means not valid
const addressValid = {
  street_number: false,
  route: false,
};

async function handlePlaceSelect(addressObject, updateQuery, helperObject) {
  const query = addressObject.formatted_address;
  const { geometry, address_components } = addressObject;

  if (!geometry || !address_components) {
    return;
  }

  const coordinates = {
    latitude: geometry.location.lat(),
    longitude: geometry.location.lng(),
  };
  const distance = getDistance(helperObject.venueCoordinates, coordinates);
  const newAddressValidState = {
    street_number: true,
    route: true,
  };
  let isWithinRange = true;
  helperObject.setError(false);
  helperObject.setShowConfirmButton(false);
  updateQuery(query);
  for (let i = 0; i < address_components.length; i++) {
    const [addressType] = address_components[i].types;
    if (addressComponets[addressType]) {
      const val = address_components[i][addressComponets[addressType]];
      if (val && addressType === "street_number") {
        helperObject.setDeliveryStreetNumber(val);
        newAddressValidState.street_number = false;
      } else if (val && addressType === "route") {
        helperObject.setDeliveryStreetName(val);
      } else if (val && addressType === "postal_code") {
        helperObject.setDeliveryPostalCode(val);
        newAddressValidState.route = false;
        if (
          helperObject.postalCodesWhitelist &&
          helperObject.postalCodesWhitelist.length > 0
        ) {
          isWithinRange = Boolean(
            helperObject.postalCodesWhitelist.find(
              (postalCodeConfig) => postalCodeConfig.postalCode === val
            )
          );
        }
      } else if (val && addressType === "locality") {
        helperObject.setDeliveryCity(val);
      }
    }
  }
  helperObject.setErrorAddress((prev) => ({
    ...prev,
    ...newAddressValidState,
  }));
  if (newAddressValidState.street_number) {
    // the space is added for UX (no need to hit space user can auto start writing the missing street number)
    updateQuery(`${query} `);
  }
  if (!isWithinRange || distance > helperObject.deliveryRange) {
    helperObject.resetAddress();
    helperObject.setError(true);
  } else if (
    !newAddressValidState.route &&
    !newAddressValidState.street_number
  ) {
    helperObject.setShowConfirmButton(true);
  }
}

const styles = {
  addItemDialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  iconStyle: {
    color: THEME.MAIN_GREEN,
  },
  title: {
    fontSize: 20,
    fontFamily: "Open Sans",
    color: THEME.PRIMARY_TEXT_COLOR,
    wordBreak: "break-all",
  },
  text: {
    fontSize: 14,
    fontFamily: "Open Sans",
    color: THEME.PRIMARY_TEXT_COLOR,
    padding: "10",
  },
  item: {
    padding: 10,
  },
  confirmButton: {
    height: 45,
    marginTop: 10,
    color: THEME.WHITE_TEXT_COLOR,
    backgroundColor: THEME.BUTTON_BACKGROUND_COLOR,
    "&:hover": {
      backgroundColor: THEME.BUTTON_BACKGROUND_COLOR,
    },
  },
};

const GoogleDeliveryForm = ({
  t,
  classes,
  open,
  venueCoordinates,
  postalCodesWhitelist,
  deliveryRange,
  setOpen,
  setDeliveryStreetName,
  setDeliveryCity,
  setDeliveryPostalCode,
  setDeliveryStreetNumber,
  setLocationOption,
  countryCode,
}) => {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);
  const [error, setError] = useState(false);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  let autoComplete;
  const resetAddress = () => {
    setDeliveryStreetName("");
    setDeliveryPostalCode("");
    setDeliveryCity("");
    setDeliveryStreetNumber("");
  };
  const handleClose = () => {
    if (error || !query) {
      setLocationOption(VENUE_LOCATION_TO_GO);
      resetAddress();
    }
    if (document.getElementById("google-maps-ip")) {
      document.getElementById("google-maps-ip").remove();
    }
    setOpen(false);
  };

  const [errorAddress, setErrorAddress] = useState(addressValid);
  useEffect(() => {
    loadScript(GOOGLE_MAPS_API_URL, () =>
      handleScriptLoad(
        autoComplete,
        setQuery,
        autoCompleteRef,
        {
          setDeliveryStreetName,
          setDeliveryCity,
          setDeliveryPostalCode,
          setDeliveryStreetNumber,
          venueCoordinates,
          postalCodesWhitelist,
          setErrorAddress,
          setError,
          deliveryRange,
          setShowConfirmButton,
          resetAddress,
        },
        handlePlaceSelect,
        countryCode
      )
    );
    return () =>
      !!document.getElementById("google-maps-ip") &&
      document.getElementById("google-maps-ip").remove();
  }, []);

  return (
    <div>
      <Dialog
        fullWidth
        PaperProps={{
          style: {
            margin: "4%",
          },
        }}
        open={open}
        maxWidth="md"
        aria-labelledby="add-menu-item-dialog"
      >
        <DialogTitle id="add-menu-item-dialog">
          <div className={classes.addItemDialogTitle}>
            <Typography variant="body2" className={classes.title}>
              {t("checkAddress")}
            </Typography>
            <IconButton
              className={classes.iconStyle}
              onClick={() => handleClose()}
            >
              <Close fontSize="small" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <TextField
              id="outlined-basic"
              variant="outlined"
              // label={t("checkAddress")}
              name="address"
              fullWidth
              margin="dense"
              inputRef={autoCompleteRef}
              onChange={(event) => setQuery(event.target.value)}
              onBlur={(event) => setQuery(event.target.value)}
              placeholder={t("addressPlaceholder")}
              value={query}
            />
            <IconButton
              // className={classes.iconStyle}
              style={{
                color: THEME.SECONDARY_BUTTON_ICON,
              }}
              onClick={() => {
                loadScript(GOOGLE_MAPS_API_URL, () =>
                  checkCoordinatesAvailability(
                    handlePlaceSelect,
                    setQuery,
                    {
                      setDeliveryStreetName,
                      setDeliveryCity,
                      setDeliveryPostalCode,
                      setDeliveryStreetNumber,
                      venueCoordinates,
                      postalCodesWhitelist,
                      setErrorAddress,
                      setError,
                      deliveryRange,
                      setShowConfirmButton,
                      resetAddress,
                    },
                    () => {
                      if (document.getElementById("google-maps-ip")) {
                        document.getElementById("google-maps-ip").remove();
                      }
                      setError(true);
                    }
                  )
                );
              }}
            >
              <img
                // eslint-disable-next-line global-require
                src={require("../assets/img/noun-my-location.svg")}
                alt={t("myLocation")}
                style={{
                  width: 30,
                  height: 30,
                }}
              />
            </IconButton>
          </div>
          {error && (
            <Typography
              variant="body2"
              className={classes.text}
              style={{
                color: THEME.ACCENT_RED,
                textAlign: "center",
              }}
            >
              {t("addressOutOfRange")}
            </Typography>
          )}
          {(errorAddress.route || errorAddress.street_number) && !error && (
            <Typography
              variant="body2"
              className={classes.text}
              style={{
                color: THEME.ACCENT_RED,
                textAlign: "center",
              }}
            >
              {t("errorStreetNumber")}
            </Typography>
          )}
          {showConfirmButton && (
            <>
              <Typography
                variant="body2"
                className={classes.text}
                style={{
                  color: THEME.MAIN_GREEN,
                  textAlign: "center",
                }}
              >
                {t("correctAddress")}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className={classes.confirmButton}
                onClick={() => {
                  setOpen(false);
                }}
              >
                {t("confirmAddress")}
              </Button>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

GoogleDeliveryForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  venueCoordinates: PropTypes.any.isRequired,
  postalCodesWhitelist: PropTypes.any.isRequired,
  deliveryRange: PropTypes.any.isRequired,
  setOpen: PropTypes.any.isRequired,
  setDeliveryStreetName: PropTypes.func.isRequired,
  setDeliveryCity: PropTypes.func.isRequired,
  setDeliveryPostalCode: PropTypes.func.isRequired,
  setDeliveryStreetNumber: PropTypes.func.isRequired,
  setLocationOption: PropTypes.func.isRequired,
  countryCode: PropTypes.string.isRequired,
};

export default withTranslation("googleDeliveryForm")(
  withStyles(styles)(GoogleDeliveryForm)
);
