import React from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { PaymentOption } from "@orda/shared-constants";
import { Cash100, CreditCard as CreditCardIcon } from "mdi-material-ui";
import { Button } from "@material-ui/core";
import routes from "../routes";
import { THEME } from "../theme";
import { getUsableLocale } from "../lib/i18next";
import GooglePayIcon from "./icons/GooglePayIcon";
import { addPaymentOption, addPaypalCheckout } from "../redux/actions/payments";
import ApplePayIcon from "./icons/ApplePayIcon";
import CardOnDelivery from "./icons/CardOnDelivery";
import DefaultPaymentMethod from "./icons/DefaultPaymentMethod";
import { injectStripe } from "react-stripe-elements";
import Loading from "./Loading";
import PayPalIcon from "./icons/PayPalIcon";

export function getPaymentOptionIcon(type, iconBaseStyle = {}) {
  switch (type) {
    case PaymentOption.PayPalCheckout:
    case PaymentOption.PayPalPrivateCheckout:
    case PaymentOption.PayPalBraintree:
    case PaymentOption.PayPalSingle:
      return (
        <div style={{ height: 25, ...iconBaseStyle }}>
          <PayPalIcon fill={iconBaseStyle.color || undefined} />
        </div>
      );
    case PaymentOption.CreditCard:
      return <CreditCardIcon style={iconBaseStyle} />;
    case PaymentOption.CashOnDelivery:
      return <Cash100 style={iconBaseStyle} />;
    case PaymentOption.CardOnDelivery:
      return (
        <div style={{ height: 25, ...iconBaseStyle }}>
          <CardOnDelivery fill={iconBaseStyle.color || undefined} />
        </div>
      );
    case PaymentOption.ApplePay:
      return (
        <div style={{ height: 32, ...iconBaseStyle }}>
          <ApplePayIcon fill={iconBaseStyle.color || undefined} />
        </div>
      );
    case PaymentOption.GooglePay:
      return (
        <div style={{ height: 32, ...iconBaseStyle }}>
          <GooglePayIcon fill={iconBaseStyle.color || undefined} />
        </div>
      );
    default:
      return (
        <div style={{ height: 45, ...iconBaseStyle }}>
          <DefaultPaymentMethod fill={iconBaseStyle.color || undefined} />
        </div>
      );
  }
}

export function getAction(type, routeRedirect, dispatchAction) {
  switch (type) {
    case PaymentOption.PayPalCheckout:
      return () => {
        dispatchAction(() => addPaypalCheckout());
        routeRedirect();
      };
    case PaymentOption.PayPalPrivateCheckout:
      return () => {
        dispatchAction(() => addPaypalCheckout(true));
        routeRedirect();
      };
    case PaymentOption.CreditCard:
      return () => routeRedirect();
    case PaymentOption.CashOnDelivery:
      return () => {
        dispatchAction(() => addPaymentOption(PaymentOption.CashOnDelivery));
        routeRedirect();
      };
    case PaymentOption.CardOnDelivery:
      return () => {
        dispatchAction(() => addPaymentOption(PaymentOption.CardOnDelivery));
        routeRedirect();
      };
    case PaymentOption.ApplePay:
      return () => {
        dispatchAction(() => addPaymentOption(PaymentOption.ApplePay));
        routeRedirect();
      };
    case PaymentOption.GooglePay:
      return () => {
        dispatchAction(() => addPaymentOption(PaymentOption.GooglePay));
        routeRedirect();
      };
    default:
      return null;
  }
}

export function getRoute(type, search) {
  const searchParams = new URLSearchParams(search);
  const venueId = searchParams.get("cartVenueId");
  switch (type) {
    case PaymentOption.CreditCard:
      return `${routes.payments.creditCard.path}${search}`;
    default:
      return `${routes.cart.template(venueId)}?addPaymentOptionDone=${1}`;
  }
}
function getLabel(t, paymentOption) {
  switch (paymentOption.id) {
    case PaymentOption.PayPalPrivateCheckout:
    case PaymentOption.PayPalCheckout:
      return (
        paymentOption.modes[paymentOption.orderLocation].name[
          getUsableLocale()
        ] || t("paypalLabel")
      );
    case PaymentOption.CreditCard:
      return (
        paymentOption.modes[paymentOption.orderLocation].name[
          getUsableLocale()
        ] || t("creditCardLabel")
      );
    case PaymentOption.CashOnDelivery:
      return (
        paymentOption.modes[paymentOption.orderLocation].name[
          getUsableLocale()
        ] || t("cashOnDeliveryLabel")
      );
    case PaymentOption.CardOnDelivery:
      return (
        paymentOption.modes[paymentOption.orderLocation].name[
          getUsableLocale()
        ] || t("cardOnDeliveryLabel")
      );
    case PaymentOption.ApplePay:
      return (
        paymentOption.modes[paymentOption.orderLocation].name[
          getUsableLocale()
        ] || t("applePayLabel")
      );
    case PaymentOption.GooglePay:
      return (
        paymentOption.modes[paymentOption.orderLocation].name[
          getUsableLocale()
        ] || t("googlePayLabel")
      );
    case PaymentOption.SEPA:
      return (
        paymentOption.modes[paymentOption.orderLocation].name[
          getUsableLocale()
        ] || "SEPA"
      );
    default:
      return null;
  }
}

const styles = {
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 45,
    maxWidth: 650,
    backgroundColor: THEME.SECONDARY_BUTTON_ICON,
    color: THEME.WHITE_TEXT_COLOR,
    "&:hover": {
      backgroundColor: THEME.SECONDARY_BUTTON_ICON,
      opacity: 0.8,
    },
  },
};

const PaymentOptionItem = ({
  t,
  classes,
  paymentOption,
  location: { search },
  orderLocation,
  dispatchAction,
  history,
  authUser,
  authSyncFinished,
}) => {
  const label = getLabel(t, { orderLocation, ...paymentOption });
  return !authSyncFinished || !authUser ? (
    <Loading />
  ) : (
    <Button
      fullWidth
      className={classes.button}
      variant="contained"
      color="primary"
      onClick={() => {
        const routeRedirect = () => {
          history.push(getRoute(paymentOption.id, search));
        };
        getAction(paymentOption.id, routeRedirect, dispatchAction)();
      }}
    >
      {getPaymentOptionIcon(paymentOption.id, {
        width: 60,
        color: THEME.WHITE_TEXT_COLOR,
      })}
      <div style={{ minWidth: 250, textAlign: "start" }}>{label}</div>
    </Button>
  );
};

PaymentOptionItem.propTypes = {
  classes: PropTypes.object.isRequired,
  paymentOption: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  orderLocation: PropTypes.number.isRequired,
  dispatchAction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  authUser: PropTypes.object,
  authSyncFinished: PropTypes.bool.isRequired,
};

PaymentOptionItem.defaultProps = {
  authUser: null,
};

export default withTranslation("paymentOptionItem")(
  withStyles(styles)(injectStripe(PaymentOptionItem))
);
