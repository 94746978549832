import { buildOptionsForPath, removeOrderOption } from "./options";
import {
  fillUpRequiredOptions,
  mergeOptions,
  validateRawOptions,
} from "@orda/shared-functions-js/lib/options";

import cloneDeep from "lodash/cloneDeep";
import findLastIndex from "lodash/findLastIndex";
import isEqual from "lodash/isEqual";

export const ENTRY_TYPE_ADD = 1;
export const ENTRY_TYPE_REMOVE = 2;

function validation(orderOptions, venue, itemId) {
  const {
    menu: { options },
  } = venue;
  const optionId = venue.menu.items[itemId].options;

  if (!optionId) {
    return;
  }

  if (!validateRawOptions(orderOptions, optionId, options)) {
    // eslint-disable-next-line no-console
    console.error("Warning! OrderOption validation failed");
  }
}

export function transformItemConfigurationToOrderOptions(
  venue,
  itemId,
  entries,
  minimumOptions,
  skipPath
) {
  if (!minimumOptions) {
    return null;
  }

  let usedEntries = entries;
  if (skipPath) {
    const rmIndex = findLastIndex(entries, ({ path }) =>
      isEqual(path, skipPath)
    );
    if (rmIndex !== -1) {
      usedEntries = usedEntries.slice(0, rmIndex);
    }
  }

  const result = usedEntries.reduce(
    (orderOptions, { entryType, path, option }) => {
      switch (entryType) {
        case ENTRY_TYPE_ADD: {
          const newOrderOption = buildOptionsForPath(venue, itemId, path);
          const mergedOptions = mergeOptions(orderOptions, newOrderOption);
          return fillUpRequiredOptions(mergedOptions, option) || {};
        }
        case ENTRY_TYPE_REMOVE:
          return removeOrderOption(orderOptions, path) || {};
        default:
          throw new Error("Unknown entry type");
      }
    },
    cloneDeep(minimumOptions)
  );

  // TODO: do this only in development
  validation(result, venue, itemId);

  return result;
}
