import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Price from "../containers/Price";
import ButtonBase from "@material-ui/core/ButtonBase";
import { withTranslation } from "react-i18next";
import { getItemUnvailableExplanationText } from "../lib/hours";
import { THEME } from "../theme";

const styles = {
  paper: {
    margin: "0.75rem",
    minHeight: 80,
  },
  button: {
    width: "100%",
    height: "100%",
    display: "flex",
    padding: "0.5rem",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "4px",
    minHeight: 80,
  },
  left: {
    display: "flex",
    alignItems: "center",
  },
  priceContainer: {},
  imageContainer: {
    width: 80,
    height: 80,
    display: "flex",
    position: "relative",
    overflow: "hidden",
    alignItems: "center",
    flexShrink: 0,
    userSelect: "none",
    borderRadius: 5,
    justifyContent: "center",
  },
  itemTextContainer: {
    padding: "0 1rem",
    textAlign: "left",
  },
  itemName: {
    fontSize: "0.90rem",
    fontWeight: "700",
    color: THEME.PRIMARY_TEXT_COLOR,
  },
  itemDescription: {
    fontSize: "0.8rem",
    fontWeight: "400",
    color: THEME.SECONDARY_TEXT_COLOR,
  },
  itemUnavailable: {
    fontSize: "0.9rem",
    fontWeight: "400",
    opacity: 0.5,
    color: THEME.ACCENT_RED,
  },
  image: {
    width: "100%",
    height: "100%",
    textAlign: "center",
    objectFit: "cover",
  },
};

const MenuListItem = ({
  t,
  classes,
  itemId,
  name,
  description,
  imageSrc,
  handleItemCardClick,
  itemAvailability,
  venueName,
  location,
}) => {
  const [imgLoadingFailed, setImgLoadingFailed] = useState(false);

  const handleImageError = () => {
    setImgLoadingFailed(true);
  };
  const explanation = useMemo(
    () =>
      (itemAvailability.reason && itemAvailability.nextAvailable) ||
      !itemAvailability.available
        ? getItemUnvailableExplanationText(t, itemAvailability, venueName, name)
        : "",
    [location]
  );
  return (
    <Paper className={classes.paper}>
      <ButtonBase
        focusRipple
        className={classes.button}
        onClick={handleItemCardClick}
        disabled={!itemAvailability.available}
        style={{ opacity: itemAvailability.available ? 1 : 0.7 }}
      >
        <div className={classes.itemTextContainer}>
          <Typography className={classes.itemName} variant="body1">
            {name}
          </Typography>
          {Boolean(description) && description.length > 0 && !explanation && (
            <Typography className={classes.itemDescription} variant="body1">
              {description}
            </Typography>
          )}
          {!!explanation && (
            <Typography className={classes.itemUnavailable} variant="body1">
              {explanation}
            </Typography>
          )}
          <div className={classes.priceContainer}>
            <Price itemId={itemId} />
          </div>
        </div>
        {!!imageSrc && !imgLoadingFailed && (
          <div className={classes.imageContainer}>
            <img
              className={classes.image}
              alt={name}
              src={imageSrc}
              onError={handleImageError}
            />
          </div>
        )}
      </ButtonBase>
    </Paper>
  );
};

MenuListItem.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  itemId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  venueName: PropTypes.string.isRequired,
  description: PropTypes.string,
  itemAvailability: PropTypes.object.isRequired,
  imageSrc: PropTypes.string,
  handleItemCardClick: PropTypes.func.isRequired,
  location: PropTypes.number.isRequired,
};

MenuListItem.defaultProps = {
  imageSrc: "",
  description: "",
};

export default withTranslation("menuItemDetail")(
  withStyles(styles)(MenuListItem)
);
