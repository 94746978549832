import React from "react";
import PropTypes from "prop-types";
import { withStyles, IconButton } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { Pencil } from "mdi-material-ui";
import { Link } from "react-router-dom";
import routes from "../../routes";
import PaymentProviderIcon from "../PaymentProviderIcon";
import { getPaymentOptionIcon } from "../PaymentOptionItem";
import { getUsableLocale } from "../../lib/i18next";
import { PaymentOption } from "@orda/shared-constants";
import { THEME } from "../../theme";

const styles = {
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  paymentMethodContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  cardInfoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardNumber: {
    marginLeft: 5,
  },
};

const PaymentMethodInformation = ({
  t,
  classes,
  venueId,
  currentPaymentMethod,
  paymentOptions,
  location,
}) => {
  let paymentMethodInfo;
  if (currentPaymentMethod.type === PaymentOption.CreditCard) {
    paymentMethodInfo = (
      <div className={classes.cardInfoWrapper}>
        <PaymentProviderIcon type={currentPaymentMethod.brand} />
        <Typography variant="body2" className={classes.cardNumber}>
          {currentPaymentMethod.last4}
        </Typography>
      </div>
    );
  } else {
    paymentMethodInfo = (
      <div className={classes.cardInfoWrapper}>
        {getPaymentOptionIcon(currentPaymentMethod.type, {
          width: 60,
          color: THEME.DARKER_MAIN_GREEN,
        })}
        <Typography variant="body2">
          {
            paymentOptions.find(
              (paymentOption) => paymentOption.id === currentPaymentMethod.type
            ).modes[location].name[getUsableLocale()]
          }
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Typography variant="body2">{t("paymentMethodLabel")}</Typography>
      <div className={classes.paymentMethodContainer}>
        {paymentMethodInfo}
        <IconButton component={Link} to={routes.payments.add.template(venueId)}>
          <Pencil />
        </IconButton>
      </div>
    </div>
  );
};

PaymentMethodInformation.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  venueId: PropTypes.string.isRequired,
  currentPaymentMethod: PropTypes.object.isRequired,
  paymentOptions: PropTypes.array.isRequired,
  location: PropTypes.number.isRequired,
};

export default withTranslation("cart")(
  withStyles(styles)(PaymentMethodInformation)
);
