import React, { useState } from "react";
import { THEME } from "../theme";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  VENUE_LOCATION_DELIVERY,
  VENUE_LOCATION_EAT_IN,
  VENUE_LOCATION_TO_GO,
} from "@orda/shared-constants/order-locations";
import Button from "@material-ui/core/Button";
import GoogleDeliveryForm from "../containers/GoogleDeliveryForm";
import classNames from "classnames";

const styles = {
  selectedButton: {
    color: THEME.PRIMARY_TEXT_COLOR,
    backgroundColor: THEME.MAIN_GREEN,
    "&:hover": {
      backgroundColor: THEME.DARKER_MAIN_GREEN,
    },
  },
  notSelectedButton: {
    color: THEME.WHITE_TEXT_COLOR,
    backgroundColor: "#8282826e",
    "&:hover": {
      backgroundColor: THEME.LIGHTER_MAIN_GREEN,
      color: THEME.PRIMARY_TEXT_COLOR,
    },
  },
  locationOptionContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    justifyContent: "space-evenly",
  },
  orderLocationButton: {
    padding: "5px",
    width: "110px",
    minHeight: "50px",
    minWidth: "60px",
    maxWidth: "150px",
    flexGrow: 1,
  },
};

const OrderLocationSwitcherBar = ({
  t,
  classes,
  orderLocations,
  location,
  setLocationOption,
  addressEmpty,
}) => {
  const [openModal, setOpenModal] = useState(
    location === VENUE_LOCATION_DELIVERY && addressEmpty
  );

  const isSelected = (orderLocation) => {
    return location === orderLocation
      ? classes.selectedButton
      : classes.notSelectedButton;
  };
  const { isAllowedDelivery, isAllowedTOGO, isAllowedDINEIN } = orderLocations;

  return (
    <div className={classes.locationOptionContainer}>
      {isAllowedTOGO && (
        <Button
          onClick={() => {
            setLocationOption(VENUE_LOCATION_TO_GO);
          }}
          className={classNames(
            classes.orderLocationButton,
            isSelected(VENUE_LOCATION_TO_GO)
          )}
        >
          {t("toGo")}
        </Button>
      )}
      {isAllowedDINEIN && (
        <Button
          onClick={() => {
            setLocationOption(VENUE_LOCATION_EAT_IN);
          }}
          className={classNames(
            classes.orderLocationButton,
            isSelected(VENUE_LOCATION_EAT_IN)
          )}
        >
          {t("dineIn")}
        </Button>
      )}
      {isAllowedDelivery && (
        <Button
          onClick={() => {
            setLocationOption(VENUE_LOCATION_DELIVERY);
            setOpenModal(true);
          }}
          className={classNames(
            classes.orderLocationButton,
            isSelected(VENUE_LOCATION_DELIVERY)
          )}
        >
          {t("delivery")}
        </Button>
      )}
      {openModal && (
        <GoogleDeliveryForm open={openModal} setOpen={setOpenModal} />
      )}
    </div>
  );
};

OrderLocationSwitcherBar.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  orderLocations: PropTypes.object.isRequired,
  location: PropTypes.number.isRequired,
  setLocationOption: PropTypes.func.isRequired,
  addressEmpty: PropTypes.bool.isRequired,
};

export default withTranslation("venueInfoModal")(
  withStyles(styles)(OrderLocationSwitcherBar)
);
