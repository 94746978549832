/* eslint no-param-reassign: 0 */
import * as types from "../types";
import produce from "immer";

export const initialState = {
  lastSectionIndex: 0,
};

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case types.ui.UPDATE_LAST_SECTION_INDEX:
      return produce(state, (newState) => {
        newState.lastSectionIndex = action.sectionIndex;
      });
    default:
      return state;
  }
}
