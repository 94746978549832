import { getUsableLocale } from "./i18next";

const TIME_RANGE_THRESHOLD = 5;

export function calculatePrepTimeRange(avgPrepTime = 12, traffic) {
  return avgPrepTime + traffic / 10 + TIME_RANGE_THRESHOLD;
}
export const getVenueName = (venue) => {
  const strings = venue.strings[getUsableLocale()];
  const fallbackStrings = venue.strings[venue.defaultLocale];
  const venueName = (strings && strings[venue.id]) || fallbackStrings[venue.id];
  return venueName;
};
