/* eslint no-param-reassign: 0 */
import * as types from "../types";
import produce from "immer";

export const initialState = {
  requests: {
    login: {
      processing: false,
      error: null,
      success: false,
    },
    logout: {
      processing: false,
      error: null,
    },
  },
  user: null,
  syncFinished: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.auth.LOGIN.REQUEST:
      return produce(state, (newState) => {
        newState.requests.login.processing = true;
        newState.requests.login.error = null;
        newState.requests.login.success = false;
      });
    case types.auth.LOGIN.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.login.processing = false;
        newState.requests.login.success = true;
      });
    case types.auth.LOGIN.FAILURE:
      return produce(state, (newState) => {
        newState.requests.login.processing = false;
        newState.requests.login.error = action.error;
        newState.requests.login.success = false;
      });
    case types.auth.LOGOUT.REQUEST:
      return produce(state, (newState) => {
        newState.requests.logout.processing = true;
        newState.requests.logout.error = null;
      });
    case types.auth.LOGOUT.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.logout.processing = false;
      });
    case types.auth.LOGOUT.FAILURE:
      return produce(state, (newState) => {
        newState.requests.logout.processing = false;
        newState.requests.logout.error = action.error;
      });
    case types.auth.SYNC_FIREBASE_USER:
      return produce(state, (newState) => {
        newState.syncFinished = true;
        newState.user = action.user;
      });
    default:
      return state;
  }
}
