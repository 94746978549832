import firebase from "../../lib/firebase";
import { eventChannel } from "redux-saga";
import { all, put, take, call, fork, takeEvery } from "redux-saga/effects";
import {
  loginSuccess,
  loginFailure,
  syncFirebaseUser,
  logoutFailure,
  logoutSuccess,
} from "../actions/auth";
import { REHYDRATE } from "redux-persist/lib/constants";
import * as types from "../types";
import { createUserSaga } from "./user";
import { injectError } from "../actions/errors";

function authChannel() {
  return eventChannel((emit) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(
      (user) => emit({ user }),
      (error) => emit({ error })
    );

    return unsubscribe;
  });
}

function* syncUserSaga() {
  yield take(REHYDRATE);
  const channel = yield call(authChannel);

  while (true) {
    const { user } = yield take(channel);
    if (user) {
      yield put(syncFirebaseUser(user));
    } else {
      yield put(syncFirebaseUser(null));
    }
  }
}

export function* logoutSaga() {
  try {
    const auth = firebase.auth();
    yield call([auth, auth.signOut]);
    yield put(logoutSuccess());
  } catch (error) {
    yield put(logoutFailure(error));
  }
}

export function* loginSaga() {
  try {
    const auth = firebase.auth();
    yield call([auth, auth.signInAnonymously]);

    // wait for sync to finish
    yield take(types.auth.SYNC_FIREBASE_USER);

    // create user on the backend
    try {
      yield* createUserSaga();
    } catch (error) {
      // this will be used to show an error page
      yield put(injectError(error));
      // logout from firebase
      yield* logoutSaga();
      return false;
    }

    yield put(loginSuccess());
    return true;
  } catch (error) {
    yield put(loginFailure(error));
    yield put(injectError(error));
    return false;
  }
}

export default function* authRootSaga() {
  yield fork(syncUserSaga);
  yield all([
    takeEvery(types.auth.LOGIN.REQUEST, loginSaga),
    takeEvery(types.auth.LOGOUT.REQUEST, logoutSaga),
  ]);
}
