import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CallWaiterModal from "../components/CallWaiterModal";
import { posMessage as posMessageAction } from "../redux/actions/venues";

const mapStateToProps = () => {
  return (state, ownProps) => {
    const {
      match: {
        params: { venueId },
      },
    } = ownProps;

    const {
      order,
      venues: {
        requests: { posMessage },
      },
    } = state;

    return {
      order,
      venueId,
      posMessageState: posMessage,
    };
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    posMessage: (venueId, order) => dispatch(posMessageAction(venueId, order)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CallWaiterModal)
);
