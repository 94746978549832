import React from "react";
import { THEME } from "../../theme";
import PropTypes from "prop-types";

const MenuIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/1999/xlink"
    version="1.1"
    width="100%"
    height="100%"
    x="0"
    y="0"
    viewBox="0 0 513.029 513.029"
    space="preserve"
  >
    <g fill={fill}>
      <path
        d="M148.684 65.029h220.331V20.015C369.015 8.611 359.677 0 349.046 0c-2.07 0-4.187.326-6.296 1.022zM409.015 95.029h-305c-11.046 0-20 8.954-20 20v378c0 11.046 8.954 20 20 20h305c11.046 0 20-8.954 20-20v-378c0-11.045-8.955-20-20-20zm-252.5 180.324h4.345c6.466-41.398 39.257-74.189 80.655-80.655v-7.516c0-8.284 6.716-15 15-15s15 6.716 15 15v7.516c41.398 6.466 74.189 39.257 80.655 80.655h4.345c8.284 0 15 6.716 15 15s-6.716 15-15 15h-200c-8.284 0-15-6.716-15-15s6.715-15 15-15zm92.817 85.524c0 8.284-6.716 15-15 15h-72c-8.284 0-15-6.716-15-15s6.716-15 15-15h72c8.284 0 15 6.716 15 15zm103.433 75h-192.5c-8.284 0-15-6.716-15-15s6.716-15 15-15h192.5c8.284 0 15 6.716 15 15s-6.716 15-15 15z"
        opacity="1"
        data-original="#000000"
      ></path>
      <path
        d="M321.636 275.353c-6.827-29.645-33.434-51.818-65.121-51.818s-58.294 22.174-65.121 51.818z"
        opacity="1"
        data-original="#000000"
      ></path>
    </g>
  </svg>
);

MenuIcon.propTypes = {
  fill: PropTypes.string,
};
MenuIcon.defaultProps = {
  fill: THEME.WHITE_TEXT_COLOR,
};

export default MenuIcon;
