import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";

const styles = {
  root: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  footerSpacer: {
    margin: "0 2px",
  },
  footerText: {
    fontSize: "0.7em",
    fontFamily: "Open Sans",
  },
  link: {
    color: "#1565c0",
  },
};

const DataProtection = ({ t, classes }) => (
  <div className={classes.root}>
    <Typography variant="body2" component="span" className={classes.footerText}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.orda-app.com/agb-nutzer"
        className={classes.link}
      >
        {t("agbs")}
      </a>
    </Typography>
    <span className={classes.footerSpacer}>⋅</span>
    <Typography variant="body2" component="span" className={classes.footerText}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.orda-app.com/datenschutz"
        className={classes.link}
      >
        {t("privacy")}
      </a>
    </Typography>
  </div>
);

DataProtection.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withTranslation("dataProtection")(
  withStyles(styles)(DataProtection)
);
