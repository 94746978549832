import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { THEME } from "../theme";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Close } from "mdi-material-ui";
import {
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { CALL_WAITER_REASON_TYPE } from "../constants";
import classNames from "classnames";

const styles = {
  callWaiterDialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "20px",
    padding: 10,
  },
  callWaiterDialogClose: {
    color: THEME.MAIN_GREEN,
  },
  title: {
    fontSize: 20,
    fontFamily: "Open Sans",
    color: THEME.PRIMARY_TEXT_COLOR,
    wordBreak: "break-all",
  },
  text: {
    fontSize: 14,
    fontFamily: "Open Sans",
    color: THEME.PRIMARY_TEXT_COLOR,
    padding: "10",
  },
  button: {
    width: "200px",
    color: THEME.WHITE_TEXT_COLOR,
    backgroundColor: THEME.SECONDARY_BUTTON_ICON,
    "&:hover": {
      backgroundColor: THEME.SECONDARY_BUTTON_ICON,
      opacity: 0.8,
    },
  },
  reasonButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    gap: "10px",
  },
  reasonButton: {
    padding: "5px",
    width: "110px",
    minHeight: "50px",
    minWidth: "60px",
    maxWidth: "150px",
  },
  selectedButton: {
    color: THEME.PRIMARY_TEXT_COLOR,
    backgroundColor: THEME.MAIN_GREEN,
    "&:hover": {
      backgroundColor: THEME.DARKER_MAIN_GREEN,
    },
  },
  notSelectedButton: {
    color: THEME.WHITE_TEXT_COLOR,
    backgroundColor: "#828282",
    "&:hover": {
      backgroundColor: THEME.LIGHTER_MAIN_GREEN,
      color: THEME.PRIMARY_TEXT_COLOR,
    },
  },
};

const CallWaiterModal = ({
  t,
  classes,
  open,
  setOpen,
  venueId,
  posMessageState,
  posMessage,
  order,
}) => {
  const [callWaiterPayload, setCallWaiterPayload] = useState({
    reason: CALL_WAITER_REASON_TYPE.Order,
    customerInfo: "",
  });
  const handleClose = () => setOpen(false);
  const isSelected = (reason) => {
    return callWaiterPayload.reason === reason
      ? classes.selectedButton
      : classes.notSelectedButton;
  };
  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        maxWidth="md"
        aria-labelledby="call-waiter-dialog"
      >
        <DialogTitle id="call-waiter-dialog">
          <div className={classes.callWaiterDialogTitle}>
            <Typography variant="body2" className={classes.title}>
              {t("callWaiterTitle")}
            </Typography>
            <IconButton
              className={classes.callWaiterDialogClose}
              onClick={() => handleClose()}
            >
              <Close fontSize="small" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <div className={classes.reasonButtonContainer}>
            {Object.values(CALL_WAITER_REASON_TYPE).map((reason) => (
              <Button
                key={reason}
                onClick={() =>
                  setCallWaiterPayload({
                    ...callWaiterPayload,
                    reason,
                  })
                }
                className={classNames(classes.reasonButton, isSelected(reason))}
              >
                {t(reason)}
              </Button>
            ))}
          </div>
          <TextField
            id="outlined-basic"
            variant="outlined"
            name="customerInfo"
            fullWidth
            onChange={(event) =>
              setCallWaiterPayload({
                ...callWaiterPayload,
                customerInfo: event.target.value,
              })
            }
            placeholder={t("customerInfoPlaceholder")}
            value={callWaiterPayload.customerInfo}
          />
          <Button
            onClick={() => {
              posMessage(venueId, { order, ...callWaiterPayload });
              handleClose();
            }}
            className={classes.button}
          >
            {posMessageState && posMessageState.processing ? (
              <CircularProgress size={20} />
            ) : (
              t("callWaiterButtonLabel")
            )}
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

CallWaiterModal.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.any.isRequired,
  posMessage: PropTypes.func.isRequired,
  order: PropTypes.any.isRequired,
  venueId: PropTypes.string.isRequired,
  posMessageState: PropTypes.any.isRequired,
};

export default withTranslation("callWaiterModal")(
  withStyles(styles)(CallWaiterModal)
);
