import React from "react";
import PropTypes from "prop-types";
import { Router, Route, Switch } from "react-router-dom";
import history from "../history";
import { withStyles } from "@material-ui/core";
import routes from "../routes";
import ErrorBoundary from "./ErrorBoundary";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
};

const AppRoot = ({ classes }) => (
  <div className={classes.root}>
    <Router history={history}>
      <ErrorBoundary>
        <Switch>
          {/* ⚠️ If the route needs the venue state make sure to include it in the venue component and not here or call getVenue in said component, otherwise on refreshing the venue data is gone */}
          <Route
            exact={routes.homeRedirect.exact}
            component={routes.homeRedirect.component}
            path={routes.homeRedirect.path}
          />
          <Route
            exact={routes.start.exact}
            path={routes.start.path}
            component={routes.start.component}
          />
          <Route
            exact={routes.venue.exact}
            component={routes.venue.component}
            path={routes.venue.path}
          />
          <Route
            exact={routes.payments.add.exact}
            component={routes.payments.add.component}
            path={routes.payments.add.path}
          />
          <Route
            exact={routes.payments.creditCard.exact}
            component={routes.payments.creditCard.component}
            path={routes.payments.creditCard.path}
          />
          <Route
            exact={routes.orders.success.exact}
            component={routes.orders.success.component}
            path={routes.orders.success.path}
          />
          <Route
            exact={routes.errors.operation.exact}
            component={routes.errors.operation.component}
            path={routes.errors.operation.path}
          />
          <Route
            exact={routes.venueAlias.exact}
            path={routes.venueAlias.path}
            component={routes.venueAlias.component}
          />
          <Route component={routes.errors.notFound.component} />
        </Switch>
      </ErrorBoundary>
    </Router>
  </div>
);

AppRoot.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppRoot);
