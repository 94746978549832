import { connect } from "react-redux";
import LastOrderbar from "../components/LastOrderBar";

const mapStateToProps = (state) => {
  const {
    user: { orders },
  } = state;

  return {
    orders,
  };
};

export default connect(mapStateToProps)(LastOrderbar);
