import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { THEME } from "../theme";

const styles = {
  venueNameBar: {},
  white: {
    color: THEME.WHITE_TEXT_COLOR,
    fontWeight: "bold",
    fontSize: "clamp(0.7rem, 3vmin, 1rem)",
    textAlign: "center",
  },
  sticky: {
    position: "sticky",
  },
};

const Header = ({ classes, title, sticky }) => (
  <div
    className={classNames(classes.venueNameBar, {
      [classes.sicky]: sticky,
    })}
  >
    <Typography variant="body2" className={classes.white}>
      {title}
    </Typography>
  </div>
);

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  sticky: PropTypes.bool,
};

Header.defaultProps = {
  sticky: false,
};

export default withStyles(styles)(Header);
