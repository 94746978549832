import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { CheckCircle as CheckIcon } from "mdi-material-ui";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
  VENUE_LOCATION_EAT_IN,
  VENUE_LOCATION_TO_GO,
  VENUE_LOCATION_DELIVERY,
  VENUE_MODE_OTC,
  VENUE_MODE_TABLE_SERVICE_QR,
} from "@orda/shared-constants/order-locations";
import routes from "../routes";
import NativeUpsellBanner from "./NativeUpsellBanner";
import { THEME } from "../theme";

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    height: "100vh",
    width: "100%",
  },
  logoContainer: {
    width: "100%",
    textAlign: "center",
    flexGrow: 0,
    padding: "30px 0",
  },
  iconContainer: {
    flexGrow: 1,
    color: "white",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  orderNumberContainer: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  messageContainer: {},
  orderLocationContainer: {
    margin: "30px 0px",
  },
  venueNameContainer: {
    textAlign: "center",
    flexGrow: 0.5,
  },
  venueName: {
    fontSize: "1.5rem",
  },
  message: {
    color: "black",
    padding: "0 30px",
    margin: "10px 0",
    textAlign: "center",
    whiteSpace: "pre-line",
  },
  messageUnderlined: {
    textDecoration: "underline",
    marginBottom: "20px",
  },
  actionContainer: {
    flexGrow: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 10,
  },
  button: {
    width: "90%",
    color: THEME.WHITE_TEXT_COLOR,
    backgroundColor: THEME.SECONDARY_BUTTON_ICON,
    "&:hover": {
      backgroundColor: THEME.SECONDARY_BUTTON_ICON,
      opacity: 0.8,
    },
  },
  inverse: {
    color: "black",
  },
  orderNumber: {
    color: "black",
    fontSize: "3rem",
    fontWeight: 600,
    padding: "10px 0",
  },
};

function formatSuccessText(t, order, classes, pickUpTime, avgDeliveryTime) {
  if (!order) {
    return "";
  }

  let modeExplanation;
  let deliveryAdditionalInformation = "";
  let deliveryAddress = "";
  if (order.deliveryAddress) {
    deliveryAddress = order.deliveryAddress.split(", ");
    if (deliveryAddress[4]) {
      deliveryAdditionalInformation = deliveryAddress.pop();
    }
    deliveryAddress = deliveryAddress.join(", ");
  }
  switch (order.usedMode) {
    case VENUE_MODE_OTC: {
      if (order.orderLocation === VENUE_LOCATION_EAT_IN) {
        modeExplanation = (
          <Typography className={classes.message} variant="body2">
            {t("explanationPushNotificationEatIn")}
          </Typography>
        );
      } else if (
        order.orderLocation === VENUE_LOCATION_TO_GO &&
        order.orderTime
      ) {
        modeExplanation = (
          <Typography className={classes.message} variant="body2">
            {t("explanationPushNotificationToGoTimed", {
              venueName: order.venueName && order.venueName.user,
              time: order.orderTime,
            })}
          </Typography>
        );
      } else if (order.orderLocation === VENUE_LOCATION_TO_GO) {
        modeExplanation = (
          <Typography className={classes.message} variant="body2">
            {t("explanationPushNotificationToGoNow", {
              venueName: order.venueName && order.venueName.user,
              time: pickUpTime,
            })}
          </Typography>
        );
      } else if (order.orderLocation === VENUE_LOCATION_DELIVERY) {
        modeExplanation = (
          <div className={classes.orderLocationContainer}>
            <Typography className={classes.message} variant="body2">
              {t("fullNameLabel", {
                fullName: order.userName,
              })}
            </Typography>
            <Typography className={classes.message} variant="body2">
              {t("deliveryAddressLabel", {
                deliveryAddress,
              })}
            </Typography>
            <Typography className={classes.message} variant="body2">
              {t("phoneNumberLabel", {
                phoneNumber: order.phoneNumber,
              })}
            </Typography>
            {deliveryAdditionalInformation && (
              <Typography className={classes.message} variant="body2">
                {t("deliveryAdditionalInformationLabel", {
                  deliveryAdditionalInformation,
                })}
              </Typography>
            )}
            <Typography className={classes.message} variant="body2">
              {order.orderTime
                ? t("explanationPushNotificationDeliveryTimed", {
                    venueName: order.venueName && order.venueName.user,
                    time: order.orderTime,
                  })
                : t("avgDeliveryTimeLabel", {
                    venueName: order.venueName && order.venueName.user,
                    avgTime: avgDeliveryTime,
                  })}
            </Typography>
          </div>
        );
      }
      break;
    }
    case VENUE_MODE_TABLE_SERVICE_QR:
      modeExplanation = (
        <div>
          <Typography className={classes.message} variant="body2">
            {t("explanationTable")}
          </Typography>
          <Typography className={classes.message} variant="body2">
            <span style={{ fontWeight: "bold" }}>{t("warning")}</span>{" "}
            {t("tableNewOrderWarning")}
          </Typography>
        </div>
      );
      break;
    default:
      break;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "800",
        }}
      >
        <CheckIcon
          style={{
            fill: "#ADD450",
            marginRight: "6px",
          }}
        />
        {t("messageOrderConfirmed")}
      </div>
      <br />
      <div>{modeExplanation}</div>
    </>
  );
}

const OrderSuccess = ({ t, classes, order, pickUpTime, avgDeliveryTime }) => {
  return (
    <div className={classes.root}>
      <NativeUpsellBanner />
      <div className={classes.logoContainer}>
        <span
          style={{ fontSize: "1.2rem", color: "black" }}
          className="orda-icon-ORDA"
        />
      </div>
      <div className={classes.orderNumberContainer}>
        <Typography component="span" className={classes.inverse}>
          {t("orderNumberLabel")}
        </Typography>
        <Typography component="span" className={classes.orderNumber}>
          {order.orderNumber}
        </Typography>
      </div>

      {/* <div className={classes.venueNameContainer}>
        <Typography component="span" className={classes.venueName}>
          {order.venueName && order.venueName.venue}
        </Typography>
      </div> */}
      <div className={classes.messageContainer}>
        {formatSuccessText(t, order, classes, pickUpTime, avgDeliveryTime)}
      </div>
      {order.usedMode !== VENUE_MODE_TABLE_SERVICE_QR && (
        <div className={classes.actionContainer}>
          <Button
            className={classes.button}
            variant="outlined"
            color="secondary"
            size="large"
            component={Link}
            to={routes.menu.template(order.venueId)}
          >
            {t("backButtonLabel")}
          </Button>
        </div>
      )}
    </div>
  );
};

OrderSuccess.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  pickUpTime: PropTypes.object.isRequired,
  avgDeliveryTime: PropTypes.number.isRequired,
};

export default withTranslation("orderSuccess")(
  withStyles(styles)(OrderSuccess)
);
