import { select, takeEvery, call, put } from "redux-saga/effects";
import * as types from "../types";
import { createUser as createUserTransport } from "../../api/user";
import { createUserSuccess, createUserFailure } from "../actions/user";

export function* createUserSaga() {
  try {
    const user = yield select((state) => state.auth.user);
    yield call(createUserTransport, user.uid);
    yield put(createUserSuccess());
  } catch (error) {
    yield put(createUserFailure(error));
    throw error;
  }
}

export default function* userRootSaga() {
  yield takeEvery(types.user.CREATE_USER.REQUEST, createUserSaga);
}
