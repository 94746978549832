import { PaymentOption } from "@orda/shared-constants";
import {
  VENUE_LOCATION_DELIVERY,
  VENUE_LOCATION_EAT_IN,
  VENUE_LOCATION_TO_GO,
} from "@orda/shared-constants/order-locations";

export const SUPPORTED_LOCALES = ["de", "en"];
export const MAX_PREORDERHOURS = 6;
export const MIN_DELIVERY_BASKET = 500;
export const MAX_DELIVERY_RANGE = 1000;
export const paymentOptionsDefault = [
  {
    id: PaymentOption.CreditCard,
    enabled: true,
    type: "online",
    modes: {
      [VENUE_LOCATION_EAT_IN]: {
        enabled: true,
        name: {
          de: "Kreditkarte",
          en: "Credit card",
        },
        info: {
          de: "Kreditkarte",
          en: "Credit card",
        },
      },
      [VENUE_LOCATION_TO_GO]: {
        enabled: true,
        name: {
          de: "Kreditkarte",
          en: "Credit card",
        },
      },
      [VENUE_LOCATION_DELIVERY]: {
        enabled: true,
        name: {
          de: "Kreditkarte",
          en: "Credit card",
        },
      },
    },
  },
  {
    id: PaymentOption.GooglePay,
    enabled: true,
    modes: {
      [VENUE_LOCATION_EAT_IN]: {
        enabled: true,
        name: {
          de: "Google Pay",
          en: "Google Pay",
        },
      },
      [VENUE_LOCATION_TO_GO]: {
        enabled: true,
        name: {
          de: "Google Pay",
          en: "Google Pay",
        },
      },
      [VENUE_LOCATION_DELIVERY]: {
        enabled: true,
        name: {
          de: "Google Pay",
          en: "Google Pay",
        },
      },
    },
    type: "online",
  },
  {
    id: PaymentOption.ApplePay,
    enabled: true,
    modes: {
      [VENUE_LOCATION_EAT_IN]: {
        enabled: true,
        info: {
          de: "Apple Pay",
          en: "Apple Pay",
        },
        name: {
          de: "Apple Pay",
          en: "Apple Pay",
        },
      },
      [VENUE_LOCATION_TO_GO]: {
        enabled: true,
        name: {
          de: "Apple Pay",
          en: "Apple Pay",
        },
      },
      [VENUE_LOCATION_DELIVERY]: {
        enabled: true,
        name: {
          de: "Apple Pay",
          en: "Apple Pay",
        },
      },
    },
    type: "online",
  },
];

export const CLIENT_ID_KEY = "clientId";
// check-in table, room...
export const SOCKET_EVENT_TARGET_TYPE = {
  TABLE: "Table",
};
export const CALL_WAITER_REASON_TYPE = {
  Order: "Order",
  Pay: "Pay",
  Other: "Other",
};

export const PosMessageType = {
  CallWaiter: "CallWaiter",
  InfoRequest: "InfoRequest",
  PaymentRequest: "PaymentRequest",
};

export const AdyenPaymentResultCode = {
  Authorised: "Authorised",
  Cancelled: "Cancelled",
  Error: "Error",
  Pending: "Pending",
  Refused: "Refused",
};

export const PosPaymentType = {
  OnlinePayment: 14,
};
