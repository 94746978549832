import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InHousePayment from "../../components/InHousePayment/InHousePayment";
import {
  posMessage as posMessageAction,
  posMessageReset,
} from "../../redux/actions/venues";

const mapStateToProps = () => {
  return (state, ownProps) => {
    const {
      match: {
        params: { venueId },
      },
    } = ownProps;

    const {
      order,
      venues: {
        requests: { posMessage },
      },
    } = state;

    return {
      order,
      venueId,
      posMessageState: posMessage,
    };
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    posMessage: (venueId, order) => dispatch(posMessageAction(venueId, order)),
    posMessageReset: () => dispatch(posMessageReset()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InHousePayment)
);
