import { select, takeEvery, call, put, all } from "redux-saga/effects";
import * as types from "../types";
import {
  addCreditCard as addCreditCardTransport,
  addPaymentOption as addPaymentOptionTransport,
  createAdyenPaymentSessionTransport,
} from "../../api/payments";

import {
  addCreditCardSuccess,
  addCreditCardFailure,
  addPaymentOptionSuccess,
  addPaymentOptionFailure,
  storeAdyenPaymentData,
  createAdyenPaymentSessionFailure,
} from "../actions/payments";
import { clearPreparedOrder, setTip } from "../actions/order";

function* addCreditCardSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield user.getIdToken();
    const payment = yield call(
      addCreditCardTransport,
      user.uid,
      action.token,
      authToken
    );
    // In the case of one payment method having tipping allowed the other not
    yield put(setTip(0));
    // this will trigger prepare order which will update the payment option inside the order ramp
    yield put(clearPreparedOrder());
    yield put(addCreditCardSuccess(payment));
  } catch (error) {
    yield put(addCreditCardFailure(error));
  }
}

function* addPaymentOptionSaga(action) {
  try {
    const user = yield select((state) => state.auth.user);
    const authToken = yield user.getIdToken();

    const payment = yield call(
      addPaymentOptionTransport,
      user.uid,
      action.paymentOption,
      authToken
    );
    // In the case of one payment method having tipping allowed the other not
    yield put(setTip(0));
    // this will trigger prepare order which will update the payment option inside the order ramp
    yield put(clearPreparedOrder());
    yield put(addPaymentOptionSuccess(payment));
  } catch (error) {
    yield put(addPaymentOptionFailure(error));
  }
}

function* createAdyenPaymentSessionSaga(action) {
  try {
    const { venueId, payload } = action;
    const response = yield call(
      createAdyenPaymentSessionTransport,
      venueId,
      payload
    );
    yield put(storeAdyenPaymentData(response));
  } catch (error) {
    yield put(createAdyenPaymentSessionFailure(error));
  }
}

export default function* paymentsRootSaga() {
  yield all([
    yield takeEvery(types.payments.ADD_CREDIT_CARD.REQUEST, addCreditCardSaga),
    yield takeEvery(
      types.payments.ADD_PAYMENT_OPTION.REQUEST,
      addPaymentOptionSaga
    ),
    yield takeEvery(
      types.payments.ADYEN.CREATE_PAYMENT_SESSION.REQUEST,
      createAdyenPaymentSessionSaga
    ),
  ]);
}
