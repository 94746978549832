import React from "react";
import { THEME } from "../theme";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { TextField, Typography } from "@material-ui/core";

const styles = {
  root: {},
  openingHourRow: {
    display: "flex",
    alignItems: "center",
    borderBottom: " 1px solid #d3d3d3",
    padding: "0.5rem 0",
  },
  text: {
    fontSize: 14,
    fontFamily: "Open Sans",
    color: THEME.PRIMARY_TEXT_COLOR,
    padding: 10,
    fontWeight: "bold",
    minWidth: 85,
  },
  openingHoursValue: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

const OpeningHours = ({ t, classes, openingHours }) => {
  return (
    <div className={classes.root}>
      {[
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
        "Sonntag",
      ].map((dayName, idx) => {
        const day = idx + 1;
        return (
          <div className={classes.openingHourRow}>
            <Typography
              variant="body2"
              className={classes.text}
              style={{ fontWeight: "bold" }}
            >
              {`${dayName}: `}
            </Typography>
            <div className={classes.openingHoursValue}>
              {openingHours[day] && openingHours[day].length >= 1 ? (
                openingHours[day].map((slot) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      value={
                        slot.startHour + 1 && slot.startMinute + 1
                          ? `${slot.startHour < 10 ? "0" : ""}${
                              slot.startHour
                            }:${slot.startMinute < 10 ? "0" : ""}${
                              slot.startMinute
                            }`
                          : ""
                      }
                      disabled
                      InputProps={{
                        inputProps: {
                          style: { textAlign: "center" },
                        },
                      }}
                    />
                    <span style={{ margin: "0 0.5em", fontSize: 10 }}>—</span>
                    <TextField
                      value={
                        slot.endHour + 1 && slot.endMinute + 1
                          ? `${slot.endHour < 10 ? "0" : ""}${slot.endHour}:${
                              slot.endMinute < 10 ? "0" : ""
                            }${slot.endMinute}`
                          : ""
                      }
                      disabled
                      InputProps={{
                        inputProps: {
                          style: { textAlign: "center" },
                        },
                      }}
                    />
                  </div>
                ))
              ) : (
                <TextField
                  value={t("closed")}
                  disabled
                  InputProps={{
                    inputProps: {
                      style: { textAlign: "center" },
                    },
                  }}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

OpeningHours.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  openingHours: PropTypes.object.isRequired,
};

export default withTranslation("ui-parts")(withStyles(styles)(OpeningHours));
