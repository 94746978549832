export default {
  ORDER: {
    ADD_ITEM: "ORDER.ADD_ITEM",
    ADD_ITEM_WITH_OPTIONS: "ORDER.ADD_ITEM_WITH_OPTIONS",
    REMOVE_ITEM: "ORDER.REMOVE_ITEM",
    CLEAR: "ORDER.CLEAR",
    CLEAR_PREPARED_ORDER: "ORDER.CLEAR_PREPARED_ORDER",
    CLEAR_BENEFIT_HASH: "ORDER.CLEAR_BENEFIT_HASH",
    SET_LOCATION: "ORDER.SET_LOCATION",
    SET_TABLE_ID: "ORDER.SET_TABLE_ID",
    SET_VENUE_ID: "ORDER.SET_VENUE_ID",
    SET_TIME: "ORDER.SET_TIME",
    SET_TIP: "ORDER.SET_TIP",
    SET_EXPRESS: "ORDER.SET_EXPRESS",
    SET_CO2_OFFSETTING: "ORDER.SET_CO2_OFFSETTING",
    SET_LOCATION_OPTION: "ORDER.SET_LOCATION_OPTION",
    SET_DELIVERY_ADDRESS: "ORDER.SET_DELIVERY_ADDRESS",
    SET_ORDER_TIME: "ORDER.SET_ORDER_TIME",
    SET_USER_NAME: "ORDER.SET_USER_NAME",
    SET_USER_EMAIL: "ORDER.SET_USER_EMAIL",
    SET_DELIVERY_STREET_NAME: "ORDER.SET_DELIVERY_STREET_NAME",
    SET_DELIVERY_STREET_NUMBER: "ORDER.SET_DELIVERY_STREET_NUMBER",
    SET_DELIVERY_CITY: "ORDER.SET_DELIVERY_CITY",
    SET_DELIVERY_POSTAL_CODE: "ORDER.SET_DELIVERY_POSTAL_CODE",
    SET_DELIVERY_ADDITIONAL_INFORMATION:
      "ORDER.SET_DELIVERY_ADDITIONAL_INFORMATION",
    SET_PHONENUMBER: "SET_PHONENUMBER",
  },
  PREPARE_ORDER: {
    REQUEST: "PREPARE_ORDER.REQUEST",
    SUCCESS: "PREPARE_ORDER.SUCCESS",
    FAILURE: "PREPARE_ORDER.FAILURE",
  },
  FEES_BENEFITS: {
    REQUEST: "FEES_BENEFITS.REQUEST",
    SUCCESS: "FEES_BENEFITS.SUCCESS",
    FAILURE: "FEES_BENEFITS.FAILURE",
  },
  EXECUTE_ORDER: {
    REQUEST: "EXECUTE_ORDER.REQUEST",
    SUCCESS: "EXECUTE_ORDER.SUCCESS",
    FAILURE: "EXECUTE_ORDER.FAILURE",
  },
  EXPRESS_ORDER: {
    START: "EXPRESS_ORDER.START",
    SUCCESS: "EXPRESS_ORDER.SUCCESS",
    FAILURE: "EXPRESS_ORDER.FAILURE",
  },
};
