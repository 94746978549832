import {
  select,
  put,
  call,
  takeEvery,
  all,
  race,
  take,
} from "redux-saga/effects";
import * as types from "../types";
import {
  addItemWithOptions,
  addItem,
  prepareOrderSuccess,
  prepareOrderFailure,
  executeOrderSuccess,
  executeOrderFailure,
  setExpress,
  clearOrder,
  clearPreparedOrder,
  expressOrderFailure,
  expressOrderSuccess,
  setTableId,
  setLocationOption,
  successBenefitsFees,
  failedBenefitsFees,
} from "../actions/order";
import {
  prepareOrder as prepareOrderTransport,
  executeOrder as executeOrderTransport,
} from "../../api/order";
import { cleanUpPositionIds } from "../../lib/order";
import { calculatePrice } from "../../lib/price";
import { getUsableLocale } from "../../lib/i18next";
import { injectError } from "../actions/errors";
import history from "../../history";
import routes from "../../routes";
import { loginSaga } from "./auth";
import { resetItemConfiguration } from "../actions/item-configuration";
import {
  convertMonetaryValue,
  logRevenue,
  logEvent,
} from "../../lib/analytics";
import { ORDER_SUCCESS } from "../../lib/analytics/events";
import {
  VENUE_LOCATION_DELIVERY,
  VENUE_LOCATION_TO_GO,
} from "@orda/shared-constants/order-locations";
import { setApplePayToken, setGooglePayToken } from "../actions/payments";
import { PaymentOption } from "@orda/shared-constants";
import { retrieveBenefitsAndFees } from "../../api/payments";

export function* addItemToExpressOrder(
  venueId,
  itemId,
  configuredOptions,
  note
) {
  if (configuredOptions) {
    yield put(addItemWithOptions(venueId, itemId, configuredOptions, note));
  } else {
    yield put(addItem(venueId, itemId, note));
  }
}

export function* prepareOrderSaga(action) {
  try {
    const {
      items,
      tip,
      location: orderLocation,
      tableId,
      userName,
      userEmail,
      orderTime,
      deliveryStreetName,
      deliveryStreetNumber,
      deliveryCity,
      deliveryPostalCode,
      deliveryAdditionalInformation,
      feesBenefits: { fees, benefits, sumBenefits, expandableBenefits },
      phoneNumber,
    } = yield select((state) => state.order);

    let deliveryAddress;
    if (orderLocation === VENUE_LOCATION_DELIVERY) {
      deliveryAddress = `${deliveryStreetName}, ${deliveryStreetNumber}, ${deliveryPostalCode}, ${deliveryCity}${
        deliveryAdditionalInformation
          ? `, ${deliveryAdditionalInformation}`
          : ""
      }`;
    }

    const venue = yield select((state) => state.venues.data[action.venueId]);
    const { currentPaymentMethod, paymentMethods } = yield select(
      (state) => state.user
    );

    const {
      menu: { items: menuItems },
    } = venue;

    const paymentMethodId = currentPaymentMethod || paymentMethods[0].id;
    if (!paymentMethodId) {
      throw new Error("No valid payment option was found!");
    }

    const paymentMethod = paymentMethods.find(
      ({ id }) => id === paymentMethodId
    );

    const { sum } = calculatePrice(
      items,
      menuItems,
      venue,
      orderLocation,
      tip,
      benefits,
      sumBenefits,
      expandableBenefits,
      fees
    );
    const expectedSum = Math.max(sum, 0);

    const user = yield select((state) => state.auth.user);
    const authToken = yield user.getIdToken();

    const params = {
      userId: user.uid,
      items: cleanUpPositionIds(items),
      venue: action.venueId,
      orderLocation,
      userName,
      userEmail,
      deliveryAddress,
      orderTime,
      tableId,
      locale: getUsableLocale(),
      tip,
      paymentOptionType: paymentMethod.type,
      paymentOptionId: paymentMethodId,
      expectedSum,
      metadata: {
        platform: "web",
        userAgent: global.navigator.userAgent,
      },
      phoneNumber,
    };

    const preparedOrder = yield call(
      prepareOrderTransport,
      user.uid,
      params,
      authToken
    );
    yield put(prepareOrderSuccess(preparedOrder));

    if (action && action.notifyPromise) {
      action.notifyPromise.resolve();
    }
    return true;
  } catch (error) {
    yield put(prepareOrderFailure(error));
    if (action && action.notifyPromise) {
      action.notifyPromise.reject();
    }
    return false;
  }
}

export function* executeOrderSaga(action) {
  try {
    const preparedOrder = yield select((state) => state.order.preparedOrder);
    const express = yield select((state) => state.order.express);
    const { applePayTokenId, googlePayTokenId } = yield select(
      (state) => state.user.tokens
    );

    if (!preparedOrder) {
      throw new Error("No order to execute!");
    }

    const user = yield select((state) => state.auth.user);
    const authToken = yield user.getIdToken();
    const params = {
      userId: user.uid,
      orderId: preparedOrder.orderId,
      metadata: {
        platform: "web",
        userAgent: global.navigator.userAgent,
      },
      applePayTokenId,
      googlePayTokenId,
    };

    const order = yield call(
      executeOrderTransport,
      user.uid,
      preparedOrder.orderId,
      params,
      authToken
    );

    // track revenue
    const eventProperties = {
      venueId: order.venueId,
      venueName: order.venueName.venue,
      sumBeforeEverything: convertMonetaryValue(
        order.currency,
        order.sumBeforeEverything
      ),
      tip: order.tip,
      tipSum: convertMonetaryValue(order.currency, order.tipSum),
      feeSum: convertMonetaryValue(order.currency, order.feeSum),
      sumBenefitSum: convertMonetaryValue(order.currency, order.sumBenefitSum),
      sumTotal: convertMonetaryValue(order.currency, order.sumTotal),
      orderLocation: order.orderLocation,
      userName: order.userName,
      userEmail: order.userEmail,
      deliveryAddress: order.deliveryAddress,
      phoneNumber: order.phoneNumber,
      paymentMethod: order.paymentOption.type,
      orderId: order.orderId,
      orderTime: order.orderTime ? order.orderTime : "",
      express,
      applePayTokenId,
      googlePayTokenId,
    };
    logRevenue(
      order.venueId,
      1,
      convertMonetaryValue(order.currency, order.sumTotal)
      // event properties are not currently tracked with revenue since the
      // react-native package doesn't support it
    );
    logEvent(ORDER_SUCCESS, eventProperties);

    yield put(executeOrderSuccess(order));
    yield put(setGooglePayToken(null));
    yield put(setApplePayToken(null));
    yield put(setTableId(null));
    yield put(setLocationOption(VENUE_LOCATION_TO_GO));
    if (action && action.notifyPromise) {
      action.notifyPromise.resolve();
    }
    return true;
  } catch (error) {
    yield put(executeOrderFailure(error));
    // needed here since the error page will be displayed
    yield put(injectError(error));

    if (action && action.notifyPromise) {
      action.notifyPromise.reject();
    }
    return false;
  }
}

/**
 * This saga is responsible for handling express orders. As an exceptional case, this
 * saga will directly control the UI end-to-end. Escaping out to the normal cart flow
 * when it encounters an irrecoverable error.
 *
 * @param {object} action
 */
function* expressOrderSaga(action) {
  // set the order type to express
  yield put(setExpress(true));

  if (!(yield select((state) => state.auth.syncFinished))) {
    yield take(types.auth.SYNC_FIREBASE_USER);
  }

  const user = yield select((state) => state.auth.user);

  if (!user) {
    if (!(yield* loginSaga())) {
      // error logging the user in cancel the express order and show error page
      yield call([history, history.push], routes.errors.operation.path);
      yield put(expressOrderFailure());
    }
  }

  // add item from configuration to express order
  yield* addItemToExpressOrder(
    action.venueId,
    action.itemId,
    action.configuredOptions,
    action.note
  );

  // get the user's payment methods
  const paymentMethods = yield select((state) => state.user.paymentMethods);

  // when no payment methods exist
  if (paymentMethods.length === 0) {
    // redirect to add payment method page
    yield call(
      [history, history.push],
      routes.payments.add.template(action.venueId)
    );
    // wait until a payment method is selected
    yield race([
      take(types.payments.ADD_CREDIT_CARD.SUCCESS),
      take(types.payments.ADD_PAYPAL_CHECKOUT),
    ]);
  }

  const currentPaymentMethod = yield select((state) =>
    state.user.paymentMethods.find(
      (paymentMethod) => paymentMethod.id === state.user.currentPaymentMethod
    )
  );

  if (currentPaymentMethod.type === PaymentOption.CreditCard) {
    if (!(yield* prepareOrderSaga({ venueId: action.venueId }))) {
      yield call([history, history.push], routes.cart.template(action.venueId));
      yield put(setExpress(false));
      yield put(expressOrderFailure());
      return;
    }

    if (yield* executeOrderSaga()) {
      yield call([history, history.push], routes.orders.success.path);
      yield put(clearOrder());
      yield put(resetItemConfiguration());
      yield put(expressOrderSuccess());
    } else {
      yield call([history, history.push], routes.errors.operation.path);
      yield put(clearPreparedOrder());
      yield put(expressOrderFailure());
    }
  }
}

function* getBenefitsFeesSaga({ venueId }) {
  try {
    const user = yield select((state) => state.auth.user);
    let authToken = null;
    if (user && user.uid) {
      authToken = yield user.getIdToken();
    }
    const venue = yield select((state) => state.venues.data[venueId]);
    const { currentPaymentMethod, paymentMethods } = yield select(
      (state) => state.user
    );
    const currentPaymentMethodInternal = currentPaymentMethod
      ? paymentMethods.find(
          (paymentMethod) => paymentMethod.id === currentPaymentMethod
        )
      : null;
    const currentPaymentMethodInternalType = currentPaymentMethodInternal
      ? currentPaymentMethodInternal.type
      : null;
    // eslint-disable-next-line no-unreachable
    const { fees, benefits, sumBenefits, expandableBenefits } = yield call(
      retrieveBenefitsAndFees,
      currentPaymentMethodInternalType,
      venue.currency,
      venueId,
      authToken
    );
    yield put(
      successBenefitsFees(
        // not needed for now to custom create a hash (this in the case we want to make this saga doesn't get called more than twice by checking if hash has changed)
        "hash",
        benefits,
        sumBenefits,
        expandableBenefits,
        fees
      )
    );
  } catch (error) {
    yield put(failedBenefitsFees(error));
  }
}

export default function* ordersRootSaga() {
  yield all([
    takeEvery(types.order.PREPARE_ORDER.REQUEST, prepareOrderSaga),
    takeEvery(types.order.EXECUTE_ORDER.REQUEST, executeOrderSaga),
    takeEvery(types.order.EXPRESS_ORDER.START, expressOrderSaga),
    takeEvery(types.order.FEES_BENEFITS.REQUEST, getBenefitsFeesSaga),
  ]);
}
