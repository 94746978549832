import React, { useMemo } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { THEME } from "../theme";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Close } from "mdi-material-ui";
import { IconButton, Paper, Typography } from "@material-ui/core";
import OpeningHours from "./OpeningHours";

const styles = {
  addItemDialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  addItemDialogClose: {
    color: THEME.MAIN_GREEN,
  },
  title: {
    fontSize: 20,
    fontFamily: "Open Sans",
    color: THEME.PRIMARY_TEXT_COLOR,
    wordBreak: "break-all",
  },
  text: {
    fontSize: 14,
    fontFamily: "Open Sans",
    color: THEME.PRIMARY_TEXT_COLOR,
    padding: "10",
  },
  item: {
    padding: 10,
  },
};

const VenueInfoModal = ({
  t,
  classes,
  open,
  setOpen,
  venueName,
  venueAddress,
  orderLocations,
  prepTime,
  openingHours,
  // phoneNumber,
}) => {
  const handleClose = () => setOpen(false);
  const { isAllowedDelivery, isAllowedTOGO, isAllowedDINEIN } = orderLocations;
  const orderLocationString = useMemo(() => {
    let builtString = "";

    if (isAllowedTOGO) {
      builtString += t("toGo");
    }
    if (isAllowedDINEIN) {
      builtString += builtString === "" ? t("dineIn") : `, ${t("dineIn")}`;
    }
    if (isAllowedDelivery) {
      builtString += builtString === "" ? t("delivery") : `, ${t("delivery")}`;
    }
    return builtString;
  }, [orderLocations]);

  return (
    <div>
      <Dialog
        fullWidth
        PaperProps={{
          style: {
            margin: "4%",
          },
        }}
        open={open}
        maxWidth="md"
        aria-labelledby="add-menu-item-dialog"
      >
        <DialogTitle id="add-menu-item-dialog">
          <div className={classes.addItemDialogTitle}>
            <Typography variant="body2" className={classes.title}>
              {venueName}
            </Typography>
            <IconButton
              className={classes.addItemDialogClose}
              onClick={() => handleClose()}
            >
              <Close fontSize="small" />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <Paper elevation={1} className={classes.item}>
            {!!venueAddress && (
              <Paper elevation={0} className={classes.item}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    variant="body2"
                    className={classes.text}
                    style={{ fontWeight: "bold" }}
                  >
                    {t("venueAddress")}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.text}
                    style={{ marginLeft: 10 }}
                  >
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.mapLink}
                      href={`https://www.google.com/maps/search/?api=1&query=${venueAddress}`}
                    >
                      {venueAddress}
                    </a>
                  </Typography>
                </div>
              </Paper>
            )}
            <Paper elevation={0} className={classes.item}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  variant="body2"
                  className={classes.text}
                  style={{ fontWeight: "bold" }}
                >
                  {t("orderLocations")}
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.text}
                  style={{ marginLeft: 10 }}
                >
                  {orderLocationString}
                </Typography>
              </div>
            </Paper>
            <Paper elevation={0} className={classes.item}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  variant="body2"
                  className={classes.text}
                  style={{ fontWeight: "bold" }}
                >
                  {t("prepTimeLabel")}
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.text}
                  style={{ marginLeft: 10 }}
                >
                  {t("prepTime", prepTime)}
                </Typography>
              </div>
            </Paper>
            {/* {!!phoneNumber && (
              <Paper elevation={0} className={classes.item}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    variant="body2"
                    className={classes.text}
                    style={{ fontWeight: "bold" }}
                  >
                    {t("phoneNumber")}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.text}
                    style={{ marginLeft: 10 }}
                  >
                    <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                  </Typography>
                </div>
              </Paper>
            )} */}
            {openingHours && (
              <Paper elevation={0} className={classes.item}>
                <Typography
                  variant="body2"
                  className={classes.text}
                  style={{ fontWeight: "bold", marginBottom: 5 }}
                >
                  {t("openingHours")}
                </Typography>
                <Paper elevation={1} className={classes.item}>
                  <OpeningHours openingHours={openingHours} />
                </Paper>
              </Paper>
            )}
          </Paper>
        </DialogContent>
      </Dialog>
    </div>
  );
};

VenueInfoModal.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.any.isRequired,
  venueName: PropTypes.string.isRequired,
  venueAddress: PropTypes.string.isRequired,
  orderLocations: PropTypes.object.isRequired,
  prepTime: PropTypes.any.isRequired,
  openingHours: PropTypes.any.isRequired,
  // phoneNumber: PropTypes.string.isRequired,
};

export default withTranslation("venueInfoModal")(
  withStyles(styles)(VenueInfoModal)
);
