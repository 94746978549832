import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { IconButton, withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { ArrowLeft } from "mdi-material-ui";
import { withTranslation, Trans } from "react-i18next";
import SecurityNotice from "./SecurityNotice";
import routes from "../routes";
import Loading from "./Loading";
import { THEME } from "../theme";
import CheckoutPaymentOptions from "../containers/CheckoutPaymentOptions";
import { Elements } from "react-stripe-elements";

const styles = {
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    backgroundColor: "#fafafa",
  },
  appBar: {
    display: "flex",
    backgroundColor: THEME.SECONDARY_BUTTON_ICON,
    boxShadow: "0px 4px 20px 1px rgba(0,0,0,0.2)",
    position: "sticky",
    height: 50,
    top: 0,
    zIndex: 3,
  },
  explanationTextContainer: {
    flexGrow: 0.5,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  buttonsContainer: {
    width: "100%",
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonsWrapper: {
    padding: 40,
    width: "100%",
  },
  securityNoticeContainer: {
    flexGrow: 1,
    padding: "0 40px 60px 40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  termsNotice: {
    textAlign: "center",
    color: "#999",
    fontSize: "0.7em",
    marginBottom: 10,
  },
  iconButton: {
    color: THEME.PRIMARY_BUTTON_ICON,
    backgroundColor: THEME.SECONDARY_BUTTON_ICON,
    "&:hover": {
      opacity: 0.8,
    },
    borderRadius: 0,
    fontSize: "clamp(0.7rem, 3vmin, 1rem)",
  },
  header: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const AddPaymentMethod = ({
  t,
  express,
  classes,
  authUser,
  authSyncFinished,
  login,
  loginProcessing,
  location: { search },
  hasSeenTermsNotice,
  paymentOptions,
  getVenue,
  venueLoaded,
}) => {
  const searchParams = new URLSearchParams(search);
  const venueId = searchParams.get("cartVenueId");
  useEffect(() => {
    if (authSyncFinished && !authUser) {
      login();
    }
  }, [authSyncFinished, !!authUser]);

  useEffect(() => {
    if (!venueLoaded) {
      getVenue(venueId);
    }
  }, [venueLoaded]);

  useEffect(() => {
    global.window.scroll(0, 0);
  }, []);
  return !authSyncFinished || (!authUser && loginProcessing) || !venueLoaded ? (
    <Loading />
  ) : (
    <div className={classes.root}>
      <div className={classes.appBar}>
        <IconButton
          className={classes.iconButton}
          component={Link}
          to={routes.cart.template(venueId)}
        >
          <ArrowLeft />
        </IconButton>
        <div className={classes.header}>
          <span style={{ fontSize: "1rem" }} className="orda-icon-ORDA" />
        </div>
      </div>
      <div className={classes.explanationTextContainer}>
        <Typography variant="body2">{t("explanationText")}</Typography>
      </div>
      <div className={classes.buttonsContainer}>
        <div className={classes.buttonsWrapper}>
          <Elements>
            <CheckoutPaymentOptions paymentOptions={paymentOptions} />
          </Elements>
        </div>
      </div>
      {express && !hasSeenTermsNotice && (
        <Typography variant="body2" className={classes.termsNotice}>
          <Trans i18nKey="cart:termsNotice">
            By placing an order you are accepting the
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.orda-app.com/agb-nutzer"
              className={classes.link}
            >
              Terms & Conditions
            </a>
            .
          </Trans>
        </Typography>
      )}
      <div className={classes.securityNoticeContainer}>
        <SecurityNotice />
      </div>
    </div>
  );
};

AddPaymentMethod.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  express: PropTypes.bool,
  authUser: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  loginProcessing: PropTypes.bool.isRequired,
  authSyncFinished: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  hasSeenTermsNotice: PropTypes.bool.isRequired,
  paymentOptions: PropTypes.array.isRequired,
  getVenue: PropTypes.func.isRequired,
  venueLoaded: PropTypes.bool.isRequired,
};

AddPaymentMethod.defaultProps = {
  express: false,
};

export default withTranslation("addPaymentMethod")(
  withStyles(styles)(AddPaymentMethod)
);
