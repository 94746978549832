import firebase from "firebase";

const config = {
  apiKey: "AIzaSyCjOkW3BBN0LLJXEt3sV_dBjgQ4QSp1a90",
  authDomain: "orda-154518.firebaseapp.com",
  databaseURL: "https://orda-154518.firebaseio.com",
  storageBucket: "orda-154518.appspot.com",
  messagingSenderId: "455267422899",
};

firebase.initializeApp(config);

export default firebase;
