import { API_ENDPOINT } from "../config";
import { transport } from "../lib/transport";
import history from "../history";

export const getVenueIdByAlias = async (venueAlias) => {
  const result = await transport({
    method: "GET",
    url: `${API_ENDPOINT}/venues/alias/${venueAlias}`,
  });
  const uuidRegexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  if (uuidRegexExp.test(result)) {
    history.push(`/venues/${result}`);
  } else {
    history.push("/error");
  }
};
export const getVenue = async (venueId) => {
  const uuidRegexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  if (uuidRegexExp.test(venueId)) {
    const result = await transport({
      method: "GET",
      url: `${API_ENDPOINT}/venues/${venueId}`,
    });
    return result;
  }
  await getVenueIdByAlias(venueId);
  return null;
};

export const posMessageTransport = async (venueId, payload) => {
  const result = await transport({
    method: "POST",
    url: `${API_ENDPOINT}/venues/${venueId}/pos-message`,
    body: JSON.stringify(payload),
  });
  return result;
};
