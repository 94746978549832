/* eslint no-param-reassign: 0 */
import * as types from "../types";
import { generate } from "shortid";
import produce from "immer";
import { PaymentOption } from "@orda/shared-constants";

export default function paymentsReducer(state = null, action) {
  switch (action.type) {
    case types.payments.ADD_CREDIT_CARD.REQUEST:
      return produce(state, (newState) => {
        newState.paymentMethods = state.paymentMethods.filter(
          ({ id }) => id !== state.currentPaymentMethod
        );
        newState.currentPaymentMethod = null;
        newState.tokens.googlePayTokenId = null;
        newState.tokens.applePayTokenId = null;
        newState.requests.addCreditCard.processing = true;
        newState.requests.addCreditCard.error = null;
      });
    case types.payments.ADD_CREDIT_CARD.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.addCreditCard.processing = false;
        newState.paymentMethods.push({
          ...action.data,
          type: PaymentOption.CreditCard,
        });
        newState.currentPaymentMethod = action.data.id;
      });
    case types.payments.ADD_PAYMENT_OPTION.FAILURE:
      return produce(state, (newState) => {
        newState.requests.addPaymentOption.processing = false;
        newState.requests.addPaymentOption.error = action.error;
      });
    case types.payments.ADD_PAYMENT_OPTION.REQUEST:
      return produce(state, (newState) => {
        newState.paymentMethods = state.paymentMethods.filter(
          ({ id }) => id !== state.currentPaymentMethod
        );
        newState.currentPaymentMethod = null;
        newState.tokens.googlePayTokenId = null;
        newState.tokens.applePayTokenId = null;
        newState.requests.addPaymentOption.processing = true;
        newState.requests.addPaymentOption.error = null;
      });
    case types.payments.ADD_PAYMENT_OPTION.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.addPaymentOption.processing = false;
        newState.paymentMethods.push({
          ...action.data,
        });
        newState.currentPaymentMethod = action.data.id;
      });
    case types.payments.ADD_CREDIT_CARD.FAILURE:
      return produce(state, (newState) => {
        newState.requests.addCreditCard.processing = false;
        newState.requests.addCreditCard.error = action.error;
      });
    case types.payments.SET_PAYMENT_REQUEST:
      return produce(state, (newState) => {
        newState.paymentRequest = action.paymentRequest;
      });
    case types.payments.SET_GOOGLE_PAY_TOKEN:
      return produce(state, (newState) => {
        newState.tokens.googlePayTokenId = action.googlePayTokenId;
      });
    case types.payments.SET_APPLE_PAY_TOKEN:
      return produce(state, (newState) => {
        newState.tokens.applePayTokenId = action.applePayTokenId;
      });
    case types.payments.ADD_PAYPAL_CHECKOUT:
      return produce(state, (newState) => {
        const id = generate();
        newState.paymentMethods.push({
          id,
          type: action.isPrivate
            ? PaymentOption.PayPalPrivateCheckout
            : PaymentOption.PayPalCheckout,
        });
        newState.currentPaymentMethod = id;
      });
    default:
      return state;
  }
}
