import { API_ENDPOINT } from "../config";
import { AUTH_PROVIDER_FIREBASE_ANONYMOUS } from "@orda/shared-constants-js";
import { transport } from "../lib/transport";

export const createUser = async (userId) => {
  const result = await transport({
    method: "POST",
    url: `${API_ENDPOINT}/users`,
    body: JSON.stringify({
      provider: AUTH_PROVIDER_FIREBASE_ANONYMOUS,
      id: userId,
    }),
  });
  return result;
};
