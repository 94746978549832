import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import PaymentOptionItem from "../containers/PaymentOptionItem";
import Grid from "@material-ui/core/Grid";
import { PaymentOption } from "@orda/shared-constants";
import { injectStripe } from "react-stripe-elements";

const styles = {
  paymentButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
};

const CheckoutPaymentOptions = ({
  paymentOptions,
  classes,
  orderLocation,
  stripe,
  subTotal,
  currency,
  label,
  countryCode,
}) => {
  const [supportedPayments, setSupportedPayments] = useState({
    applePay: false,
    googlePay: false,
    link: false,
  });

  const isSupported = (type) => {
    switch (type) {
      case PaymentOption.ApplePay:
        return supportedPayments && supportedPayments.applePay;
      case PaymentOption.GooglePay:
        return supportedPayments && supportedPayments.googlePay;
      default:
        return true;
    }
  };

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: countryCode.toUpperCase(),
        currency,
        total: {
          label,
          amount: subTotal,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setSupportedPayments(result);
        }
      });
    }
  }, [stripe]);
  return (
    <Grid container spacing={32}>
      {paymentOptions.length > 0 &&
        paymentOptions.map(
          (paymentOption) =>
            paymentOption &&
            paymentOption.enabled &&
            paymentOption.modes[orderLocation] &&
            paymentOption.modes[orderLocation].enabled &&
            isSupported(paymentOption.id) && (
              <Grid
                key={paymentOption.id}
                className={classes.paymentButtonContainer}
                item
                xs={12}
              >
                <PaymentOptionItem
                  key={paymentOption.id}
                  paymentOption={paymentOption}
                />
              </Grid>
            )
        )}
    </Grid>
  );
};

CheckoutPaymentOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  paymentOptions: PropTypes.array.isRequired,
  subTotal: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  orderLocation: PropTypes.number.isRequired,
  stripe: PropTypes.object.isRequired,
  countryCode: PropTypes.string.isRequired,
};

export default withStyles(styles)(injectStripe(CheckoutPaymentOptions));
