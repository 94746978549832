/* eslint no-param-reassign: 0 */
import * as types from "../types";
import produce from "immer";
import { REHYDRATE } from "redux-persist/lib/constants";
import { get } from "lodash";
import { VENUE_LOCATION_EAT_IN } from "@orda/shared-constants/order-locations";

const FeesBenefits = {
  hash: null,
  fees: [],
  benefits: [],
  sumBenefits: [],
  expandableBenefits: [],
  fetching: false,
  error: null,
};

export const initialState = {
  requests: {
    expressOrder: {
      processing: false,
    },
    prepareOrder: {
      processing: false,
      error: null,
    },
    executeOrder: {
      processing: false,
      error: null,
    },
    feesBenefits: {
      processing: false,
      error: null,
    },
  },
  items: [],
  tip: 0,
  location: 2,
  deliveryAddress: "",
  orderTime: null,
  preparedOrder: null,
  userName: "",
  userEmail: "",
  feesBenefits: FeesBenefits,
  tableId: null,
  express: false,
  deliveryStreetName: "",
  deliveryStreetNumber: "",
  deliveryCity: "",
  deliveryPostalCode: "",
  deliveryAdditionalInformation: "",
  phoneNumber: null,
  venueId: null,
};

export default function orderReducer(state = initialState, action) {
  switch (action.type) {
    case types.order.ORDER.CLEAR:
      return {
        ...initialState,
        tableId: state.tableId,
      };
    case types.order.ORDER.CLEAR_PREPARED_ORDER:
      return produce(state, (newState) => {
        newState.preparedOrder = null;
      });
    case types.order.ORDER.ADD_ITEM:
      return produce(state, (newState) => {
        const item = {
          id: action.id,
          itemId: action.itemId,
        };
        const note = action.note && action.note.trim();
        if (note) {
          item.note = note;
        }
        newState.items.push(item);
        newState.preparedOrder = null;
        newState.requests.prepareOrder.error = null;
      });
    case types.order.ORDER.ADD_ITEM_WITH_OPTIONS:
      return produce(state, (newState) => {
        const item = {
          id: action.id,
          itemId: action.itemId,
          orderOptions: action.configuredOptions,
        };
        const note = action.note && action.note.trim();
        if (note) {
          item.note = note;
        }
        newState.items.push(item);
        newState.preparedOrder = null;
        newState.requests.prepareOrder.error = null;
      });
    case types.order.ORDER.REMOVE_ITEM:
      return produce(state, (newState) => {
        const idx = newState.items.findIndex(
          ({ id }) => id === action.cartItemId
        );
        newState.items.splice(idx, 1);
        newState.preparedOrder = null;
        newState.requests.prepareOrder.error = null;
      });
    case types.order.PREPARE_ORDER.REQUEST:
      return produce(state, (newState) => {
        newState.requests.prepareOrder.processing = true;
        newState.requests.prepareOrder.error = null;
        newState.preparedOrder = null;
      });
    case types.order.PREPARE_ORDER.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.prepareOrder.processing = false;
        newState.preparedOrder = action.data;
      });
    case types.order.PREPARE_ORDER.FAILURE:
      return produce(state, (newState) => {
        newState.requests.prepareOrder.processing = false;
        newState.requests.prepareOrder.error = action.error;
      });
    case types.order.EXECUTE_ORDER.REQUEST:
      return produce(state, (newState) => {
        newState.requests.executeOrder.processing = true;
        newState.requests.executeOrder.error = null;
      });
    case types.order.EXECUTE_ORDER.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.executeOrder.processing = false;
      });
    case types.order.EXECUTE_ORDER.FAILURE:
      return produce(state, (newState) => {
        newState.requests.executeOrder.processing = false;
        newState.requests.executeOrder.error = action.error;
      });
    case types.order.ORDER.SET_TABLE_ID:
      return produce(state, (newState) => {
        newState.tableId = action.tableId;
      });
    case types.order.ORDER.SET_TIP:
      return produce(state, (newState) => {
        newState.tip = action.tip;
        newState.preparedOrder = null;
      });
    case types.order.ORDER.SET_LOCATION_OPTION:
      return produce(state, (newState) => {
        if (action.location !== VENUE_LOCATION_EAT_IN) {
          newState.tableId = null;
        }
        // uncomment this if you want to be able to clear address form when switching order location options
        // if (action.location !== VENUE_LOCATION_DELIVERY) {
        //   newState.deliveryAddress = "";
        //   newState.deliveryStreetName = "";
        //   newState.deliveryStreetNumber = "";
        //   newState.deliveryAdditionalInformation = "";
        //   newState.deliveryCity = "";
        //   newState.deliveryPostalCode = "";
        //   newState.userEmail = "";
        //   newState.userName = "";
        // }
        newState.location = action.location;
        newState.preparedOrder = null;
      });
    case types.order.ORDER.SET_DELIVERY_ADDRESS:
      return produce(state, (newState) => {
        newState.deliveryAddress = action.deliveryAddress;
        newState.preparedOrder = null;
      });
    case types.order.ORDER.SET_ORDER_TIME:
      return produce(state, (newState) => {
        newState.orderTime = action.orderTime;
        newState.preparedOrder = null;
      });
    case types.order.ORDER.SET_USER_NAME:
      return produce(state, (newState) => {
        newState.userName = action.userName;
        newState.preparedOrder = null;
      });
    case types.order.ORDER.SET_USER_EMAIL:
      return produce(state, (newState) => {
        newState.userEmail = action.userEmail;
        newState.preparedOrder = null;
      });
    case types.order.ORDER.SET_DELIVERY_STREET_NAME:
      return produce(state, (newState) => {
        newState.deliveryStreetName = action.deliveryStreetName;
        newState.preparedOrder = null;
      });
    case types.order.ORDER.SET_DELIVERY_STREET_NUMBER:
      return produce(state, (newState) => {
        newState.deliveryStreetNumber = action.deliveryStreetNumber;
        newState.preparedOrder = null;
      });
    case types.order.ORDER.SET_DELIVERY_CITY:
      return produce(state, (newState) => {
        newState.deliveryCity = action.deliveryCity;
        newState.preparedOrder = null;
      });
    case types.order.ORDER.SET_DELIVERY_POSTAL_CODE:
      return produce(state, (newState) => {
        newState.deliveryPostalCode = action.deliveryPostalCode;
        newState.preparedOrder = null;
      });

    case types.order.ORDER.SET_DELIVERY_ADDITIONAL_INFORMATION:
      return produce(state, (newState) => {
        newState.deliveryAdditionalInformation =
          action.deliveryAdditionalInformation;
        newState.preparedOrder = null;
      });
    case types.order.ORDER.SET_PHONENUMBER:
      return produce(state, (newState) => {
        newState.phoneNumber = action.phoneNumber;
        newState.preparedOrder = null;
      });
    case types.order.ORDER.SET_CO2_OFFSETTING:
      return produce(state, (newState) => {
        newState.co2OffsetValue =
          action.co2OffsetValue * get(state, ["items", "length"], 0);
      });
    case types.order.FEES_BENEFITS.REQUEST:
      return produce(state, (newState) => {
        newState.feesBenefits.fetching = true;
      });
    case types.order.FEES_BENEFITS.FAILURE:
      return produce(state, (newState) => {
        newState.feesBenefits.fetching = false;
        newState.feesBenefits.error = action.error;
      });
    case types.order.FEES_BENEFITS.SUCCESS:
      return produce(state, (newState) => {
        newState.feesBenefits = {
          ...FeesBenefits,
          hash: action.hash,
          fees: action.fees,
          benefits: action.benefits,
          sumBenefits: action.sumBenefits,
          expandableBenefits: action.expandableBenefits,
        };
      });
    case types.order.ORDER.SET_EXPRESS:
      return produce(state, (newState) => {
        newState.express = action.state;
      });
    case types.order.EXPRESS_ORDER.START:
      return produce(state, (newState) => {
        newState.requests.expressOrder.processing = true;
      });
    case types.order.EXPRESS_ORDER.SUCCESS:
    case types.order.EXPRESS_ORDER.FAILURE:
      return produce(state, (newState) => {
        newState.requests.expressOrder.processing = false;
      });
    case types.order.ORDER.SET_VENUE_ID:
      return produce(state, (newState) => {
        newState.venueId = action.venueId;
      });
    case REHYDRATE:
      return produce(state, (newState) => {
        const savedState = action.payload && action.payload.order;
        if (savedState) {
          newState.tableId = savedState.tableId;
          newState.venueId = savedState.venueId;
          newState.location = savedState.location;
        }
      });
    default:
      return state;
  }
}
