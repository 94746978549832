/* eslint no-param-reassign: 0 */
import * as types from "../types";
import produce from "immer";
import paymentsReducer from "./payments";
import { REHYDRATE } from "redux-persist/lib/constants";

export const initialState = {
  userId: null,
  paymentMethods: [],
  orders: [],
  requests: {
    createUser: {
      processing: false,
      error: null,
    },
    addCreditCard: {
      processing: false,
      error: null,
    },
    addPaymentOption: {
      processing: false,
      error: null,
    },
  },
  tokens: {
    applePayTokenId: null,
    googlePayTokenId: null,
  },
  paymentRequest: null,
  currentPaymentMethod: null,
  hasSeenTermsNotice: false,
};

export default function userReducer(userState = initialState, action) {
  const state = paymentsReducer(userState, action);
  switch (action.type) {
    case types.user.SYNC_USER:
      return produce(state, (newState) => {
        if (action.userId !== newState.userId) {
          // new user - nuke everything
          newState.userId = action.userId;
          newState.paymentMethods = [];
          newState.orders = [];
          newState.currentPaymentMethod = null;
        }
      });
    case types.user.CREATE_USER.REQUEST:
      return produce(state, (newState) => {
        newState.requests.createUser.processing = true;
        newState.requests.createUser.error = null;
      });
    case types.user.CREATE_USER.SUCCESS:
      return produce(state, (newState) => {
        newState.requests.createUser.processing = false;
        newState.userId = action.id;
      });
    case types.user.CREATE_USER.FAILURE:
      return produce(state, (newState) => {
        newState.requests.createUser.processing = false;
        newState.requests.createUser.error = action.error;
      });
    case types.order.EXECUTE_ORDER.SUCCESS:
      return produce(state, (newState) => {
        newState.orders.push(action.order);
        if (!newState.hasSeenTermsNotice) {
          newState.hasSeenTermsNotice = true;
        }
      });
    case types.user.CLEAR_CURRENT_PAYMENT_METHOD:
      return produce(state, (newState) => {
        newState.paymentMethods = state.paymentMethods.filter(
          ({ id }) => id !== state.currentPaymentMethod
        );
        newState.currentPaymentMethod = null;
        newState.tokens.googlePayTokenId = null;
        newState.tokens.applePayTokenId = null;
      });
    case REHYDRATE:
      return produce(state, (newState) => {
        const savedState = action.payload && action.payload.user;
        if (savedState) {
          newState.userId = savedState.userId;
          newState.paymentMethods = savedState.paymentMethods;
          newState.orders = savedState.orders;
          newState.currentPaymentMethod = savedState.currentPaymentMethod;
          newState.hasSeenTermsNotice = savedState.hasSeenTermsNotice;
        }
      });
    default:
      return state;
  }
}
