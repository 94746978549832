import * as types from "../types";

import {
  ENTRY_TYPE_REMOVE,
  ENTRY_TYPE_ADD,
} from "../../lib/item-configuration";

export const initItemConfiguration = (minimumOptions) => ({
  type: types.itemConfig.ITEM_CONFIG.INTIALIZE,
  minimumOptions,
});

export const resetItemConfiguration = () => ({
  type: types.itemConfig.ITEM_CONFIG.RESET,
});

const updateItemConfiguration = (entryType, path, option) => ({
  type: types.itemConfig.ITEM_CONFIG.UPDATE,
  entry: {
    entryType,
    path,
    option,
  },
});

export const removeItemConfigurationEntry = (path) =>
  updateItemConfiguration(ENTRY_TYPE_REMOVE, path);

export const addItemConfigurationEntry = (path, option) =>
  updateItemConfiguration(ENTRY_TYPE_ADD, path, option);

export const updateNote = (note) => ({
  type: types.itemConfig.ITEM_CONFIG.UPDATE_NOTE,
  note,
});
