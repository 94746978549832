import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer, { initialState } from "./reducers";
import rootSaga from "./sagas";
import { routerMiddleware } from "connected-react-router";
import history from "../history";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "order"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  // eslint-disable-next-line no-underscore-dangle
  global.window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
