import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { validateEmail, validatePhoneNumber } from "../../util/validation";

const styles = {
  text: {
    marginBottom: "20px",
  },
  invoiceFormField: {
    margin: "10px auto",
    width: "90%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const InvoiceForm = ({
  t,
  classes,
  userName,
  userEmail,
  phoneNumber,
  setUserEmail,
  setPhoneNumber,
  setUserName,
  showAllErrors,
  cachedInvoiceFormData,
  isEmailRequired,
}) => {
  useEffect(() => {
    if (cachedInvoiceFormData.userEmail && !userEmail) {
      setUserEmail(cachedInvoiceFormData.userEmail);
    }
    if (cachedInvoiceFormData.phoneNumber && !phoneNumber) {
      setPhoneNumber(cachedInvoiceFormData.phoneNumber);
    }
    if (cachedInvoiceFormData.userName && !userName) {
      setUserName(cachedInvoiceFormData.userName);
    }
  }, []);

  return (
    <>
      <Typography variant="body2" className={classes.text}>
        {t("invoiceHelpLabel")}
      </Typography>
      <TextField
        id="outlined-basic"
        label={t("nameLabel")}
        className={classes.invoiceFormField}
        fullWidth
        name="name"
        autoComplete="name"
        margin="dense"
        variant="outlined"
        required
        helperText={
          showAllErrors && !userName ? t("requiredStringErrorLabel") : ""
        }
        error={showAllErrors && !userName}
        value={userName || ""}
        onChange={(e) => {
          setUserName(e.target.value);
        }}
        onBlur={(e) => {
          setUserName(e.target.value);
        }}
      />
      <TextField
        className={classes.invoiceFormField}
        id="outlined-basic"
        variant="outlined"
        label={t("emailLabel")}
        name="email"
        autoComplete="email"
        fullWidth
        margin="dense"
        required={isEmailRequired}
        type="email"
        helperText={
          showAllErrors && isEmailRequired && !validateEmail(userEmail)
            ? t("validEmailErrorLabel")
            : ""
        }
        error={showAllErrors && isEmailRequired && !validateEmail(userEmail)}
        value={userEmail || ""}
        defaultValue={cachedInvoiceFormData.cachedUserEmail}
        onChange={(e) => {
          setUserEmail(e.target.value);
        }}
        onBlur={(e) => {
          setUserEmail(e.target.value);
        }}
      />
      <TextField
        id="outlined-basic"
        label={t("phoneNumberLabel")}
        className={classes.invoiceFormField}
        name="phone"
        fullWidth
        margin="dense"
        type="tel"
        autoComplete="tel"
        variant="outlined"
        required
        helperText={
          showAllErrors && !validatePhoneNumber(phoneNumber)
            ? t("validPhoneNumberErrorLabel")
            : ""
        }
        error={showAllErrors && !validatePhoneNumber(phoneNumber)}
        value={phoneNumber || ""}
        defaultValue={cachedInvoiceFormData.cachedPhoneNumber}
        onChange={(e) => {
          setPhoneNumber(e.target.value);
        }}
        onBlur={(e) => {
          setPhoneNumber(e.target.value);
        }}
      />
    </>
  );
};

InvoiceForm.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  userName: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  setUserEmail: PropTypes.func.isRequired,
  setUserName: PropTypes.func.isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
  showAllErrors: PropTypes.bool.isRequired,
  isEmailRequired: PropTypes.bool.isRequired,
  cachedInvoiceFormData: PropTypes.object.isRequired,
};

export default withTranslation("addressForm")(withStyles(styles)(InvoiceForm));
