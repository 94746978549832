import Venue from "./containers/Venue";
import MenuDetailItem from "./containers/MenuDetailItem";
import AddPaymentMethod from "./containers/AddPaymentMethod";
import CrediCardForm from "./components/CreditCardForm/CreditCardFormWrapper";
import Error from "./containers/Error";
import OrderSuccess from "./containers/OrderSuccess";
import Menu from "./containers/Menu";
import ErrorNotFound from "./components/ErrorNotFound";
import Cart from "./containers/Cart/Cart";
import HomeRedirect from "./components/HomeRedirect";
import VenueByAlias from "./components/VenueByAlias";
import StartScreen from "./containers/StartScreen";
import InHousePayment from "./containers/InHousePayment/InHousePayment";
import InHouseCheckoutRedirect from "./containers/InHousePayment/InHouseCheckoutRedirect";

const routes = {
  homeRedirect: {
    public: true,
    path: "/",
    component: HomeRedirect,
    exact: true,
  },
  venue: {
    public: true,
    path: "/venues/:venueId",
    component: Venue,
    exact: false,
  },
  start: {
    exact: true,
    path: "/venues/:venueId/start",
    component: StartScreen,
    template: (venueId) => `/venues/${venueId}/start`,
  },
  inHousePayment: {
    exact: true,
    path: "/venues/:venueId/in-house-payment",
    component: InHousePayment,
    template: (venueId) => `/venues/${venueId}/in-house-payment`,
  },
  inHouseCheckoutRedirect: {
    exact: true,
    path: "/venues/:venueId/in-house-checkout/redirect",
    component: InHouseCheckoutRedirect,
    template: (venueId) => `/venues/${venueId}/in-house-checkout/redirect`,
  },
  menu: {
    public: true,
    exact: true,
    path: "/venues/:venueId/menu",
    component: Menu,
    template: (venueId) => `/venues/${venueId}/menu`,
    id: "MENU",
  },
  item: {
    public: true,
    exact: true,
    path: "/venues/:venueId/menu/items/:itemId",
    component: MenuDetailItem,
    id: "MENU_DETAIL_ITEM",
  },
  cart: {
    public: true,
    exact: true,
    path: "/venues/:venueId/cart",
    component: Cart,
    template: (venueId) => `/venues/${venueId}/cart`,
    id: "CART",
  },
  payments: {
    add: {
      public: false,
      exact: true,
      path: "/payments/add",
      component: AddPaymentMethod,
      template: (venueId) => `/payments/add?cartVenueId=${venueId}`,
    },
    creditCard: {
      public: false,
      exact: true,
      path: "/payments/add/credit-card",
      component: CrediCardForm,
    },
  },
  errors: {
    operation: {
      public: true,
      exact: true,
      path: "/error",
      component: Error,
    },
    notFound: {
      public: true,
      component: ErrorNotFound,
    },
  },
  orders: {
    success: {
      public: false,
      exact: true,
      path: "/order-success",
      component: OrderSuccess,
    },
  },
  venueAlias: {
    exact: true,
    path: "/:venueAlias",
    component: VenueByAlias,
  },
};

export default routes;
