import { connect } from "react-redux";
import { getUsableLocale } from "../lib/i18next";
import { venueStringsSelector } from "../redux/selectors/venue";
import { get } from "lodash";
import VenueMenuHeader from "../components/VenueMenuHeader";

const mapStateToProps = (state, ownProps) => {
  const { venueId } = ownProps;

  const {
    venues: {
      data: { [venueId]: venue },
    },
    order: { tableId },
  } = state;

  // localization and strings
  const locale = getUsableLocale();
  const venueStrings = venueStringsSelector(venue, locale);

  return {
    strings: venueStrings,
    venueId,
    startScreen: venue.startScreen || null,
    heroImage: get(venue, ["theme", "logo", "images", "hero"], null),
    venueAddress: get(venue, ["location", "address"], null),
    venueCoordinates:
      get(venue, ["location", "coordinates", "latitude"], null) &&
      get(venue, ["location", "coordinates", "longitude"], null)
        ? get(venue, ["location", "coordinates"], null)
        : null,
    tableId: tableId || null,
  };
};

export default connect(mapStateToProps)(VenueMenuHeader);
