import { connect } from "react-redux";
import InvoiceForm from "../../components/Cart/InvoiceForm";
import {
  setUserName,
  setUserEmail,
  setPhoneNumber,
} from "../../redux/actions/order";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state) => {
  const {
    order,
    user: { orders },
  } = state;

  const cachedInvoiceFormData = {
    userEmail: "",
    phoneNumber: "",
    userName: "",
  };
  if (orders.length > 0) {
    const cachedOrderData = orders[orders.length - 1];
    cachedInvoiceFormData.userName = cachedOrderData.userName || "";
    cachedInvoiceFormData.userEmail = cachedOrderData.userEmail || "";
    cachedInvoiceFormData.phoneNumber = cachedOrderData.phoneNumber || "";
  }

  return {
    location: order.location,
    userName: order.userName,
    userEmail: order.userEmail,
    phoneNumber: order.phoneNumber,
    cachedInvoiceFormData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserName: (userName) => dispatch(setUserName(userName)),
    setUserEmail: (userEmail) => dispatch(setUserEmail(userEmail)),
    setPhoneNumber: (phoneNumber) => dispatch(setPhoneNumber(phoneNumber)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InvoiceForm)
);
