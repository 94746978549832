import { connect } from "react-redux";
import Error from "../components/Error";
import history from "../history";

const mapStateToProps = (state, ownProps) => {
  const {
    errors: { error },
  } = state;

  const { critical } = ownProps;

  return {
    errorMessage: error && error.message,
    buttonLabelKey: critical ? "retryButtonLabel" : "backButtonLabel",
  };
};

// technically dispatch is not needed but it's used here to allow retries in the future
const mapDispatchToProps = (_, ownProps) => {
  const { critical } = ownProps;

  return {
    action: critical
      ? () => global.window.location.reload()
      : () => history.goBack(),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Error);
