import { connect } from "react-redux";
import MenulistItem from "../components/MenuListItem";
import { getUsableLocale } from "../lib/i18next";
import { venueStringsSelector } from "../redux/selectors/venue";
import { withRouter } from "react-router-dom";
import { logEvent } from "../lib/analytics";
import { EXPLORE_VIEW_MENU_ITEM_DETAIL } from "../lib/analytics/events";
import { getItemAvailability } from "../lib/hours";
import moment from "moment";

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { venueId },
    },
    itemId,
  } = ownProps;

  const {
    venues: {
      data: { [venueId]: venue },
    },
    order: { location },
  } = state;

  // localization and strings
  const locale = getUsableLocale();

  let venueStrings = venueStringsSelector(venue, locale);
  if (!venueStrings[itemId]) {
    venueStrings = venueStringsSelector(venue, venue.defaultLocale || "de");
  }
  const item = venue.menu && venue.menu.items[itemId];

  const itemAvailability = getItemAvailability(
    venue.openingHours,
    item,
    false,
    location,
    moment.tz(venue.openingHours.timeZone).format("HHmm")
  );
  let description = "";
  if (item.description) {
    description = venueStrings[item.description];
  } else if (item.shortDescription) {
    description = venueStrings[item.shortDescription];
  }
  return {
    venueId,
    itemId,
    trackingVenue: venue,
    name: venueStrings[itemId],
    description,
    itemAvailability,
    imageSrc: (item && item.images && item.images.default) || null,
    venueName: venue.strings[venue.defaultLocale][venueId],
    location,
  };
};

const mapDispatchToProps = (_, ownProps) => {
  const {
    match: {
      params: { venueId },
    },
    itemId,
    history,
  } = ownProps;

  return {
    handleItemCardClick: (venue) => {
      history.push(`/venues/${venueId}/menu/items/${itemId}`);
      logEvent(EXPLORE_VIEW_MENU_ITEM_DETAIL, {
        venueId,
        venueName: venue.strings[venue.defaultLocale][venueId],
        itemId,
        itemName: venue.strings[venue.defaultLocale][itemId]
          ? venue.strings[venue.defaultLocale][itemId]
          : venue.strings.de[itemId],
      });
    },
  };
};

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  handleItemCardClick: () =>
    dispatchProps.handleItemCardClick(stateProps.trackingVenue),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(MenulistItem)
);
