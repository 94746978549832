import { connect } from "react-redux";
import Menu from "../components/Menu";
import { getUsableLocale } from "../lib/i18next";
import { venueStringsSelector } from "../redux/selectors/venue";
import { updateLastSectionIndex } from "../redux/actions/ui";
import { getVenueOpenStatus } from "../lib/hours";
import { get } from "lodash";

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { venueId },
    },
    routeId,
  } = ownProps;

  const {
    venues: {
      data: { [venueId]: venue },
    },
    ui: { lastSectionIndex },
    order: { items: orderItems, tableId },
  } = state;

  // localization and strings
  const locale = getUsableLocale();
  const venueStrings = venueStringsSelector(venue, locale);

  return {
    menu: venue.menu,
    theme: venue.theme,
    strings: venueStrings,
    venueId,
    routeId,
    trackingVenue: venue,
    lastSectionIndex,
    countItems: orderItems.length,
    venueOpenStatus: getVenueOpenStatus(venue),
    heroImage: get(venue, ["theme", "logo", "images", "hero"], null),
    venueAddress: get(venue, ["location", "address"], null),
    venueCoordinates:
      get(venue, ["location", "coordinates", "latitude"], null) &&
      get(venue, ["location", "coordinates", "longitude"], null)
        ? get(venue, ["location", "coordinates"], null)
        : null,
    tableId: tableId || null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateLastSectionIndex: (index) => dispatch(updateLastSectionIndex(index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
