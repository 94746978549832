import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { getPriceDisplay } from "../../util/price-display";
import PriceCart from "../PriceCart";
import { PRICE_DISPLAY_DEFAULT } from "@orda/shared-constants-js/price-display";

const LEFT_PADDING = 15;

const styles = {
  itemDetailsSegmentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

const CartItemOptions = ({
  classes,
  amount,
  option,
  currency,
  priceDisplay,
  level,
}) => {
  if (!option) {
    return null;
  }

  let children;
  if (option.options) {
    if (option.options.map) {
      children = option.options.map((child) => (
        <CartItemOptions
          classes={classes}
          amount={amount}
          key={child.id}
          priceDisplay={getPriceDisplay(
            child.priceDisplay,
            option.priceDisplay,
            priceDisplay
          )}
          option={child}
          currency={currency}
          level={level + 1}
        />
      ));
    } else {
      children = (
        <CartItemOptions
          classes={classes}
          amount={amount}
          option={option.options}
          priceDisplay={getPriceDisplay(option.priceDisplay, priceDisplay)}
          currency={currency}
          level={level + 1}
        />
      );
    }
  }

  return (
    <>
      <div
        className={classes.itemDetailsSegmentContainer}
        style={{ paddingLeft: level * LEFT_PADDING }}
      >
        <div>
          <Typography variant="body2">{option.itemName}</Typography>
        </div>
        {option.priceBeforeBenefits > 0 && (
          <PriceCart
            hideFre
            priceBeforeBenefits={amount * option.priceBeforeBenefits}
            priceAfterBenefits={amount * option.priceAfterBenefits}
            priceDisplay={priceDisplay}
            remark={option.remark}
            currency={currency}
          />
        )}
      </div>
      {children}
    </>
  );
};

CartItemOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  amount: PropTypes.number.isRequired,
  option: PropTypes.object,
  currency: PropTypes.string.isRequired,
  priceDisplay: PropTypes.number,
  level: PropTypes.number.isRequired,
};

CartItemOptions.defaultProps = {
  option: null,
  priceDisplay: PRICE_DISPLAY_DEFAULT,
};

export default withStyles(styles)(CartItemOptions);
