import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PaymentOptionItem from "../components/PaymentOptionItem";

const mapStateToProps = () => {
  return (state) => {
    const {
      auth: { user: authUser, syncFinished: authSyncFinished },
      order: { location: orderLocation },
    } = state;

    return {
      authUser,
      authSyncFinished,
      orderLocation,
    };
  };
};

const mapDispatchToProps = (dispatch) => {
  return { dispatchAction: (action) => dispatch(action()) };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentOptionItem)
);
