import venues from "./venues";
import auth from "./auth";
import user from "./user";
import payments from "./payments";
import order from "./order";
import { all, fork } from "redux-saga/effects";

export default function* rootSaga() {
  yield all([
    fork(auth),
    fork(venues),
    fork(user),
    fork(payments),
    fork(order),
  ]);
}
