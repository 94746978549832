import { connect } from "react-redux";
import TipSelector from "../components/TipSelector";
import { setTip } from "../redux/actions/order";

const mapStateToProps = (state) => {
  const {
    order: { tip },
  } = state;

  return {
    tip,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTip: (tip) => dispatch(setTip(tip)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TipSelector);
