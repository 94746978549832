/* eslint-disable react/no-danger */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MenuListItem from "../containers/MenuListItem";
import Typography from "@material-ui/core/Typography";
import CheckoutBar from "../containers/CheckoutBar";
import LastOrderBar from "../containers/LastOrderBar";
import { logEvent } from "../lib/analytics";
import {
  EXPLORE_MENU_CLICK_SECTION_TAB,
  EXPLORE_MENU_SWIPE_TO_SECTION,
} from "../lib/analytics/events";
import classnames from "classnames";
import { getVenueClosedExplanation } from "../lib/hours";
import { withTranslation } from "react-i18next";
import { THEME } from "../theme";
import { ChevronLeft, ChevronRight } from "mdi-material-ui";
import { IconButton } from "@material-ui/core";
import VenueMenuHeader from "../containers/VenueMenuHeader";

const styles = {
  menuContainer: {
    display: "flex",
    backgroundColor: "#fafafa",
    width: "100%",
    height: "100vh",
    flexDirection: "column",
    overflowY: "auto",
  },
  checkoutMargin: {
    marginBottom: 80,
  },
  itemsContainer: {
    width: "100%",
    marginBottom: 45,
  },
  invert: {
    color: "white",
  },
  barsContainer: {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    maxWidth: THEME.MAX_SCREEN_WIDTH,
    width: "100%",
    bottom: 0,
  },
  closedNoticeContainer: {
    height: 80,
    display: "flex",
    padding: "0 30px",
    backgroundColor: "#ef5350",
    maxHeight: 80,
    alignItems: "center",
    justifyContent: "center",
  },
  noticeHtml: {
    fontFamily: "Open Sans",
    color: "black",
  },
  tab: {
    color: THEME.WHITE_TEXT_COLOR,
    fontWeight: "500",
    fontFamily: "Open Sans",
    fontSize: 13,
  },
  iconButton: {
    color: THEME.MAIN_GREEN,
    "&:hover": {
      backgroundColor: THEME.SECONDARY_BUTTON_ICON,
      opacity: 0.8,
    },
    borderRadius: 0,
    fontSize: "clamp(0.7rem, 3vmin, 1rem)",
  },
};

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      index: props.lastSectionIndex,
    };
    this.menuSectionsRef = React.createRef();
  }

  componentDidMount() {
    global.window.scroll(0, 0);
  }

  handleChange = (event, value) => {
    const { updateLastSectionIndex } = this.props;
    this.setState({
      index: value,
    });
    updateLastSectionIndex(value);
    window.scrollTo(0, 0);
  };

  handleChangeIndex = (index) => {
    const { trackingVenue, updateLastSectionIndex } = this.props;
    this.setState({
      index,
    });
    const sectionId = trackingVenue.menu.sectionOrder[index];
    logEvent(EXPLORE_MENU_SWIPE_TO_SECTION, {
      sectionId,
      sectionName:
        trackingVenue.strings[trackingVenue.defaultLocale][sectionId],
    });
    updateLastSectionIndex(index);
    window.scrollTo(0, 0);
  };

  renderNoticeHtml(sectionId) {
    const { menu, strings } = this.props;
    if (
      !menu ||
      !menu.sections ||
      !menu.sections[sectionId] ||
      !menu.sections[sectionId].noticeHtml
    ) {
      return null;
    }

    return (
      <div
        style={styles.noticeHtml}
        dangerouslySetInnerHTML={{
          __html: strings[menu.sections[sectionId].noticeHtml],
        }}
      />
    );
  }

  render() {
    const {
      t,
      menu,
      strings,
      classes,
      venueId,
      routeId,
      trackingVenue,
      countItems,
      venueOpenStatus,
    } = this.props;
    const { index } = this.state;
    return (
      <div
        className={classnames(classes.menuContainer, {
          [classes.checkoutMargin]: countItems > 0,
        })}
      >
        <VenueMenuHeader venueId={venueId} />
        <div
          style={{
            position: "sticky",
            top: 0,
            zIndex: 2,
          }}
          ref={this.menuSectionsRef}
        >
          <Tabs
            value={index}
            onChange={this.handleChange}
            variant="scrollable"
            style={{
              backgroundColor: THEME.SECONDARY_BUTTON_ICON,
              position: "sticky",
              top: 0,
              zIndex: 2,
            }}
            TabIndicatorProps={{
              style: { background: THEME.MAIN_GREEN },
            }}
            ScrollButtonComponent={(props) => {
              if (props.direction === "left" && props.visible) {
                return (
                  <IconButton style={styles.iconButton} {...props}>
                    <ChevronLeft />
                  </IconButton>
                );
              }
              if (props.direction === "right" && props.visible) {
                return (
                  <IconButton style={styles.iconButton} {...props}>
                    <ChevronRight />
                  </IconButton>
                );
              }
              return null;
            }}
          >
            {menu.sectionOrder.map((sectionId) => (
              <Tab
                key={sectionId}
                label={
                  strings[sectionId] ||
                  trackingVenue.strings[trackingVenue.defaultLocale][sectionId]
                }
                className={classes.tab}
                style={{
                  color:
                    trackingVenue.menu.sectionOrder[index] === sectionId
                      ? THEME.MAIN_GREEN
                      : THEME.WHITE_TEXT_COLOR,
                }}
                onClick={() => {
                  logEvent(EXPLORE_MENU_CLICK_SECTION_TAB, {
                    sectionId,
                    sectionName:
                      trackingVenue.strings[trackingVenue.defaultLocale][
                        sectionId
                      ],
                  });
                }}
              />
            ))}
          </Tabs>
        </div>
        <div key={index} className={classes.itemsContainer}>
          {menu.sections[trackingVenue.menu.sectionOrder[index]].items.map(
            (itemId) => (
              <MenuListItem key={itemId} itemId={itemId} />
            )
          )}
          {this.renderNoticeHtml(trackingVenue.menu.sectionOrder[index])}
        </div>
        <div className={classes.barsContainer}>
          {venueOpenStatus.closed && (
            <div className={classes.closedNoticeContainer}>
              <Typography variant="body2" className={classes.invert}>
                {getVenueClosedExplanation(
                  t,
                  venueOpenStatus,
                  strings[venueId]
                )}
              </Typography>
            </div>
          )}
          <CheckoutBar mountedAt={routeId} />
          <LastOrderBar />
        </div>
      </div>
    );
  }
}

Menu.propTypes = {
  t: PropTypes.func.isRequired,
  menu: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  venueId: PropTypes.string.isRequired,
  routeId: PropTypes.string.isRequired,
  trackingVenue: PropTypes.object.isRequired,
  lastSectionIndex: PropTypes.number.isRequired,
  updateLastSectionIndex: PropTypes.func.isRequired,
  countItems: PropTypes.number.isRequired,
  venueOpenStatus: PropTypes.object.isRequired,
};

export default withTranslation("ui-parts")(withStyles(styles)(Menu));
