/* eslint no-param-reassign: 0 */
import * as types from "../types";
import produce from "immer";

export const initialState = {
  error: null,
};

export default function errorsReducer(state = initialState, action) {
  switch (action.type) {
    case types.errors.INJECT_ERROR:
      return produce(state, (newState) => {
        newState.error = action.error;
      });
    default:
      return state;
  }
}
