import Option from "../components/Option";
import { connect } from "react-redux";
import { getUsableLocale } from "../lib/i18next";
import { venueStringsSelector } from "../redux/selectors/venue";
import { inflateOptions } from "../lib/options";
import { withRouter } from "react-router-dom";
import { createSelector } from "reselect";
import {
  addItemConfigurationEntry,
  removeItemConfigurationEntry,
} from "../redux/actions/item-configuration";

const optionSelector = createSelector(
  (venue) => venue,
  (_, itemId) => itemId,
  (_, __, locale) => locale,
  (venue, itemId, locale) => {
    const venueStrings = venueStringsSelector(venue, locale);
    return inflateOptions(
      venue,
      venue.menu.items[itemId].options,
      venueStrings
    );
  }
);

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { venueId },
    },
    itemId,
  } = ownProps;

  const {
    venues: {
      data: { [venueId]: venue },
    },
    order: { location: orderLocation },
  } = state;

  const locale = getUsableLocale();
  const venueStrings = venueStringsSelector(venue, locale);
  const option = optionSelector(venue, itemId, locale);
  const venueStringsFallover = venueStringsSelector(
    venue,
    venue.defaultLocale || "de"
  );

  const processMissingTranslation = (items) => {
    const { length: itemsLength } = items;
    for (let index = 0; index < itemsLength; index++) {
      const item = items[index];
      const { id = "", description = "" } = item;
      if (id && !venueStrings[id]) {
        venueStrings[id] = venueStringsFallover[id] || "";
      }
      if (description && !venueStrings[description]) {
        venueStrings[description] = venueStringsFallover[description] || "";
      }
    }
  };

  if (option && option.options && option.options.length > 0) {
    const { options } = option;
    const { length: optionsLength } = options;
    for (let index = 0; index < optionsLength; index++) {
      const { items = null } = options[index];
      if (items && items.length > 0) {
        processMissingTranslation(items);
      }
    }
  }

  return {
    option,
    itemId,
    venue,
    venueStrings,
    orderLocation,
    path: option ? [option.id] : [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  removeItemConfigurationEntry: (path) =>
    dispatch(removeItemConfigurationEntry(path)),
  addItemConfigurationEntry: (path, option) =>
    dispatch(addItemConfigurationEntry(path, option)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  removeItemConfigurationEntry: (path) =>
    dispatchProps.removeItemConfigurationEntry(path),
  addItemConfigurationEntry: (path) =>
    dispatchProps.addItemConfigurationEntry(path, stateProps.option),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(Option)
);
