import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InHouseCheckout from "../../components/InHousePayment/InHouseCheckout";
import {
  createAdyenPaymentSession,
  clearAdyenPaymentSession,
} from "../../redux/actions/payments";
import { posMessage as posMessageAction } from "../../redux/actions/venues";
import { REACT_APP_ADYEN_CLIENT_KEY } from "../../config";

const mapStateToProps = () => {
  return (state, ownProps) => {
    const {
      match: {
        params: { venueId },
      },
    } = ownProps;

    const {
      order,
      venues: {
        requests: { posMessage },
        data: { [venueId]: venue },
      },
      adyenPayment,
    } = state;
    return {
      order,
      venueId,
      posMessageState: posMessage,
      adyenPaymentState: adyenPayment,
      adyenClientKey:
        (venue.settings && venue.settings.adyenClientKey) ||
        REACT_APP_ADYEN_CLIENT_KEY,
    };
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createAdyenPaymentSession: (venueId, payload) =>
      dispatch(createAdyenPaymentSession(venueId, payload)),
    clearAdyenPaymentSession: () => dispatch(clearAdyenPaymentSession()),
    posMessage: (venueId, order) => dispatch(posMessageAction(venueId, order)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InHouseCheckout)
);
