import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { THEME } from "../theme";

const styles = {
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const Loading = ({ classes }) => (
  <div className={classes.root}>
    <CircularProgress
      size={40}
      thickness={5}
      style={{ color: THEME.MAIN_GREEN }}
    />
  </div>
);

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Loading);
