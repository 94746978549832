import React from "react";
import { THEME } from "../../theme";
import PropTypes from "prop-types";

const ClocheIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    x="0"
    y="0"
    viewBox="0 0 64 64"
    space="preserve"
  >
    <g fill={fill}>
      <g data-name="Layer 2">
        <path
          d="M42.1 50.16A3.19 3.19 0 0 0 45 47a3.24 3.24 0 0 0-2.8-3.21L29.13 42a1 1 0 0 0-.35 0l-8.89 2H12a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h5.84l11.84 4a1.19 1.19 0 0 0 .32 0 .47.47 0 0 0 .17 0l17-3a.93.93 0 0 0 .49-.23l7.79-6.92a2.89 2.89 0 0 0 .48-3.74A2.94 2.94 0 0 0 54 45.85a2.89 2.89 0 0 0-2.2.54l-6.23 4.66L34.16 53l-2.47-1.23.76-.76ZM11 32h42a21 21 0 0 0-17.63-20.7l.36-2.94a3 3 0 0 0-3-3.36h-1.5a3 3 0 0 0-3 3.36l.36 2.94A21 21 0 0 0 11 32Zm11-13.76a1 1 0 0 1 1.18 1.62 15 15 0 0 0-5.8 8.68 1 1 0 0 1-1 .77.91.91 0 0 1-.23 0 1 1 0 0 1-.74-1.2A17.06 17.06 0 0 1 22 18.24ZM6.79 35.71l4 4a1 1 0 0 0 .71.29h41a1 1 0 0 0 .71-.29l4-4A1 1 0 0 0 56.5 34h-49a1 1 0 0 0-.71 1.71Z"
          opacity="1"
          data-original="#000000"
        ></path>
      </g>
    </g>
  </svg>
);

ClocheIcon.propTypes = {
  fill: PropTypes.string,
};
ClocheIcon.defaultProps = {
  fill: THEME.WHITE_TEXT_COLOR,
};

export default ClocheIcon;
