import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { getCharacter } from "../lib/additives";
import {
  VENUE_LOCATION_EAT_IN,
  VENUE_LOCATION_TO_GO,
} from "@orda/shared-constants/order-locations";
import AndOption from "../containers/AndOption";
import OrOption from "../containers/OrOption";
import { getPriceDisplay } from "../util/price-display";
import { withTranslation } from "react-i18next";

const styles = {
  readOnlyLabel: {
    fontSize: "0.9rem",
    fontWeight: 700,
  },
  optionContainer: {
    borderBottom: "1px solid #cecece",
  },
  maxChildren: {
    fontSize: 11,
    fontFamily: "Open Sans",
  },
};

function isUnavailable(option, orderLocation) {
  if (!option) {
    return true;
  }

  if (option.unavailable) {
    return true;
  }

  switch (orderLocation) {
    case VENUE_LOCATION_EAT_IN:
      return option.notEatIn;
    case VENUE_LOCATION_TO_GO:
      return option.notToGo;
    default:
      return false;
  }
}

class Option extends React.Component {
  state = {
    expanded: false,
  };

  handleCheckedChange = (status, path) => {
    const { addItemConfigurationEntry, removeItemConfigurationEntry } =
      this.props;
    if (status) {
      addItemConfigurationEntry(path);
      this.setState({ expanded: true });
    } else {
      removeItemConfigurationEntry(path);
      this.setState({ expanded: false });
    }
  };

  render() {
    const { expanded } = this.state;
    const {
      t,
      option,
      orderLocation,
      benefits,
      configuredOptions,
      additives,
      venueStrings,
      disabled,
      path,
      padding,
      addItemConfigurationEntry,
      removeItemConfigurationEntry,
    } = this.props;

    if (!option) {
      return null;
    }

    const nameValue = venueStrings[option.id];
    const descriptionValue = venueStrings[option.description];

    let checked;
    let name = null;

    if (nameValue) {
      if (option.readOnly) {
        const displayValue = nameValue;
        let additiveValue = "";
        if (option.tags && option.tags.length > 0) {
          additiveValue += option.tags
            .map((tagId) => getCharacter(additives.indexOf(tagId)))
            .join(",");
          name = (
            <>
              <Typography>{name}</Typography>
              <Typography>{additiveValue}</Typography>
            </>
          );
        } else {
          name = (
            <Typography variant="overline" style={styles.readOnlyLabel}>
              {displayValue}
              {option.maxChildren > 0 && (
                <Typography style={styles.maxChildren}>
                  {`${t("ui-parts:maxChildren", {
                    maxChildren: option.maxChildren,
                  })}`}
                </Typography>
              )}
            </Typography>
          );
        }
      } else {
        checked = !!(configuredOptions && configuredOptions[option.id]);
        switch (option.type) {
          case "AND":
            name = (
              <AndOption
                label={nameValue}
                benefits={benefits}
                additives={additives}
                orderLocation={orderLocation}
                notToGo={option.notToGo}
                notEatIn={option.notEatIn}
                priceDisplay={option.priceDisplay}
                checked={checked}
                tags={option.tags}
                itemId={option.id}
                onCheckedChange={(optionPath) => (event) =>
                  this.handleCheckedChange(event.target.checked, optionPath)}
                path={path}
                unavailable={option.unavailable || disabled}
              />
            );
            break;
          case "OR":
            name = (
              <OrOption
                label={nameValue}
                benefits={benefits}
                additives={additives}
                orderLocation={orderLocation}
                notToGo={option.notToGo}
                notEatIn={option.notEatIn}
                priceDisplay={option.priceDisplay}
                checked={checked}
                onCheckedChange={() => this.handleCheckedChange(true, path)}
                tags={option.tags}
                itemId={option.id}
                value={option.id}
                path={path}
                unavailable={option.unavailable || disabled}
              />
            );
            break;
          default:
            throw new Error("unknown option type");
        }
      }
    }

    const description = descriptionValue ? (
      <Typography variant="body2">{descriptionValue}</Typography>
    ) : null;

    let children;
    switch (option.type) {
      case "AND":
        if (option.items) {
          const checkedOptions =
            (configuredOptions && configuredOptions[option.id]) || [];
          children = option.items.map((item) => (
            <AndOption
              checked={checkedOptions.includes(item.id)}
              onCheckedChange={(optionPath) => (event) =>
                this.handleCheckedChange(event.target.checked, optionPath)}
              key={item.id}
              additives={additives}
              tags={item.tags}
              description={venueStrings[item.description]}
              benefits={benefits}
              orderLocation={orderLocation}
              notToGo={item.notToGo}
              notEatIn={item.notEatIn}
              priceDisplay={getPriceDisplay(
                item.priceDisplay,
                option.priceDisplay
              )}
              itemId={item.id}
              label={item.name}
              path={path.concat(item.id)}
              unavailable={
                (option.maxChildren &&
                  !checkedOptions.includes(item.id) &&
                  checkedOptions.length >= option.maxChildren) ||
                item.unavailable ||
                option.unavailable ||
                disabled
              }
            />
          ));
        } else {
          children = option.options.map((opt, index) => (
            <Option
              t={t}
              key={opt.id}
              option={opt}
              additives={additives}
              benefits={benefits}
              orderLocation={orderLocation}
              priceDisplay={getPriceDisplay(
                opt.priceDisplay,
                option.priceDisplay
              )}
              venueStrings={venueStrings}
              disabled={disabled || isUnavailable(option, orderLocation)}
              configuredOptions={
                configuredOptions &&
                configuredOptions[option.id] &&
                configuredOptions[option.id][index]
              }
              path={path.concat([index, opt.id])}
              addItemConfigurationEntry={addItemConfigurationEntry}
              removeItemConfigurationEntry={removeItemConfigurationEntry}
            />
          ));
        }
        break;
      case "OR":
        if (option.options) {
          const childOptions =
            (configuredOptions && configuredOptions[option.id]) || {};
          children = (
            <div>
              {option.options.map((opt, index) => (
                <Option
                  t={t}
                  key={opt.id}
                  option={opt}
                  benefits={benefits}
                  additives={additives}
                  venueStrings={venueStrings}
                  orderLocation={orderLocation}
                  priceDisplay={getPriceDisplay(
                    opt.priceDisplay,
                    option.priceDisplay
                  )}
                  disabled={disabled || isUnavailable(option, orderLocation)}
                  padding={padding + 10}
                  configuredOptions={
                    childOptions.index === index ? childOptions.item : null
                  }
                  path={path.concat([index, opt.id])}
                  addItemConfigurationEntry={addItemConfigurationEntry}
                  removeItemConfigurationEntry={removeItemConfigurationEntry}
                />
              ))}
            </div>
          );
        } else {
          const checkedOption =
            configuredOptions && configuredOptions[option.id];
          children = (
            <div>
              {option.items.map((item) => (
                <OrOption
                  checked={checkedOption === item.id}
                  onCheckedChange={(event) => {
                    this.handleCheckedChange(
                      true,
                      path.concat([event.target.value])
                    );
                  }}
                  key={item.id}
                  value={item.id}
                  benefits={benefits}
                  additives={additives}
                  venueStrings={venueStrings}
                  orderLocation={orderLocation}
                  description={venueStrings[item.description]}
                  tags={item.tags}
                  notToGo={item.notToGo}
                  notEatIn={item.notEatIn}
                  priceDisplay={getPriceDisplay(
                    item.priceDisplay,
                    option.priceDisplay
                  )}
                  label={item.name}
                  itemId={item.id}
                  path={path.concat([item.id])}
                  unavailable={
                    item.unavailable ||
                    option.unavailable ||
                    disabled ||
                    isUnavailable(item, orderLocation)
                  }
                />
              ))}
            </div>
          );
        }
        break;
      default:
        throw new Error("Options requires the type information!");
    }

    const showChildren = expanded || option.expanded || checked;

    let containerStyle = {};
    if (option.highlighted) {
      containerStyle = styles.optionContainer;
    }
    return (
      <div key={option.id} style={containerStyle}>
        {name}
        {!!description && (
          <Typography variant="body2">{description}</Typography>
        )}
        <div style={{ paddingLeft: padding }}>{showChildren && children}</div>
      </div>
    );
  }
}

Option.propTypes = {
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  padding: PropTypes.number,
  option: PropTypes.object,
  benefits: PropTypes.array.isRequired,
  additives: PropTypes.array.isRequired,
  path: PropTypes.array.isRequired,
  orderLocation: PropTypes.number.isRequired,
  configuredOptions: PropTypes.object,
  venueStrings: PropTypes.object.isRequired,
  addItemConfigurationEntry: PropTypes.func.isRequired,
  removeItemConfigurationEntry: PropTypes.func.isRequired,
};

Option.defaultProps = {
  disabled: false,
  padding: 0,
  option: null,
  configuredOptions: null,
};
export default withTranslation("ui-parts")(Option);
