import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { THEME } from "../../theme";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import "./adyen.css";
import { Close } from "mdi-material-ui";
import Loading from "../Loading";
import classNames from "classnames";
import { AdyenPaymentResultCode, PosMessageType } from "../../constants";

const styles = {
  root: {},
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  payment: {
    width: "100%",
  },
  inHousePaymentTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inHousePaymentDialogClose: {
    color: THEME.MAIN_GREEN,
  },
  title: {
    fontSize: 20,
    color: THEME.PRIMARY_TEXT_COLOR,
    wordBreak: "break-all",
  },
  alertMessage: {
    textAlign: "center",
    fontWeight: "bold",
  },
  errorMessage: {
    color: THEME.ACCENT_RED,
  },
  successMessage: {
    color: THEME.DARKER_MAIN_GREEN,
  },
};
const InHouseCheckout = ({
  t,
  classes,
  open,
  setOpen,
  order,
  createAdyenPaymentSession,
  clearAdyenPaymentSession,
  posMessage,
  posMessageState,
  adyenPaymentState,
  venueId,
  adyenClientKey,
}) => {
  const [checkoutState, setCheckoutState] = useState({
    success: false,
    error: null,
  });
  const paymentContainer = useRef(null);

  useEffect(() => {
    if (order.tableId) {
      posMessage(venueId, {
        order,
        type: PosMessageType.PaymentRequest,
      });
    }
  }, []);

  useEffect(() => {
    if (
      posMessageState.success &&
      posMessageState.result.type === PosMessageType.PaymentRequest &&
      posMessageState.result.target &&
      posMessageState.result.target.positions
    ) {
      createAdyenPaymentSession(venueId, {
        order: {
          ...order,
          items: posMessageState.result.target.positions,
        },
      });
    }
  }, [posMessageState.success]);
  useEffect(() => {
    const {
      config,
      session,
      orderRef,
      amount = null,
      error: errorAdyen,
    } = adyenPaymentState;
    let ignore = false;

    if (errorAdyen && errorAdyen.message) {
      setCheckoutState({
        ...checkoutState,
        error: errorAdyen.message,
        success: false,
      });
      return undefined;
    }
    if (!session && !posMessageState.success) {
      return undefined;
    }
    async function createAdyenCheckout() {
      try {
        const checkout = await AdyenCheckout({
          ...config,
          clientKey: adyenClientKey,
          session,
          onPaymentCompleted: (result) => {
            setCheckoutState({
              ...checkoutState,
              success: result.resultCode === AdyenPaymentResultCode.Authorised,
              error:
                result.resultCode === AdyenPaymentResultCode.Error
                  ? result.resultCode
                  : null,
            });
            if (result.resultCode === AdyenPaymentResultCode.Authorised) {
              posMessage(venueId, {
                order,
                payment: {
                  ...result,
                  orderRef,
                  amount,
                },
                type: PosMessageType.PaymentRequest,
              });
              clearAdyenPaymentSession();
            }
          },
          onError: (error) => {
            setCheckoutState({
              ...checkoutState,
              error,
              success: false,
            });
          },
          instantPaymentTypes: ["applepay", "googlepay"],
          showPayButton: true,
        });
        if (paymentContainer.current && !ignore) {
          checkout.create("dropin").mount(paymentContainer.current);
        }
      } catch (error) {
        setCheckoutState({ ...checkoutState, error, success: false });
      }
    }
    createAdyenCheckout();
    return () => {
      ignore = true;
    };
  }, [adyenPaymentState]);
  const handleClose = () => {
    clearAdyenPaymentSession();
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="md"
      aria-labelledby="adyen-checkout-dialog"
    >
      <DialogTitle id="adyen-checkout-dialog">
        <div className={classes.inHousePaymentTitle}>
          <Typography variant="body2" className={classes.title}>
            {t("title")}
          </Typography>
          <IconButton
            className={classes.inHousePaymentDialogClose}
            onClick={() => handleClose()}
          >
            <Close fontSize="small" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {!!checkoutState.error && (
          <Typography
            variant="body2"
            className={classNames(classes.alertMessage, classes.errorMessage)}
          >
            {t("checkoutError")}
          </Typography>
        )}
        {checkoutState.success && (
          <Typography
            variant="body2"
            className={classNames(classes.alertMessage, classes.successMessage)}
          >
            {t("checkoutSuccess")}
          </Typography>
        )}
        {adyenPaymentState.session ? (
          <div ref={paymentContainer} className={classes.payment}></div>
        ) : (
          <Loading />
        )}
      </DialogContent>
    </Dialog>
  );
};

InHouseCheckout.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  venueId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.any.isRequired,
  order: PropTypes.any.isRequired,
  posMessageState: PropTypes.object.isRequired,
  adyenPaymentState: PropTypes.object.isRequired,
  createAdyenPaymentSession: PropTypes.any.isRequired,
  clearAdyenPaymentSession: PropTypes.any.isRequired,
  posMessage: PropTypes.any.isRequired,
  adyenClientKey: PropTypes.string.isRequired,
};
export default withTranslation("inHouseCheckout")(
  withStyles(styles)(InHouseCheckout)
);
