import { Allergen } from "@orda/shared-constants/allergens";
import { VenueTag, FoodPreference } from "@orda/shared-constants/preferences";
import { CALL_WAITER_REASON_TYPE } from "../constants";

export default {
  common: {
    number: "{{data, number}}",
    money: "{{data, money}}",
    percent: "{{data, percent}}",
    "time-llll": "{{time, time-llll}}",
    timeTimed: "{{time, orderTime}}",
    duration: "{{duration, duration}}",
  },
  nativeUpsellBanner: {
    header: "Welcome to {{venueName}}.",
    mode: "You are currently ordering for {{mode}}.",
    toGo: "To-Go",
    dineIn: "Eat In",
    delivery: "Delivery",
    text: "Download the <1>ORDA app</1> and unlock more features.",
  },
  checkoutBar: {
    sumTotalLabel: "Total",
    countLabel: "Count",
    addToCart: "Add to Cart",
    viewCart: "View Cart",
    oneClickOrder: "Order now",
  },
  cart: {
    title: "Cart",
    subTotalLabel: "Subtotal",
    feesLabel: "Fees",
    totalLabel: "Total",
    benefitLabel: "Discount",
    tipLabel: "Tip ({{tip, percent}})",
    addPaymentMethodButtonLabel: "Select Payment Method",
    tip: "Would you like to tip the team?",
    orderButtonLabel: "Place Order",
    termsNotice:
      "By placing an order you are accepting the <1>Terms & Conditions</1>.",
    retryButtonLabel: "Retry",
    paymentMethodLabel: "Payment Method",
    agbs: "<1>Terms & Conditions</1>",
    privacy: "<1>Privacy</1>",
    noteLabel: "Note",
    toGo: "To-Go",
    dineIn: "Eat In",
    delivery: "Delivery",
    modes: "Takeaway, Dine-In or Delivery?",
    itemPreOrder: "Pre-order for: ",
    toGoNow: "ASAP",
    timedNow: "ASAP",
    timedValue: "{{time, orderTime}}",
    emailLabel: "Email",
    invoiceHelpLabel: "For invoicing/contact by restaurant: ",
    validEmailErrorLabel: "Please enter a valid email address.",
    validPhoneNumberErrorLabel: "Please enter a valid phonenumber.",
    orderTime: "Order Time",
    preOrderTime: "Preorder",
    toGoPreOrderTime: "Pick up time:",
    deliveryPreOrderTime: "Delivery time:",
    dineInPreOrderTime: "Reservation time:",
    asapNotAvailable:
      "Some items in your cart are only available for preorder!",
    minimumBasketNotReached: "Minimum Value for ordering {{minimumBasket}}",
    creditCardLabel: "Credit card",
    paypalLabel: "PayPal",
    applePayLabel: "Apple Pay",
    googlePayLabel: "Google Pay",
    cashOnDeliveryLabel: "Cash on delivery",
    deliveryFee: "Delivery fee",
  },
  paypalButton: {
    errorValidation: "Please make sure that all inputed data is valid.",
  },
  addressForm: {
    nameLabel: "Name",
    phoneNumberLabel: "Phone Number",
    additionalInfoLabel: "Additional information",
    postalCodeLabel: "Postal Code",
    cityLabel: "City",
    emailLabel: "Email",
    streetNumberLabel: "Street Number",
    streetLabel: "Street",
    enterInfo: "Please fill in your informations:",
    requiredStringErrorLabel: "Required.",
    requiredNumberErrorLabel: "Required (only digits allowed).",
    validEmailErrorLabel: "Please enter a valid email address.",
    validPhoneNumberErrorLabel: "Please enter a valid phonenumber.",
    addressOutOfRange:
      "Address is outside the delivery area, delivery not possible!",
    confirmAddress: "Check again",
    invoiceHelpLabel: "For invoicing/contact by restaurant: ",
  },
  lastOrderBar: {
    lastOrderLabel: "Your last order:",
    // eslint-disable-next-line @typescript-eslint/camelcase
    lastOrderLabel_plural: "Your last orders:",
  },
  menuItemDetail: {
    additionalInfoLabel: "Further information:",
    sumTotalLabel: "Total:",
    addToBasketButton: "Add to cart",
    noteLabel: "Note",
    notesDisabled:
      "The option to add notes has been disabled by {{venueName}}.",
    notesDisclaimer:
      "{{venueName}} cannot guarantee the fulfillment of extra wishes. Chargeable options cannot be considered.",
    noteTextInputPlaceholder: "e.g. without Mustard",
    iosNoteError: "Invalid character entered: ",
  },
  additives: {
    [FoodPreference.HighProtein]: "High Protein",
    [FoodPreference.LowCarb]: "Low Carb",
    [FoodPreference.Spicy]: "Spicy",
    [FoodPreference.NotSpicy]: "Not spicy",
    [FoodPreference.Vegan]: "Vegan",
    [FoodPreference.Vegetarian]: "Vegetarian",

    [Allergen.Gluten]: "Gluten",
    [Allergen.Crustaceans]: "Crustaceans",
    [Allergen.Eggs]: "Eggs",
    [Allergen.Fish]: "Fish",
    [Allergen.Peanuts]: "Peanuts",
    [Allergen.Soy]: "Soybeans",
    [Allergen.Lactose]: "Lactose",
    [Allergen.Nuts]: "Nuts",
    [Allergen.Celery]: "Celery",
    [Allergen.Mustard]: "Mustard",
    [Allergen.Sesame]: "Sesame",
    [Allergen.Sulfite]: "Sulfites",
    [Allergen.Lupins]: "Lupin",
    [Allergen.Molluscs]: "Molluscs",
    [Allergen.Preservative]: "Preservative",
    [Allergen.Colorant]: "Colorant",
    [Allergen.Antioxidant]: "Antioxidant",
    [Allergen.Saccharin]: "Saccharin",
    [Allergen.Cyclamate]: "Cyclamate",
    [Allergen.AspartamePhenylalanineSource]:
      "Aspartame, contain. Phenylalanine source",
    [Allergen.Acesulfame]: "Acesulfame",
    [Allergen.Phosphate]: "Phosphate",
    [Allergen.Sulfurized]: "Sulfurized",
    [Allergen.Quinine]: "Quinine",

    [VenueTag.Turkish]: "Turkish",
    [VenueTag.Grill]: "Grill",
    [VenueTag.Chinese]: "Chinese",
    [VenueTag.Doner]: "Doner",
    [VenueTag.Bar]: "Bar",
    [VenueTag.Drinks]: "Drinks",
    [VenueTag.SoulFood]: "Soul Food",
    [VenueTag.Syrian]: "Syrian",
    [VenueTag.Shawarma]: "Shawarma",
    [VenueTag.Falafel]: "Falafel",
    [VenueTag.PokeBowls]: "Poké Bowls",
    [VenueTag.Fish]: "Fish",
    [VenueTag.Vietnamese]: "Vietnamese",
    [VenueTag.Indian]: "Indian",
    [VenueTag.Curry]: "Curry",
    [VenueTag.Burger]: "Burger",
    [VenueTag.American]: "American Food",
    [VenueTag.Mexican]: "Mexican",
    [VenueTag.Burrito]: "Burrito",
    [VenueTag.Taco]: "Taco",
    [VenueTag.Bowl]: "Bowl",
    [VenueTag.Asian]: "Asian",
    [VenueTag.FusionCuisine]: "Fusion Cuisine",
    [VenueTag.Sushi]: "Sushi",
    [VenueTag.Breakfast]: "Breakfast",
    [VenueTag.Brunch]: "Brunch",
    [VenueTag.HealtyFood]: "Healty Food",
    [VenueTag.Pinsa]: "Pinsa",
    [VenueTag.Lunch]: "Lunch",
    [VenueTag.Salads]: "Salads",
    [VenueTag.Bowls]: "Bowls",
    [VenueTag.Bagels]: "Bagels",
    [VenueTag.Muffins]: "Muffins",
    [VenueTag.CoffeeShop]: "Café",
    [VenueTag.FitnessFood]: "Fitness Food",
    [VenueTag.Fries]: "Fries",
    [VenueTag.Cocktails]: "Cocktails",
    [VenueTag.Pizza]: "Pizza",
    [VenueTag.Tacos]: "Tacos",
    [VenueTag.Burritos]: "Burritos",
    [VenueTag.Thai]: "Thai Food",
    Sy6w1tRzb: "Vegetarian",

    containsLabel: "Contains {{additive}}",
    warnLabel: "Warning: {{additive}}",
  },
  "ui-parts": {
    notEatIn: "(to-go only)",
    notToGo: "(for dine-in only)",
    defaultDisabledNotice: "(temporarily unavailable)",
    freeLabel: "Free",
    backButton: "back",
    startingFromPrefix: "from ",
    venueClosed:
      "is currently closed. Orders are possible again starting at {{nextAvailable, calendar}}",
    venueClosedWithName:
      "{{venueName}} is currently closed. Orders are possible again starting at {{nextAvailable, calendar}}",
    itemNotEatIn: "can only be ordered to-go.",
    itemNotEatInWithName: "{{itemName}} can only be ordered to-go.",
    itemNotToGo: "can only be ordered for dine-in.",
    itemNotToGoWithName: "{{itemName}} can only be ordered to for dine-in.",
    itemNotAvailableUntil:
      "can be ordered again starting at {{nextAvailable, calendar}}.",
    itemNotAvailableUntilWithName:
      "{{itemName}} can be ordered again starting at {{nextAvailable, calendar}}.",
    itemNotAvailable: "is currently unavailable.",
    itemNotAvailableWithName: "{{itemName}} is currently unavailable.",
    sectionNotAvailable: "Unavailable",
    venueClosedNotice:
      "{{venueName}} is closed until {{nextOpenTime, calendar}}.",
    venueClosedExtendedNotice: "{{venueName}} is currently closed.",
    venueOfflineNotice:
      "{{venueName}} is currently not available. Please get in touch with the staff there.",
    lastOrderLabel: "Your last ORDA:",
    subTotalLabel: "Subtotal",
    paypalLabel: "PayPal",
    applePayLabel: "Apple Pay",
    googlePayLabel: "Google Pay",
    appLinkLabel: "ORDA app",
    downloadAppFragment1: "Use the ",
    downloadAppFragment2: "  and enjoy more benefits!",
    maxChildren: "(maximum {{maxChildren}})",
    closed: "Closed",
  },
  errors: {
    generic: "Whoops 🙈\n\nAn error has occurred. Please try again.",
    network:
      "An error occurred during the request to the server. Please check your internet connection and try again.",
  },
  creditCardForm: {
    cardNumber: "Card number",
    validUntil: "Expiration (MM/YY)",
    cvc: "CVC",
    orderButtonLabel: "Place Order",
    continueButtonLabel: "Continue",
    poweredByStripe: "Powered By Stripe",
    termsNotice:
      "By placing an order you are accepting the <1>Terms & Conditions</1>.",
  },
  addPaymentMethod: {
    explanationText: "Select a payment method:",
    creditCardLabel: "Credit card",
    paypalLabel: "PayPal",
    termsNotice:
      "By placing an order you are accepting the <1>Terms & Conditions</1>.",
  },
  paymentOptionItem: {
    creditCardLabel: "Credit card",
    paypalLabel: "PayPal",
    applePayLabel: "Apple Pay",
    googlePayLabel: "Google Pay",
    cashOnDeliveryLabel: "Cash on delivery",
    cardOnDeliveryLabel: "Cash on delivery",
  },
  securityNotice: {
    text: "Your payment will be processed securely by our payment processing partners.",
  },
  dataProtection: {
    agbs: "Terms & Conditions",
    privacy: "Privacy",
  },
  error: {
    // this is the error page
    retryButtonLabel: "Retry",
    backButtonLabel: "Back",
  },
  orderSuccess: {
    orderNumberLabel: "Your ORDA number:",
    messageOrderConfirmed: "Your order has been placed.",
    explanationTable:
      "Once your order is ready, the service staff will bring it to your table.",
    warning: "Important:",
    tableNewOrderWarning:
      "If you would like to place another order, please scan the QR code again. Only then will your table number be added to the order.",
    explanationPushNotificationEatIn:
      "Once your order is ready, the service staff will bring it to your table.",
    explanationPushNotificationToGoNow:
      "Please pick-up your food at {{time, time-hhmm}} at {{venueName}}.",
    explanationPushNotificationToGoTimed:
      "Please be at {{venueName}} between {{time, orderTime}} to pick up your order.",
    explanationPushNotificationDeliveryTimed:
      "Your order at {{venueName}} will be delivered to you between approx. {{time, orderTime}}.",
    messageEnjoy: "Enjoy 😋",
    deliveryAddressLabel: "Delivery address: {{deliveryAddress}}",
    phoneNumberLabel: "Phonenumber: {{phoneNumber}}",
    fullNameLabel: "Fullname: {{fullName}}",
    avgDeliveryTimeLabel:
      "Your order at {{venueName}} will be delivered to you in approx. {{avgTime}} minutes.",
    deliveryAdditionalInformationLabel:
      "Additional Information: {{deliveryAdditionalInformation}}",
    backButtonLabel: "Back",
    toGoNow: "ASAP",
  },
  errorNotFound: {
    header: "Oops 🙈",
    errorText: "There was an error processing your request.",
    retryText:
      "Please try scanning the QR code again or if the problem persists please contact support.",
  },
  burgerMenu: {
    downloadApp: "Download App",
    imprint: "Imprint",
    restaurantInfo: "Restaurant Info",
  },
  venueInfoModal: {
    currency: "Currency:",
    venueAddress: "Restaurant address:",
    orderLocations: "Ordering options:",
    toGo: "To-Go",
    dineIn: "Eat In",
    delivery: "Delivery",
    prepTimeLabel: "Average preparation time:",
    prepTime: "{{- prepTime}} min",
    phoneNumber: "Phonenumber:",
    openingHours: "Opening Hours:",
  },
  downloadAppModal: {
    title: "USE THE ORDA APP",
    content: "AND ENJOY MORE BENEFITS",
    buttonLabel: "GET APP",
  },
  googleDeliveryForm: {
    checkAddress: "Enter delivery address",
    addressPlaceholder: "Street and house number, city and postal code",
    addressOutOfRange: "Address out of range, delivery not possible",
    errorStreetNumber: "Please enter the street along with the street number.",
    confirmAddress: "Use this address as delivery address",
    correctAddress: "Your address is in our delivery area.",
    myLocation: "Current location button.",
  },
  startScreen: {
    title: "Welcome to {{venueName}}!",
    subTitle: "Welcome! Make your choice.",
    callWaiter: "Call staff",
    orderNow: "Menu",
    pay: "Pay",
    posMessageError:
      "An error has occurred. Please try again or contact the staff directly.",
    posMessageSuccess: "The staff were successfully informed.",
  },
  callWaiterModal: {
    callWaiterTitle: "Call staff",
    callWaiterReason: "Please state the reason for calling the staff.",
    customerInfoPlaceholder: "Comments for staff.",
    callWaiterButtonLabel: "Send",
    [CALL_WAITER_REASON_TYPE.Order]: "Order",
    [CALL_WAITER_REASON_TYPE.Pay]: "Pay",
    [CALL_WAITER_REASON_TYPE.Other]: "Other",
  },
  inHousePayment: {
    title: "Order overview",
    sumTotalLabel: "Total:",
    payButtonLabel: "Pay {{sumTotal}}",
    noPositions: "No open positions found.\n\nPlease contact the staff.",
    posMessageError:
      "Error handling your request.\nPlease try again or contact staff directly.",
  },
  inHouseCheckout: {
    title: "Checkout",
    checkoutError: "Error while trying to pay.\nPlease contact the staff.",
    checkoutSuccess: "Your payment was successful!",
    redirectMessage: "Redirecting...",
  },
};
