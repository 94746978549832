import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { THEME } from "../../theme";

const styles = {
  calculationsItemContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  calculationLabelBold: {
    fontWeight: 600,
  },
  calculationValueBold: {
    fontWeight: 600,
  },
  minimumBasketLabel: {
    color: THEME.ACCENT_RED,
    textAlign: "center",
    paddingTop: "1rem",
    fontWeight: "bold",
  },
};

const formatFees = (t, fees, locale) => {
  let result = t("feesLabel");
  if (fees && fees.length > 0) {
    result += " (";
    result += fees.map(({ strings }) => strings[locale]).join(", ");
    result += ")";
  }
  return result;
};

const CartCalculations = ({
  t,
  classes,
  currency,
  locale,
  sumBeforeEverything,
  sumBenefit,
  sumBenefitSum,
  fees,
  feeSum,
  sumTotal,
  tip,
  tipSum,
  showMinimumBasketNotReachedError,
  minimumBasket,
  totalBenefit,
}) => (
  <>
    <div className={classes.calculationsItemContainer}>
      <Typography variant="body2">{t("subTotalLabel")}</Typography>
      <Typography variant="body2">
        {t("common:money", {
          data: {
            currency,
            value: sumBeforeEverything,
          },
        })}
      </Typography>
    </div>
    {sumBenefit && (
      <div className={classes.calculationsItemContainer}>
        <Typography variant="body2">{t("subTotalLabel")}</Typography>
        <Typography variant="body2">
          {t("common:money", {
            data: {
              currency,
              value: sumBenefitSum,
            },
          })}
        </Typography>
      </div>
    )}
    {(tip || tip === 0) && (
      <div className={classes.calculationsItemContainer}>
        <Typography variant="body2">{t("tipLabel", { tip })}</Typography>
        <Typography variant="body2">
          {t("common:money", {
            data: {
              currency,
              value: tipSum,
            },
          })}
        </Typography>
      </div>
    )}
    {fees && (
      <div className={classes.calculationsItemContainer}>
        <Typography variant="body2">{formatFees(t, fees, locale)}</Typography>
        <Typography variant="body2">
          {t("common:money", {
            data: {
              currency,
              value: feeSum,
            },
          })}
        </Typography>
      </div>
    )}
    {totalBenefit > 0 && (
      <div className={classes.calculationsItemContainer}>
        <Typography variant="body2">{t("benefitLabel")}</Typography>
        <Typography variant="body2">
          {t("common:money", {
            data: {
              currency,
              value: totalBenefit || 0,
            },
          })}
        </Typography>
      </div>
    )}
    {(sumTotal || sumTotal === 0) && (
      <div className={classes.calculationsItemContainer}>
        <Typography variant="body2" className={classes.calculationLabelBold}>
          {t("totalLabel")}
        </Typography>
        <Typography variant="body2" className={classes.calculationValueBold}>
          {t("common:money", {
            data: {
              currency,
              value: sumTotal,
            },
          })}
        </Typography>
      </div>
    )}
    {showMinimumBasketNotReachedError && !!minimumBasket && (
      <Typography variant="body2" className={classes.minimumBasketLabel}>
        {t("minimumBasketNotReached", { minimumBasket })}
      </Typography>
    )}
  </>
);

CartCalculations.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  currency: PropTypes.string,
  sumBeforeEverything: PropTypes.number,
  sumBenefit: PropTypes.bool,
  sumBenefitSum: PropTypes.number,
  fees: PropTypes.array,
  feeSum: PropTypes.number,
  sumTotal: PropTypes.number,
  tip: PropTypes.number,
  tipSum: PropTypes.number,
  showMinimumBasketNotReachedError: PropTypes.bool,
  minimumBasket: PropTypes.any.isRequired,
  totalBenefit: PropTypes.number,
};

CartCalculations.defaultProps = {
  currency: "EUR",
  sumBeforeEverything: null,
  sumBenefit: null,
  sumBenefitSum: null,
  fees: null,
  feeSum: null,
  sumTotal: null,
  tip: null,
  tipSum: null,
  totalBenefit: 0,
  showMinimumBasketNotReachedError: false,
};

export default withTranslation("cart")(withStyles(styles)(CartCalculations));
