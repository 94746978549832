import { createSelector } from "reselect";
import { get } from "lodash";

export const venueStringsSelector = createSelector(
  (venue) => venue,
  (_, locale) => locale,
  (venue, locale) => {
    if (venue && locale && venue.strings && venue.strings[locale]) {
      return {
        ...venue.strings[venue.defaultLocale],
        ...venue.strings[locale],
      };
    }
    return get(venue, ["strings", venue ? venue.defaultLocale : "de"], {});
  }
);
