import {
  expandBenefitsByIndex,
  getUsableBenefits,
  mergeUsedBenefits,
  updateUsedBenefits,
} from "@orda/shared-functions/benefits";
import { VENUE_LOCATION_TO_GO } from "@orda/shared-constants/order-locations";
import { inflateOptions, inflateOrderOptionsNew } from "./options";

import { calculateItemBenefits } from "@orda/shared-functions-js/lib/price";
import { calculatePrice as calculatePriceOptions } from "@orda/shared-functions-js/lib/options";
import hash from "object-hash";
import isEqual from "lodash/isEqual";
import sha1 from "sha1";

/**
 * Finding the correspondent section by the item id
 *
 * @param  {string} id of the item
 * @param  {arrayOf sections} sections
 *
 * @returns {
 *  object // ** Founded Section
 * }
 */
function findSection(id, sections) {
  const foundedSection = Object.values(sections).find(
    (section) => section.items && section.items.includes(id)
  );
  return foundedSection && foundedSection[0];
}

/**
 * Calculate the price from all the options
 *
 * @param  {object} expandedOptions
 *
 * @returns {
 *  number // ** Calculated Price of all options
 * }
 */
function calculateOptionsPrice(expandedOptions) {
  if (!expandedOptions) {
    return 0;
  }

  let price = expandedOptions.priceAfterBenefitsRaw;
  if (expandedOptions.options) {
    if (expandedOptions.options.reduce) {
      price += expandedOptions.options.reduce(
        (prev, option) => prev + calculateOptionsPrice(option),
        0
      );
    } else {
      price += calculateOptionsPrice(expandedOptions.options);
    }
  }

  return price;
}

/**
 * Fill the remarks
 *
 * @param  {} expandedOptions
 * @param  {} remarks
 *
 * @returns {void}
 */
function updateOrderOptionsRemarks(expandedOptions, remarks) {
  if (!expandedOptions) {
    return;
  }

  if (expandedOptions.remark) {
    // eslint-disable-next-line no-param-reassign
    expandedOptions.remark = remarks.indexOf(expandedOptions.remark) + 1;
  }

  if (expandedOptions.options) {
    if (expandedOptions.options.forEach) {
      expandedOptions.options.forEach((option) => {
        updateOrderOptionsRemarks(option, remarks);
      });
    } else {
      updateOrderOptionsRemarks(expandedOptions.options, remarks);
    }
  }
}

/**
 *
 *
 * @param  {} rawOrderOptions
 * @param  {} venue
 * @param  {} venueStrings
 * @param  {} usableBenefits
 * @param  {} usedBenefits
 * @param  {} remarks
 * @param  {} userLocale
 * @param  {} orderLocation
 */
function expandOrderOptions(
  rawOrderOptions,
  venue,
  venueStrings,
  usableBenefits,
  usedBenefits,
  remarks,
  userLocale,
  orderLocation
) {
  if (!rawOrderOptions) {
    return null;
  }

  return inflateOrderOptionsNew(
    rawOrderOptions,
    venue,
    venueStrings,
    usableBenefits,
    usedBenefits,
    remarks,
    userLocale,
    orderLocation
  );
}

/**
 *
 *
 * @param  {} orderItems
 * @param  {} venue
 * @param  {} venueStrings
 * @param  {} benefits
 * @param  {} expandableBenefits
 * @param  {} locale
 * @param  {} orderLocation
 */
export function groupItems(
  orderItems,
  venue,
  venueStrings,
  benefits,
  expandableBenefits,
  locale,
  orderLocation
) {
  const groupedOrders = {};
  const remarks = {};
  const {
    menu: { items },
  } = venue;

  const menuItems = orderItems.map((item) => {
    const menuItem = {
      id: item.itemId,
      item: items[item.itemId],
      orderOptions: [],
      note: null,
    };

    if (item && item.orderOptions) {
      menuItem.orderOptions = item.orderOptions;
    }

    if (item && item.note) {
      menuItem.note = item.note;
    }

    return menuItem;
  });

  const expandedBenefits = expandBenefitsByIndex(
    expandableBenefits,
    orderItems
  );

  const { shareableBenefits, itemBenefits } =
    expandedBenefits.length > 0
      ? expandedBenefits.reduce(
          (results, expandedItemBenefits, index) => {
            if (!expandedItemBenefits || expandedItemBenefits.length === 0) {
              return results;
            }

            // Get used benefits up to this point
            const { usedBenefits } = results;
            let allBenefits = benefits;
            let usableBenefits = benefits;
            for (let i = 0; i < index; i++) {
              const menuItem = menuItems[i];
              if (expandedBenefits[i]) {
                for (let k = 0; k < expandedBenefits[i].length; k++) {
                  const expandedBenefit = expandedBenefits[i][k];
                  let concatEnabled = true;
                  for (let j = 0; j < allBenefits.length; j++) {
                    if (expandedBenefit.id === allBenefits[j].id) {
                      allBenefits = [
                        ...allBenefits.slice(0, j),
                        {
                          ...expandedBenefit,
                          maxAmount:
                            expandedBenefit.maxAmount +
                            allBenefits[j].maxAmount,
                        },
                        ...allBenefits.slice(j + 1),
                      ];
                      concatEnabled = false;
                      break;
                    }
                  }
                  if (concatEnabled) {
                    allBenefits = allBenefits.concat([expandedBenefit]);
                  }
                }
              }

              usableBenefits = getUsableBenefits(allBenefits, usedBenefits);
              const usablePrice =
                (orderLocation === VENUE_LOCATION_TO_GO && menuItem.item
                  ? menuItem.item.priceToGo || menuItem.item.price
                  : menuItem.item.price) || 0;

              const { benefit } = calculateItemBenefits(
                menuItem.id,
                usablePrice,
                usableBenefits
              );
              updateUsedBenefits(usedBenefits, benefit);

              if (menuItem.orderOptions) {
                calculatePriceOptions(
                  menuItem.orderOptions,
                  inflateOptions(venue, menuItem.item.options),
                  allBenefits,
                  usedBenefits,
                  orderLocation
                );
              }
            }

            const maxAmountsBeforeApplication = expandedItemBenefits.reduce(
              (result, benefit) => {
                if (!usedBenefits[benefit.id]) {
                  return result;
                }
                return {
                  ...result,
                  [benefit.id]: usedBenefits[benefit.id],
                };
              },
              {}
            );

            const menuItem = menuItems[index];
            expandedItemBenefits.forEach((expandedBenefit) => {
              for (let j = 0; j < allBenefits.length; j++) {
                if (expandedBenefit.id === allBenefits[j].id) {
                  allBenefits = [
                    ...allBenefits.slice(0, j),
                    {
                      ...expandedBenefit,
                      maxAmount:
                        expandedBenefit.maxAmount + allBenefits[j].maxAmount,
                    },
                    ...allBenefits.slice(j + 1),
                  ];
                  return;
                }
              }
              allBenefits = allBenefits.concat([expandedBenefit]);
            });
            usableBenefits = getUsableBenefits(allBenefits, usedBenefits);
            const usablePrice =
              (orderLocation === VENUE_LOCATION_TO_GO
                ? menuItem.item.priceToGo || menuItem.item.price
                : menuItem.item.price) || 0;
            const { benefit: resultingBenefit } = calculateItemBenefits(
              menuItem.id,
              usablePrice,
              usableBenefits
            );
            updateUsedBenefits(usedBenefits, resultingBenefit);
            usableBenefits = getUsableBenefits(allBenefits, usedBenefits);

            if (menuItem.orderOptions) {
              calculatePriceOptions(
                menuItem.orderOptions,
                inflateOptions(venue, menuItem.item.options),
                allBenefits,
                usedBenefits,
                orderLocation
              );
            }

            // Check whether benefits expanded by item are shareable
            const { shareableItemBenefits, itemBenefits: singleItemBenefits } =
              expandedItemBenefits.reduce(
                (itemResult, benefit) => {
                  if (!benefit.maxAmount) {
                    return {
                      ...itemResult,
                      shareableBenefits: {
                        ...itemResult.shareableBenefits,
                        [benefit.id]: benefit,
                      },
                    };
                  }

                  const realUsage =
                    (usedBenefits[benefit.id] || 0) -
                    (maxAmountsBeforeApplication[benefit.id] || 0);
                  const shareAmount = benefit.maxAmount - realUsage;
                  let newShareableItemBenefits =
                    itemResult.shareableItemBenefits;
                  let newItemBenefits = itemResult.itemBenefits;

                  if (shareAmount > 0) {
                    newShareableItemBenefits = {
                      ...newShareableItemBenefits,
                      [benefit.id]: {
                        ...benefit,
                        maxAmount: shareAmount,
                      },
                    };
                  }

                  if (realUsage > 0) {
                    newItemBenefits = newItemBenefits.concat([
                      {
                        ...benefit,
                        itemBenefit: true,
                        maxAmount: realUsage,
                      },
                    ]);
                  }

                  return {
                    shareableItemBenefits: newShareableItemBenefits,
                    itemBenefits: newItemBenefits,
                  };
                },
                { shareableItemBenefits: {}, itemBenefits: [] }
              );

            const newItemBenefits = {
              ...results.itemBenefits,
              [index]: singleItemBenefits,
            };

            const newShareableBenefits = Object.entries(
              shareableItemBenefits
            ).reduce((result, [benefitId, benefit]) => {
              if (!benefit.maxAmount || !result[benefitId]) {
                return {
                  ...result,
                  [benefitId]: benefit,
                };
              }

              return {
                ...result,
                [benefitId]: {
                  ...result[benefitId],
                  maxAmount: result[benefitId].maxAmount + benefit.maxAmount,
                },
              };
            }, results.shareableBenefits);

            return {
              shareableBenefits: newShareableBenefits,
              itemBenefits: newItemBenefits,
              usedBenefits,
            };
          },
          {
            shareableBenefits: {},
            itemBenefits: {},
            usedBenefits: {},
          }
        )
      : {
          shareableBenefits: {},
          itemBenefits: {},
          usedBenefits: {},
        };

  const sharedBenefits = Object.values(shareableBenefits);
  let allBenefits = benefits;

  sharedBenefits.forEach((expandedBenefit) => {
    for (let j = 0; j < allBenefits.length; j++) {
      if (expandedBenefit.id === allBenefits[j].id) {
        allBenefits = [
          ...allBenefits.slice(0, j),
          {
            ...expandedBenefit,
            maxAmount: expandedBenefit.maxAmount + allBenefits[j].maxAmount,
          },
          ...allBenefits.slice(j + 1),
        ];
        return;
      }
    }
    allBenefits = allBenefits.concat([expandedBenefit]);
  });

  let usedBenefits = {};
  orderItems.forEach((orderItem, index) => {
    const itemId = orderItem.itemId || orderItem;
    const section = findSection(itemId, venue.menu.sections);
    if (!groupedOrders[section]) {
      groupedOrders[section] = [];
    }

    const item = venue.menu && venue.menu.items && venue.menu.items[itemId];
    const priceBeforeBenefits =
      (item &&
        (orderLocation === VENUE_LOCATION_TO_GO
          ? item.priceToGo || item.price
          : item.price)) ||
      0;

    let priceAfterBenefits = priceBeforeBenefits;
    let priceAfterBenefitsRaw = priceBeforeBenefits;

    let fullBenefits = allBenefits;
    if (itemBenefits && itemBenefits[index]) {
      itemBenefits[index].forEach((expandedBenefit) => {
        for (let j = 0; j < allBenefits.length; j++) {
          if (expandedBenefit.id === allBenefits[j].id) {
            fullBenefits = [
              ...fullBenefits.slice(0, j),
              {
                ...expandedBenefit,
                maxAmount:
                  expandedBenefit.maxAmount + fullBenefits[j].maxAmount,
              },
              ...fullBenefits.slice(j + 1),
            ];
            return;
          }
        }
        fullBenefits = fullBenefits.concat([expandedBenefit]);
      });
    }

    const itemUsedBenefits = {};
    const remainingBenefits = getUsableBenefits(fullBenefits, usedBenefits);
    const { benefit, amount } = calculateItemBenefits(
      itemId,
      priceBeforeBenefits,
      remainingBenefits
    );
    if (benefit) {
      updateUsedBenefits(itemUsedBenefits, benefit);
      priceAfterBenefitsRaw -= amount;
      priceAfterBenefits = Math.round(priceAfterBenefitsRaw);
      if (priceAfterBenefits < 0) {
        priceAfterBenefits = 0;
      }
    }

    let remark;
    if (benefit && benefit.remark) {
      const remarkHash = sha1(
        benefit.remark[locale] || benefit.remark[venue.defaultLocale]
      );
      const reusableRemark = remarks[remarkHash];
      if (reusableRemark) {
        remark = reusableRemark;
      } else {
        remark = benefit.remark[locale] || benefit.remark[venue.defaultLocale];
        remarks[remarkHash] = remark;
      }
    }

    const expandedOptions = expandOrderOptions(
      orderItem.orderOptions,
      venue,
      venueStrings,
      remainingBenefits,
      itemUsedBenefits,
      remarks,
      locale,
      orderLocation
    );

    const difference = Object.entries(itemUsedBenefits).reduce(
      (diff, [benefitId, value]) => {
        // Check whether difference needs to be recorded
        let isItemBenefit = false;
        let usableValue = value;

        if (itemBenefits[index]) {
          const itemBenefit = itemBenefits[index].find(
            (innerItemBenefit) => innerItemBenefit.id === benefitId
          );
          if (itemBenefit) {
            isItemBenefit = true;
            if (itemBenefit.maxAmount) {
              const benefitDifference = itemBenefit.maxAmount - value;
              if (benefitDifference < 0) {
                usableValue = Math.abs(benefitDifference);
                isItemBenefit = false;
              }
            }
          }
        }

        if (isItemBenefit) {
          return diff;
        }

        if (!diff[benefitId]) {
          return {
            ...diff,
            [benefitId]: usableValue,
          };
        }
        return {
          ...diff,
          [benefitId]: usableValue + diff[benefitId],
        };
      },
      {}
    );

    usedBenefits = mergeUsedBenefits(difference, usedBenefits);

    const [alreadyGroupedItem] = groupedOrders[section].filter(
      (savedItem) =>
        itemId === savedItem.id &&
        isEqual(expandedOptions, savedItem.orderOptions) &&
        priceAfterBenefits === savedItem.priceAfterBenefits &&
        remark === savedItem.remark &&
        orderItem.note === savedItem.note
    );

    if (alreadyGroupedItem) {
      alreadyGroupedItem.amount++;
    } else {
      groupedOrders[section].push({
        id: itemId,
        key: orderItem.id,
        orderPositionId: orderItem.id,
        rawOrderOptions: orderItem.orderOptions,
        note: orderItem.note,
        amount: 1,
        orderOptions: expandedOptions,
        itemName: venueStrings[itemId],
        priceDisplay:
          venue.menu &&
          venue.menu.items &&
          venue.menu.items[itemId] &&
          venue.menu.items[itemId].priceDisplay,
        priceBeforeBenefits,
        priceAfterBenefitsRaw,
        priceAfterBenefits,
        remark,
      });
    }
  });

  const finalRemarks = Object.values(remarks);
  // Update remarks
  Object.entries(groupedOrders).forEach(([, groupedItems]) => {
    groupedItems.forEach((item) => {
      if (item.remark) {
        // eslint-disable-next-line no-param-reassign
        item.remark = finalRemarks.indexOf(item.remark) + 1;
      }
      if (item.orderOptions) {
        updateOrderOptionsRemarks(item.orderOptions, finalRemarks);
      }
    });
  });

  const sectionResults = Object.entries(groupedOrders).map(
    ([section, groupedItems]) => ({
      key: section,
      id: section,
      data: groupedItems,
    })
  );

  // Sort based on strings
  sectionResults.sort((a, b) => {
    if (venueStrings[a] < venueStrings[b]) {
      return -1;
    }
    if (venueStrings[a] > venueStrings[b]) {
      return 1;
    }
    return 0;
  });

  // Sort items within sections
  sectionResults.forEach((section) => {
    section.data.sort((a, b) => {
      if (venueStrings[a.id] < venueStrings[b.id]) {
        return -1;
      }
      if (venueStrings[a.id] > venueStrings[b.id]) {
        return 1;
      }

      if (a.remark) {
        if (!b.remark) {
          return -1;
        }

        if (a.remark < b.remark) {
          return -1;
        }
        if (a.remark > b.remark) {
          return 1;
        }
      }

      if (a.orderOptions && b.orderOptions) {
        const hashA = hash(a.orderOptions);
        const hashB = hash(b.orderOptions);
        if (hashA < hashB) {
          return -1;
        }
        if (hashA > hashB) {
          return 1;
        }
      }

      return 0;
    });
  });

  // Calculate price
  const totalPrice = Math.round(
    sectionResults.reduce(
      (prev, { data }) =>
        prev +
        data.reduce(
          (previous, item) =>
            previous +
            item.amount *
              (item.priceAfterBenefitsRaw +
                calculateOptionsPrice(item.orderOptions)),
          0
        ),
      0
    )
  );

  return {
    totalPrice,
    sectionResults,
    remarks: finalRemarks,
  };
}
