import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CheckoutBar from "../components/CheckoutBar";
import { createSelector } from "reselect";
import { calculatePrice as calculateOptionPrice } from "@orda/shared-functions-js/lib/options";
import { inflateOptions } from "../lib/options";
import { getUsableBenefits } from "@orda/shared-functions/benefits";
import { VENUE_LOCATION_TO_GO } from "@orda/shared-constants/order-locations";
import { calculateItemBenefits } from "@orda/shared-functions-js/lib/price";
import {
  addItem,
  addItemWithOptions,
  expressOrderStart,
} from "../redux/actions/order";
import { getItemAvailability } from "../lib/hours";
import {
  configuredOptionsSelector,
  preConfigurationUsedBenefitsSelector,
  preConfigurationBenefitsSelector,
} from "../redux/selectors/item-configuration";
import { calculatePrice } from "../lib/price";
import routes from "../routes";
import { noteValid } from "../util/validation";

const optionsPriceSelector = createSelector(
  (configuredOptions) => configuredOptions,
  (_, options) => options,
  (_, __, preConfigurationBenefits) => preConfigurationBenefits,
  (_, __, ___, orderLocation) => orderLocation,
  (configuredOptions, options, preConfigurationBenefits, orderLocation) =>
    options
      ? calculateOptionPrice(
          configuredOptions,
          options,
          preConfigurationBenefits,
          {},
          orderLocation
        )
      : { price: 0, usedBenefits: {} }
);

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { venueId, itemId },
    },
    mountedAt,
  } = ownProps;

  const {
    venues: {
      data: { [venueId]: venue },
    },
    itemConfiguration: { entries, minimumOptions, note },
    order,
    user,
  } = state;

  if (mountedAt === routes.item.id) {
    // cart is being displayed on the menu item detail page
    // need to show the item configuration price
    const item = venue.menu.items[itemId];
    const options = inflateOptions(venue, item.options);
    const configuredOptions = configuredOptionsSelector(
      venue,
      itemId,
      entries,
      minimumOptions
    );

    // collect the benefits
    const preConfigurationUsedBenefits = preConfigurationUsedBenefitsSelector(
      venue,
      order
    );

    const preConfigurationBenefits = preConfigurationBenefitsSelector(
      itemId,
      configuredOptions,
      preConfigurationUsedBenefits,
      order
    );

    const optionsPrice = optionsPriceSelector(
      configuredOptions,
      options,
      preConfigurationBenefits,
      order.location
    );

    const itemPriceBeforeBenefits =
      (order.location === VENUE_LOCATION_TO_GO
        ? item.priceToGo || item.price
        : item.price) || 0;

    const itemPrice = Math.round(
      Math.max(
        itemPriceBeforeBenefits -
          calculateItemBenefits(
            itemId,
            itemPriceBeforeBenefits,
            getUsableBenefits(
              preConfigurationBenefits,
              optionsPrice.usedBenefits
            )
          ).amount,
        0
      ) + optionsPrice.price
    );

    // availability
    const { available: itemAvailable } = getItemAvailability(
      venue.openingHours,
      item,
      false,
      order.location,
      order.orderTime
    );

    // current payment method
    const currentPaymentMethodInternal = user.currentPaymentMethod
      ? user.paymentMethods.find(
          (paymentMethod) => paymentMethod.id === user.currentPaymentMethod
        )
      : null;

    return {
      venueId,
      itemId,
      currency: venue.currency,
      price: itemPrice,
      note,
      configuredOptions,
      itemAvailable,
      mountedAt,
      countItems: order.items.length,
      expressOrderProcessing: order.requests.expressOrder.processing,
      currentPaymentMethod: currentPaymentMethodInternal,
      trackingVenueName: venue.strings[venue.defaultLocale][venueId],
      trackingItemName: venue.strings[venue.defaultLocale][itemId],
    };
  }

  const price = calculatePrice(
    order.items,
    venue.menu.items,
    venue,
    order.location,
    null,
    order.feesBenefits.benefits,
    null,
    order.feesBenefits.expandablebenefits
  ).sum;

  return {
    venueId,
    trackingVenueName: venue.strings[venue.defaultLocale][venueId],
    currency: venue.currency,
    price,
    mountedAt,
    countItems: order.items.length,
    tableId: order.tableId ? order.tableId : null,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    match: {
      params: { venueId, itemId },
    },
    history,
  } = ownProps;

  return {
    addToCart: (
      itemAvailable,
      note,
      configuredOptions,
      skipRedirect = false
    ) => {
      if (itemAvailable && noteValid(note)) {
        if (configuredOptions) {
          dispatch(
            addItemWithOptions(venueId, itemId, configuredOptions, note)
          );
        } else {
          dispatch(addItem(venueId, itemId, note));
        }
        if (!skipRedirect) {
          history.push(`/venues/${venueId}/menu`);
        }
      }
    },
    viewCart: () => {
      history.push(`/venues/${venueId}/cart`);
    },
    expressOrderStart: (itemAvailable, note, configuredOptions) => {
      if (itemAvailable && noteValid(note)) {
        dispatch(expressOrderStart(venueId, itemId, configuredOptions, note));
      }
    },
  };
};

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  addToCart: () =>
    dispatchProps.addToCart(
      stateProps.itemAvailable,
      stateProps.note,
      stateProps.configuredOptions
    ),
  expressOrderStart: () => {
    if (stateProps.countItems > 0) {
      dispatchProps.addToCart(
        stateProps.itemAvailable,
        stateProps.note,
        stateProps.configuredOptions,
        true
      );
      dispatchProps.viewCart();
    } else {
      dispatchProps.expressOrderStart(
        stateProps.itemAvailable,
        stateProps.note,
        stateProps.configuredOptions
      );
    }
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(CheckoutBar)
);
