import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Menu as MenuIcon } from "mdi-material-ui";
import { THEME } from "../theme";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import VenueInfoModal from "../containers/VenueInfoModal";

const styles = {
  iconButton: {
    color: THEME.PRIMARY_BUTTON_ICON,
    backgroundColor: "rgba(44, 47, 58, 0.3)",
    "&:hover": {
      backgroundColor: "rgba(44, 47, 58, 0.7)",
    },
    borderRadius: 0,
    fontSize: "clamp(0.7rem, 3vmin, 1rem)",
  },
};

const BurgerMenu = ({ t, classes, venueId, tableId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const handleItemClick = (itemNumber) => {
    switch (itemNumber) {
      case 0:
        setOpenModal(true);
        break;
      case 1:
        openInNewTab(
          `https://get.orda-app.com?&~channel=${tableId}&venueId=${venueId}&tableId=${tableId}`
        );
        break;
      case 2:
        openInNewTab("https://www.orda-app.com/impressum");
        break;
      default:
        break;
    }
    handleClose();
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={classes.iconButton}
      >
        <MenuIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleItemClick(0)}>
          {t("restaurantInfo")}
        </MenuItem>
        <MenuItem onClick={() => handleItemClick(1)}>
          {t("downloadApp")}
        </MenuItem>
        <MenuItem onClick={() => handleItemClick(2)}>{t("imprint")}</MenuItem>
      </Menu>
      <VenueInfoModal open={openModal} setOpen={setOpenModal} />
    </div>
  );
};

BurgerMenu.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  venueId: PropTypes.string.isRequired,
  tableId: PropTypes.string,
};

BurgerMenu.defaultProps = {
  tableId: null,
};

export default withTranslation("burgerMenu")(withStyles(styles)(BurgerMenu));
