import PropTypes from "prop-types";
import history from "../history";

const VenueByAlias = ({ match }) => {
  history.push(`/venues/${match.params.venueAlias}`);
  return null;
};

VenueByAlias.propTypes = {
  match: PropTypes.object.isRequired,
};

export default VenueByAlias;
