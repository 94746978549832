import { API_ENDPOINT } from "../config";
import { transport } from "../lib/transport";

export const prepareOrder = async (userId, params, authToken) => {
  const result = await transport(
    {
      method: "POST",
      url: `${API_ENDPOINT}/users/${userId}/orders`,
      body: JSON.stringify(params),
    },
    authToken
  );
  return result;
};

export const executeOrder = async (userId, orderId, params, authToken) => {
  const result = await transport(
    {
      method: "POST",
      url: `${API_ENDPOINT}/users/${userId}/orders/${orderId}/execute`,
      body: JSON.stringify(params),
    },
    authToken
  );
  return result;
};
