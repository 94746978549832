import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from "react-i18next";
import { Plus } from "mdi-material-ui";
import routes from "../routes";
import { logEvent, convertMonetaryValue } from "../lib/analytics";
import { ORDER_ADD_ITEM_TO_CART } from "../lib/analytics/events";
import classnames from "classnames";
import { THEME } from "../theme";
import DownloadAppModal from "./DownloadAppModal";

const styles = {
  root: {
    backgroundColor: "white",
    boxShadow: "0px -4px 20px 1px rgba(0,0,0,0.2)",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  itemRoot: {
    maxHeight: 80,
    minHeight: 80,
  },
  menuRoot: {
    maxHeight: 80,
    minHeight: 80,
  },
  container: {
    width: "90%",
  },
  horizontalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  summaryContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  priceContainer: {
    display: "flex",
    flexDirection: "column",
  },
  countContainer: {
    display: "flex",
    flexDirection: "column",
  },
  countActionsContainer: {
    display: "flex",
    alignItems: "center",
  },
  labelDisplay: {
    fontSize: "0.7em",
  },
  valueDisplay: {
    fontSize: "1.2em",
    fontWeight: 600,
    color: THEME.MAIN_GREEN,
  },
  button: {
    fontSize: "0.8em",
    height: 45,
  },
  smallButton: {
    width: 200,
    color: THEME.WHITE_TEXT_COLOR,
    fontSize: "0.8em",
    backgroundColor: THEME.BUTTON_BACKGROUND_COLOR,
    "&:hover": {
      backgroundColor: THEME.BUTTON_BACKGROUND_COLOR,
      opacity: 0.8,
    },
  },
  leftIcon: {
    marginRight: 10,
  },
  countSelect: {
    maxHeight: 44,
    minWidth: 65,
  },
  inverse: {
    color: "#fff",
  },
  paypalButtonWrapper: {
    width: "100%",
    marginTop: 15,
  },
};

class CheckoutBar extends React.Component {
  handleAddToCart = () => {
    const {
      venueId,
      trackingVenueName,
      itemId,
      trackingItemName,
      note,
      currency,
      price,
      addToCart,
    } = this.props;

    logEvent(ORDER_ADD_ITEM_TO_CART, {
      venueId,
      venueName: trackingVenueName,
      itemId,
      itemName: trackingItemName,
      withNote: !!note,
      price: convertMonetaryValue(currency, price),
    });
    addToCart();
  };

  render() {
    const {
      t,
      classes,
      currency,
      price,
      countItems,
      viewCart,
      mountedAt,
      expressOrderProcessing,
      itemAvailable,
      tableId,
      venueId,
    } = this.props;

    return (
      <>
        {mountedAt === routes.item.id && itemAvailable && (
          <div className={classnames(classes.root, classes.itemRoot)}>
            <div className={classes.container}>
              <div className={classes.horizontalContainer}>
                <div className={classes.priceContainer}>
                  <Typography component="span" className={classes.labelDisplay}>
                    {t("sumTotalLabel")}
                  </Typography>
                  <Typography component="span" className={classes.valueDisplay}>
                    {t("common:money", { data: { currency, value: price } })}
                  </Typography>
                </div>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.smallButton}
                  size="large"
                  onClick={this.handleAddToCart}
                  disabled={expressOrderProcessing}
                >
                  <Plus className={classes.leftIcon} />
                  {t("addToCart")}
                </Button>
              </div>
            </div>
          </div>
        )}
        {mountedAt === routes.menu.id &&
          (countItems > 0 ? (
            <div className={classnames(classes.root, classes.menuRoot)}>
              <div className={classes.container}>
                <div className={classes.horizontalContainer}>
                  <div className={classes.priceContainer}>
                    <Typography
                      component="span"
                      className={classes.labelDisplay}
                    >
                      {t("sumTotalLabel")}
                    </Typography>
                    <Typography
                      component="span"
                      className={classes.valueDisplay}
                    >
                      {t("common:money", { data: { currency, value: price } })}
                    </Typography>
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.smallButton}
                    size="large"
                    onClick={viewCart}
                  >
                    {t("viewCart")}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <DownloadAppModal venueId={venueId} tableId={tableId} />
          ))}
      </>
    );
  }
}

CheckoutBar.propTypes = {
  t: PropTypes.func.isRequired,
  venueId: PropTypes.string.isRequired,
  itemId: PropTypes.string,
  classes: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  countItems: PropTypes.number,
  addToCart: PropTypes.func.isRequired,
  viewCart: PropTypes.func.isRequired,
  mountedAt: PropTypes.string.isRequired,
  expressOrderProcessing: PropTypes.bool,
  itemAvailable: PropTypes.bool,
  note: PropTypes.string,
  trackingVenueName: PropTypes.string.isRequired,
  trackingItemName: PropTypes.string,
  tableId: PropTypes.string,
};

CheckoutBar.defaultProps = {
  countItems: 0,
  itemAvailable: null,
  note: null,
  itemId: null,
  expressOrderProcessing: false,
  trackingItemName: null,
  tableId: null,
};

export default withTranslation(["checkoutBar", "common"])(
  withStyles(styles)(CheckoutBar)
);
