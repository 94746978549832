import flatten from "lodash/flatten";

function getOptionAdditives(optionId, venue) {
  const option = venue.menu.options[optionId];
  let additives = option.tags || [];

  if (option.options) {
    additives = additives.concat(
      flatten(
        option.options.map((childOptionId) =>
          getOptionAdditives(childOptionId, venue)
        )
      )
    );
  }
  if (option.items) {
    additives = additives.concat(
      flatten(option.items.map((itemId) => venue.menu.items[itemId].tags || []))
    );
  }

  return additives;
}

export function collectAdditives(itemId, venue) {
  // Get additives for item
  const item = venue.menu.items[itemId];
  const itemAdditives = item.tags || [];
  let optionAdditives = [];

  // Get additives for options
  if (item.options) {
    optionAdditives = getOptionAdditives(item.options, venue);
  }

  return {
    item: [...new Set(itemAdditives)],
    options: [...new Set(optionAdditives)],
  };
}

export function getCharacter(index) {
  return String.fromCharCode("A".charCodeAt(0) + index);
}
